import React, { useEffect, useState } from 'react';
import {
  NumberInput,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  FormControl,
  FormLabel,
  Input,
  InputRightAddon,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberDecrementStepper,
  VStack,
  Radio,
  Link,
  FormHelperText,
  InputGroup,
  FormErrorMessage,
} from '@chakra-ui/react';
import * as _ from 'lodash';

import { OverSampleType, UniverseType } from '../../lib/pricing-tool-api';
import Choice from '../Choice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ReduxAction } from '../../reducer';
import PercentIcon from '../PercentIcon';

export const UNIVERSE_TYPE_OPTIONS = [
  {
    choiceText: 'Likely general election voters',
    value: UniverseType.LikelyGeneralVoters,
  },
  {
    choiceText: 'Likely primary voters',
    value: UniverseType.LikelyDemPrimary,
  },
  {
    choiceText: 'Registered voters',
    value: UniverseType.RegisteredVoters,
  },
  {
    choiceText: 'All adults',
    value: UniverseType.AllAdults,
  },
  {
    choiceText: 'Something else',
    value: UniverseType.OtherNonStandard,
  },
];

export const OVERSAMPLE_OPTIONS = [
  {
    choiceText: 'No oversample',
    value: OverSampleType.None,
  },
  {
    choiceText: 'Oversample of primary voters',
    value: OverSampleType.PrimaryVoters,
  },
  {
    choiceText: 'Other Oversample',
    value: OverSampleType.Other,
  },
];

const EXAMPLE_FILTERS_LINK =
  'https://docs.google.com/document/d/1F0Vdzdg3pDQM8uBunq8LjHrCa1bZ8F3xNlpo8iiIURo/edit?usp=sharing';

const MIN_UNIVERSES = 1;
const MAX_UNIVERSES = 10;
const DEFAULT_UNIVERSES = 1;
const DEMO_FILTER_PRECISION = 1;

function specialDemoFilterWithinRange(percentage: string | number) {
  return _.toNumber(percentage) >= 1 && _.toNumber(percentage) <= 100;
}

export interface TargetUniverseSectionProps {
  quoteId: string | null;
}

function TargetUniverseSection(
  props: TargetUniverseSectionProps = { quoteId: null },
) {
  const { quoteId } = props;
  const [numOfUniverses, setNumOfUniverses] = useState(DEFAULT_UNIVERSES);
  const [universeTypes, setUniverseTypes] = useState<Array<UniverseType>>([
    UNIVERSE_TYPE_OPTIONS[0].value,
  ]);
  const [oversampleType, setOversampleType] = useState<OverSampleType>(
    OVERSAMPLE_OPTIONS[0].value,
  );
  const [otherUniverseDescription, setOtherUniverseDescription] = useState('');
  const [specialDemoFilterPercentage, setSpecialDemoFilterPercentage] =
    useState<string | number>(100);
  const dispatch = useAppDispatch();
  const quoteFromRedux = useAppSelector((state) => state.selectedQuote);

  // only on first render load the saved quote
  useEffect(() => {
    if (quoteId && quoteFromRedux && quoteFromRedux.id === quoteId) {
      setNumOfUniverses(quoteFromRedux.price_info.request.num_of_universes);
      setUniverseTypes(quoteFromRedux.price_info.request.universe_types);
      if (quoteFromRedux.price_info.request.oversample_type) {
        setOversampleType(quoteFromRedux.price_info.request.oversample_type);
      }
      if (quoteFromRedux.price_info.request.other_universe_type_description) {
        setOtherUniverseDescription(
          quoteFromRedux.price_info.request.other_universe_type_description,
        );
      }
      if (quoteFromRedux.price_info.request.special_demo_filter_pct) {
        setSpecialDemoFilterPercentage(
          quoteFromRedux.price_info.request.special_demo_filter_pct * 100,
        );
      }
    } else if (quoteId && (!quoteFromRedux || quoteFromRedux.id !== quoteId)) {
      throw new Error('Should have loaded the quote from api into redux');
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateUniverseCount,
      universeCount: numOfUniverses,
    });
  }, [dispatch, numOfUniverses]);

  useEffect(() => {
    if (!universeTypes.includes(UniverseType.OtherNonStandard)) {
      setOtherUniverseDescription('');
    }
    dispatch({
      type: ReduxAction.UpdateUniverseTypes,
      universeTypes,
    });
  }, [dispatch, universeTypes]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateOversampleType,
      oversampleType,
    });
  }, [dispatch, oversampleType]);

  useEffect(() => {
    let percentage = _.toNumber(specialDemoFilterPercentage);
    percentage /= 100;
    percentage = Number.parseFloat(
      percentage.toFixed(DEMO_FILTER_PRECISION + 2),
    );
    dispatch({
      type: ReduxAction.UpdateSpecialDemoFilterPercentage,
      percentage,
    });
  }, [dispatch, specialDemoFilterPercentage]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateOtherUniverseDescription,
      otherUniverseDescription,
    });
  }, [dispatch, otherUniverseDescription]);

  return (
    <VStack spacing="2%">
      <FormControl>
        <FormLabel>How many universes are we weighting to?</FormLabel>
        <NumberInput
          value={numOfUniverses}
          onChange={(valString) => setNumOfUniverses(_.toInteger(valString))}
          min={MIN_UNIVERSES}
          max={MAX_UNIVERSES}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <FormControl isRequired isInvalid={universeTypes.length > numOfUniverses}>
        <FormLabel>What kinds of universes are you weighting to?</FormLabel>
        <CheckboxGroup
          value={universeTypes}
          onChange={(vals) =>
            setUniverseTypes(_.map(vals, (val) => val as UniverseType))
          }
        >
          <VStack flexDir="column" align="start">
            {UNIVERSE_TYPE_OPTIONS.map(({ choiceText, value }: Choice) => (
              <Checkbox value={value} key={`universe-type-${value}`}>
                {choiceText}
              </Checkbox>
            ))}

            <FormHelperText>
              You can only select as many universe types as the number of
              universes you are weighting to.
            </FormHelperText>
          </VStack>
        </CheckboxGroup>

        {universeTypes.length > numOfUniverses && (
          <FormErrorMessage>
            You can only select {numOfUniverses} universe type
            {numOfUniverses > 1 && 's'}!
          </FormErrorMessage>
        )}
      </FormControl>

      {universeTypes.includes(UniverseType.OtherNonStandard) && (
        <FormControl isRequired>
          <FormLabel>
            Please describe the other universe type you are weighting to:
          </FormLabel>
          <Input
            value={otherUniverseDescription}
            onChange={(e) => setOtherUniverseDescription(e.target.value)}
          />
        </FormControl>
      )}

      <FormControl isRequired>
        <FormLabel>Is there an oversample?</FormLabel>
        <RadioGroup
          value={oversampleType}
          onChange={(v) => setOversampleType(v as OverSampleType)}
        >
          <VStack flexDir="column" align="start">
            {OVERSAMPLE_OPTIONS.map(({ choiceText, value }: Choice) => (
              <Radio key={choiceText} value={value}>
                {choiceText}
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
      </FormControl>

      <FormControl
        textAlign="start"
        isInvalid={!specialDemoFilterWithinRange(specialDemoFilterPercentage)}
      >
        <FormLabel>
          Percent of all survey takers that will make it past the special
          demographic filtering (100 means no filtering)
        </FormLabel>
        <InputGroup>
          <Input
            type="number"
            value={specialDemoFilterPercentage}
            onChange={(e) =>
              setSpecialDemoFilterPercentage(
                e.target.value ? _.toNumber(e.target.value) : '',
              )
            }
            onBlur={(e) => {
              setSpecialDemoFilterPercentage(_.toNumber(e.target.value));
            }}
          />
          <InputRightAddon pointerEvents="none">
            <PercentIcon />
          </InputRightAddon>
        </InputGroup>
        <FormHelperText>
          <Link href={EXAMPLE_FILTERS_LINK} color="brand.linkColor" isExternal>
            Example filter percentages here
          </Link>
        </FormHelperText>
        {!specialDemoFilterWithinRange(specialDemoFilterPercentage) && (
          <FormErrorMessage>
            Demo filter percentages should be within 1 and 100!
          </FormErrorMessage>
        )}
      </FormControl>
    </VStack>
  );
}

export default TargetUniverseSection;
