import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Code,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  GridItem,
} from '@chakra-ui/react';

import FieldingHeader, {
  FieldingHeaderProps,
} from '../../components/polling/FieldingHeader';
import FieldingCategoryRow from '../../components/polling/FieldingCategoryRow';
import CrSpinner from '../../components/polling/CrSpinner';
import WeightingConfigChooser from '../../components/polling/WeightingConfigChooser';
import { usePollService } from '../../lib/services/PollService';

import useFieldingData from '../../lib/services/PollServerHooks';
import {
  TargetSegmentSummaryType,
  WeightingConfigType,
} from '../../lib/polling-graphql/types';

interface FieldingSummaryProps {
  headerInfo: FieldingHeaderProps;
  unweightedSampleSize: number;
  targetSummaries: TargetSegmentSummaryType[];
  weightingConfigs: WeightingConfigType[];
  setConfig: (config: WeightingConfigType) => void;
  warnings: string[];
  loading: boolean;
  handleNewConfig: () => void;
}

function FieldingSummary({
  headerInfo,
  unweightedSampleSize,
  targetSummaries,
  weightingConfigs,
  setConfig,
  warnings,
  loading,
  handleNewConfig,
}: FieldingSummaryProps) {
  const chosenConfig = weightingConfigs.find((wc) => wc.isRecruitmentConfig);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Grid
        templateColumns={{
          md: 'repeat(11, 1fr)',
        }}
      >
        <GridItem colStart={5} colEnd={7}>
          <FieldingHeader
            name={headerInfo.name}
            lastSurveyResponseTime={headerInfo.lastSurveyResponseTime}
            lastSurveyCheckTime={headerInfo.lastSurveyCheckTime}
          />
        </GridItem>
        <GridItem colStart={9} colEnd={11}>
          <Stat>
            <StatLabel>Filtered Respondents</StatLabel>
            <StatNumber>{unweightedSampleSize}</StatNumber>
          </Stat>
        </GridItem>
      </Grid>

      {loading && <CrSpinner message="Counting..." />}
      {!loading && (
        <HStack spacing="24px" align="flex-end" m={2}>
          <WeightingConfigChooser
            weightingConfigs={weightingConfigs}
            setConfig={setConfig}
          />

          <Button
            whiteSpace="normal"
            height="auto"
            blockSize="auto"
            onClick={onOpen}
          >
            <Text padding={2}>Create new config</Text>
          </Button>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create New Recruitment Config</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                This will create a new weighting configuration with the target
                marginals for the location of this poll based on the Registered
                Voter polling universe for that geography.
                <br />
                <br />
                The new config will be named with the prefix{' '}
                <Code>Recruitment Target</Code>.
                <br />
                <br />
                Note that if the poll defines multiple geographies, only the
                first one will be used.
                <br />
                <br />
                You are able to edit the created recruitment configuration in
                the weighting tool after it has been created.
              </ModalBody>

              <ModalFooter>
                <Button mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onClose();
                    handleNewConfig();
                  }}
                >
                  Create
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </HStack>
      )}

      {!loading && warnings.length > 0 && (
        <Alert status="warning" textAlign="left">
          <Box m={4}>
            <Heading>Warnings</Heading>
            <ul>
              {warnings.map((w) => (
                <li key={w}>
                  <Text>{w}</Text>
                </li>
              ))}
            </ul>
          </Box>
        </Alert>
      )}

      {chosenConfig && !loading && (
        <Grid
          templateColumns={{
            md: 'repeat(1, 1fr)',
          }}
          gap={6}
        >
          {targetSummaries.map((ts: TargetSegmentSummaryType) => (
            <FieldingCategoryRow key={ts.segmentName} data={ts} />
          ))}
        </Grid>
      )}
    </>
  );
}

function FieldingSummaryView() {
  const { pollId } = useParams();
  const { loading, error, data, refetch } = useFieldingData(pollId);
  const pollService = usePollService();

  const handleSelectConfig = async (config: WeightingConfigType) => {
    if (pollId) {
      await pollService.setRecruitmentConfig(pollId, config.uuid);
    }
    if (refetch) {
      await refetch();
    }
  };

  const handleNewConfig = async () => {
    if (pollId) {
      await pollService.buildDefaultTargetConfig(pollId);
    }
    if (refetch) {
      await refetch();
    }
  };

  return (
    <Box
      maxW={{ '2xl': '100%', md: '7xl' }}
      mx="auto"
      pt={5}
      px={{ base: 2, sm: 12, md: 17 }}
    >
      {loading && !data && <CrSpinner message="Loading..." />}
      {error && (
        <Alert status="error">
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{`${error}`}</AlertDescription>
        </Alert>
      )}
      {data && (
        <FieldingSummary
          headerInfo={data.fieldingHeader}
          unweightedSampleSize={data.unweightedSampleSize}
          targetSummaries={data.targetSummaries}
          weightingConfigs={data.weightingConfigs}
          warnings={data.warnings}
          setConfig={handleSelectConfig}
          loading={loading}
          handleNewConfig={handleNewConfig}
        />
      )}
    </Box>
  );
}

export default FieldingSummaryView;
