import React from 'react';
import { Box, Grid, ColorModeScript } from '@chakra-ui/react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet, useRoutes, RouteObject } from 'react-router-dom';
import PricingNavigationMenu from './components/pricing/PricingNavigationMenu';
import GlobalServices from './lib/services/GlobalServices';
import PricingTool from './views/pricing/PricingTool';
import QuoteList from './views/quotes/QuoteList';
import QuoteDetail from './views/quotes/QuoteDetail';
import FieldingSummaryView from './views/polling/FieldingSummary';
import PollDetailView from './views/polling/PollDetail';
import PollNavigationMenu from './components/poll/PollNavigationMenu';

interface ProtectedRouteProps {
  component: React.ComponentType;
}

function ProtectedRoute({ component }: ProtectedRouteProps) {
  const Component = withAuthenticationRequired(component);

  return <Component />;
}

function PricingHome() {
  return (
    <Box textAlign="center" fontSize="xl">
      <PricingNavigationMenu>
        <Grid minH="100vh" p={4}>
          <GlobalServices>
            <Outlet />
          </GlobalServices>
        </Grid>
      </PricingNavigationMenu>
    </Box>
  );
}

function PollHome() {
  return (
    <Box textAlign="center" fontSize="xl">
      <PollNavigationMenu>
        <Grid minH="100vh" p={4}>
          <GlobalServices>
            <Outlet />
          </GlobalServices>
        </Grid>
      </PollNavigationMenu>
    </Box>
  );
}

function App() {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <ProtectedRoute component={PricingHome} />,
      children: [
        { index: true, element: <PricingTool /> },
        {
          path: 'pricing',
          element: <QuoteList />,
        },
        {
          path: 'pricing/quote',
          element: <PricingTool />,
        },
        {
          path: 'pricing/quote/:quoteId',
          element: <QuoteDetail />,
        },
      ],
    },
    {
      path: '/poll',
      element: <ProtectedRoute component={PollHome} />,
      children: [
        {
          path: ':pollId',
          element: <PollDetailView />,
        },
        { path: ':pollId/fielding', element: <FieldingSummaryView /> },
      ],
    },
    {
      path: '*',
      element: <p>UI Router path not found</p>,
    },
  ];

  const routingElement = useRoutes(routes);
  return (
    <Box>
      {routingElement}
      <ColorModeScript />
    </Box>
  );
}

export default App;
