import { useQuery, gql } from '@apollo/client';
import { FieldingHeaderProps } from '../../components/polling/FieldingHeader';

const GET_FIELDING_SUMMARY = gql`
  query GetFieldingSummary($id: UUID!) {
    poll(id: $id) {
      name
      fieldingSummary {
        lastSurveyCheckTime
        lastSurveyResponseTime
        pollingUniverse
        name
        unweightedSampleSize
        targetSegmentSummaries {
          categoryData {
            approxCount
            categoryName
            currentPercentOfTotal
            percentageOfFinalTarget
            targetCount
            targetPercent
          }
          segmentName
          historyLineData {
            categoryData {
              count
              categoryName
            }
            datetime
          }
        }
        warnings
      }
      weightingConfigs {
        uuid
        name
        isRecruitmentConfig
      }
    }
  }
`;

function useFieldingData(pollId: string | undefined) {
  if (pollId === undefined) {
    return {
      loading: false,
      error: 'No poll id provided',
      data: null,
      refetch: null,
    };
  }
  const { loading, error, data, refetch } = useQuery(GET_FIELDING_SUMMARY, {
    variables: { id: pollId },
    notifyOnNetworkStatusChange: true,
  });
  let processedData = data;
  if (data && data.poll.fieldingSummary) {
    const fieldingHeader = {
      lastSurveyCheckTime: new Date(
        data.poll.fieldingSummary.lastSurveyCheckTime,
      ),
      lastSurveyResponseTime:
        data.poll.fieldingSummary.lastSurveyResponseTime === null
          ? null
          : new Date(data.poll.fieldingSummary.lastSurveyResponseTime),
      pollingUniverse: data.poll.fieldingSummary.pollingUniverse,
      name: data.poll.fieldingSummary.name,
    } as FieldingHeaderProps;

    processedData = {
      fieldingHeader,
      unweightedSampleSize: data.poll.fieldingSummary.unweightedSampleSize,
      targetSummaries: data.poll.fieldingSummary.targetSegmentSummaries,
      weightingConfigs: data.poll.weightingConfigs,
      warnings: data.poll.fieldingSummary.warnings,
    };
  } else if (data && data.poll.fieldingSummary === null) {
    processedData = {
      fieldingHeader: {
        unweightedSampleSize: 0,
        lastSurveyCheckTime: new Date(),
        lastSurveyResponseTime: null,
        pollingUniverse: 'unknown',
        name: data.poll.name,
      } as FieldingHeaderProps,
      targetSummaries: [],
      weightingConfigs: data.poll.weightingConfigs,
      warnings: ['No Fielding summary generated'],
    };
  }

  return { loading, error, data: processedData, refetch };
}

export default useFieldingData;
