import React from 'react';
import PricingService from './PricingService';
import GeoService from './GeoService';
import QuoteService from './QuoteService';
import PollService from './PollService';
import GqlService from './GqlService';

type GlobalServicesProps = { children: React.ReactNode };

function GlobalServices({ children }: GlobalServicesProps) {
  return (
    <QuoteService>
      <PricingService>
        <GeoService>
          <GqlService>
            <PollService>{children}</PollService>
          </GqlService>
        </GeoService>
      </PricingService>
    </QuoteService>
  );
}

export default GlobalServices;
