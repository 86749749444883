import { configureStore } from '@reduxjs/toolkit';
import * as _ from 'lodash';
// import logger from "redux-logger";

import appReducer, { DealInfo, ReduxAction } from './reducer';
import {
  DistrictDemoData,
  PricingQuoteRequest,
  UniverseType,
} from './lib/pricing-tool-api';

export const store = configureStore({
  reducer: appReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const checkIfSaveable = (
  pricingQuote: PricingQuoteRequest,
  dealInfo: DealInfo,
) => {
  const clientTypeIsSet = !_.isEmpty(pricingQuote.client_type);
  const businessUnitIsSet = !_.isEmpty(pricingQuote.business_unit);
  const oversampleTypeIsSet = !_.isEmpty(pricingQuote.oversample_type);
  const atLeastOneUniverseTypeIsSet = pricingQuote.universe_types?.length > 0;
  const dealNameIsSet = !_.isEmpty(dealInfo.deal_name);
  const clientNameIsSet = !_.isEmpty(dealInfo.client_name);
  const zendeskDealIdIsSet = !_.isEmpty(dealInfo.zendesk_deal_id);
  const otherUniverseDescriptionSetIfRequired = _.includes(
    pricingQuote.universe_types,
    UniverseType.OtherNonStandard,
  )
    ? !!pricingQuote.other_universe_type_description
    : true;

  const specialDemoFilterWithinBounds =
    pricingQuote?.special_demo_filter_pct &&
    pricingQuote?.special_demo_filter_pct >= 0.01 &&
    pricingQuote?.special_demo_filter_pct <= 1;

  if (
    clientTypeIsSet &&
    businessUnitIsSet &&
    oversampleTypeIsSet &&
    atLeastOneUniverseTypeIsSet &&
    otherUniverseDescriptionSetIfRequired &&
    dealNameIsSet &&
    clientNameIsSet &&
    zendeskDealIdIsSet &&
    specialDemoFilterWithinBounds
  ) {
    return true;
  }

  return false;
};

store.subscribe(() => {
  const {
    isSaveable: isCurrentlySaveable,
    pricingQuote,
    dealInfo,
  } = store.getState();
  if (isCurrentlySaveable) {
    if (!checkIfSaveable(pricingQuote, dealInfo)) {
      store.dispatch({
        type: ReduxAction.UpdateIsSaveable,
        isSaveable: false,
      });
    }
  } else if (checkIfSaveable(pricingQuote, dealInfo)) {
    store.dispatch({ type: ReduxAction.UpdateIsSaveable, isSaveable: true });
  }
});

export const calculateMaxRecommendedSampleSize = (
  districtDemoDataList: DistrictDemoData[],
) => {
  if (districtDemoDataList.length === 0) {
    return 0;
  }
  return _.ceil(_.sumBy(districtDemoDataList, (d) => d.max_sample_size));
};

export const selectMaxRecommendedSampleSize = (state: RootState) => {
  const { selectedDemoDistricts } = state;
  return calculateMaxRecommendedSampleSize(selectedDemoDistricts);
};
