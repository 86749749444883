import React, { useMemo } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import ProvidedServices from './ProvidedServices';
import Contextualizer from './Contextualizer';
import {
  Backend,
  Configuration,
  GetPollResponse,
  MainApi,
  WeightingApi,
  WeightingConfig,
  CustomAudienceApi,
} from '../polling-api';

// ************
// This code is disposable and should be replaced with the generated code
export interface PollDetail {
  id: string;
  name: string;
}

export interface GraphQLResponse {
  data: any | null;
  errors?: object[];
}

export interface IPollService {
  getPoll(pollId: string): Promise<AxiosResponse<GetPollResponse>>;
  getWeightConfigs(
    pollId: string,
  ): Promise<AxiosResponse<{ [key: string]: WeightingConfig }>>;

  setRecruitmentConfig(
    pollIdUuid: string,
    weightingConfigUuid: string,
  ): Promise<AxiosResponse<boolean>>;

  buildDefaultTargetConfig(pollIdUuid: string): Promise<AxiosResponse<string>>;
  graphql(query: string): Promise<AxiosResponse<GraphQLResponse>>;
}

const PollServiceContext = Contextualizer.createContext(
  ProvidedServices.PollService,
);
export const usePollService = () =>
  Contextualizer.use<IPollService>(ProvidedServices.PollService);

type PollServiceProps = { children: React.ReactNode };

const PROXY_PATH = '/proxy/poll-server';

const getProxiedUrl = (basePath: string, path: string) =>
  `${basePath}${PROXY_PATH}/${path}`;

interface GetPollRequest {
  backend: string;
  id: string;
}

const getPollApiCall = async (
  basePath: string,
  pollId: string,
  headers: { [key: string]: string },
): Promise<AxiosResponse<PollDetail>> => {
  const url = getProxiedUrl(basePath, 'get_poll');
  const request = { backend: 'postgres', id: pollId } as GetPollRequest;
  const response = await axios.post(url, request, { headers });

  return {
    data: { id: pollId, name: response.data.poll.name },
  } as AxiosResponse<PollDetail>;
};

const graphQLApiCall = async (
  basePath: string,
  query: string,
  headers: { [key: string]: string },
): Promise<AxiosResponse<GraphQLResponse>> => {
  const url = getProxiedUrl(basePath, 'graphql');

  const payload = { query };
  const response = await axios.post(url, payload, { headers });
  return response as AxiosResponse<GraphQLResponse>;
};

// ************

function PollService({ children }: PollServiceProps) {
  const { getAccessTokenSilently } = useAuth0();
  const basePath = `${window.location.origin}/poll-api/proxy/poll-server`;

  const pollService = {
    async getPoll(
      pollId: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<GetPollResponse>> {
      const tokenValue = await token;

      const options = {
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      };

      return new MainApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).getPollGetPollPut({ backend: Backend.Postgres, id: pollId }, options);
    },
    async getWeightConfigs(
      pollId: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<{ [key: string]: WeightingConfig }>> {
      const tokenValue = await token;

      const options = {
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      };

      return new WeightingApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).configsMapWeightingConfigsPut(
        { backend: Backend.Postgres, id: pollId },
        options,
      );
    },
    async setRecruitmentConfig(
      pollIdUuid: string,
      weightingConfigUuid: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<boolean>> {
      const tokenValue = await token;

      const options = {
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      };

      return new CustomAudienceApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut(
        pollIdUuid,
        weightingConfigUuid,
        options,
      );
    },
    async buildDefaultTargetConfig(
      pollIdUuid: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<string>> {
      const tokenValue = await token;

      const options = {
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      };

      return new CustomAudienceApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut(
        pollIdUuid,
        options,
      );
    },
    async graphql(
      query: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<GraphQLResponse>> {
      const tokenValue = await token;

      return graphQLApiCall(basePath, query, {
        Authorization: `Bearer ${tokenValue}`,
        'content-type': 'application/json',
      });
    },
  };

  return (
    <PollServiceContext.Provider value={useMemo(() => pollService, [])}>
      {children}
    </PollServiceContext.Provider>
  );
}
export default PollService;
