/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * The parameters for canceling a running ads creation request.
 * @export
 * @interface AdCancelRequest
 */
export interface AdCancelRequest {
  /**
   * The job id of the ads creation request to cancel
   * @type {string}
   * @memberof AdCancelRequest
   */
  job_id: string;
}
/**
 * Keeps track of the state of an Ad Launch to allow reporting back to client on progress
 * @export
 * @interface AdSheetLaunchJob
 */
export interface AdSheetLaunchJob {
  /**
   * A unique identifier
   * @type {string}
   * @memberof AdSheetLaunchJob
   */
  job_id: string;
  /**
   * Epoch time in seconds of when the job was requested
   * @type {number}
   * @memberof AdSheetLaunchJob
   */
  start_time: number;
  /**
   * Epoch time in seconds of when the job was completed, or -1 if still running
   * @type {number}
   * @memberof AdSheetLaunchJob
   */
  end_time?: number;
  /**
   * Number of ads that were launched
   * @type {number}
   * @memberof AdSheetLaunchJob
   */
  ads_launched?: number;
  /**
   * Number of ads that were skipped because they already existed.
   * @type {number}
   * @memberof AdSheetLaunchJob
   */
  ads_skipped?: number;
  /**
   * Number of ads that were not able to be created.
   * @type {number}
   * @memberof AdSheetLaunchJob
   */
  ads_failed?: number;
  /**
   * If there were any warnings relating to the ad sheet reported.
   * @type {boolean}
   * @memberof AdSheetLaunchJob
   */
  has_warnings?: boolean;
  /**
   * If any of the rows in the sheet could not be validated.
   * @type {boolean}
   * @memberof AdSheetLaunchJob
   */
  bad_config?: boolean;
  /**
   * If any configs skipped the demo check.
   * @type {boolean}
   * @memberof AdSheetLaunchJob
   */
  demo_check_skipped?: boolean;
  /**
   * If any configs failed the demo check.
   * @type {boolean}
   * @memberof AdSheetLaunchJob
   */
  demo_check_failed?: boolean;
  /**
   * If any configs failed the demo check.
   * @type {boolean}
   * @memberof AdSheetLaunchJob
   */
  demo_check_passed?: boolean;
  /**
   * Whether or not the script ran to completion.
   * @type {boolean}
   * @memberof AdSheetLaunchJob
   */
  completed_ok?: boolean;
}
/**
 * The parameters for launching a set of Facebook ads using a spreadsheet.
 * @export
 * @interface AdSheetLaunchRequest
 */
export interface AdSheetLaunchRequest {
  /**
   *
   * @type {PollID}
   * @memberof AdSheetLaunchRequest
   */
  poll_id: PollID;
  /**
   * Google spreadsheet hash from the url eg if your url was `https://docs.google.com/spreadsheets/d/1QAx-W8aCD7O5hXyxiK_iqa488gyL4FMivA935kjq8aI/edit#gid=0` the hash would be: `1QAx-W8aCD7O5hXyxiK_iqa488gyL4FMivA935kjq8aI`
   * @type {string}
   * @memberof AdSheetLaunchRequest
   */
  sheet_id: string;
  /**
   * If the sheet is using the long form format this value should be true, if the sheet is using the short form format this value should be false
   * @type {boolean}
   * @memberof AdSheetLaunchRequest
   */
  long_form: boolean;
}
/**
 *
 * @export
 * @interface AddGoogleSheetCrosstabsUrlResponse
 */
export interface AddGoogleSheetCrosstabsUrlResponse {
  /**
   *
   * @type {boolean}
   * @memberof AddGoogleSheetCrosstabsUrlResponse
   */
  success: boolean;
}
/**
 *
 * @export
 * @interface AdsFacebookServiceCountDemo
 */
export interface AdsFacebookServiceCountDemo {
  /**
   *
   * @type {string}
   * @memberof AdsFacebookServiceCountDemo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof AdsFacebookServiceCountDemo
   */
  percent: number;
  /**
   *
   * @type {number}
   * @memberof AdsFacebookServiceCountDemo
   */
  count: number;
}
/**
 *
 * @export
 * @interface AdsFacebookServiceCountSummary
 */
export interface AdsFacebookServiceCountSummary {
  /**
   *
   * @type {Array<AdsFacebookServiceCountDemo>}
   * @memberof AdsFacebookServiceCountSummary
   */
  by_race: Array<AdsFacebookServiceCountDemo>;
  /**
   *
   * @type {Array<AdsFacebookServiceCountDemo>}
   * @memberof AdsFacebookServiceCountSummary
   */
  by_pol: Array<AdsFacebookServiceCountDemo>;
}
/**
 *
 * @export
 * @interface ArchiveStatus
 */
export interface ArchiveStatus {
  /**
   *
   * @type {string}
   * @memberof ArchiveStatus
   */
  poll_id?: string;
  /**
   *
   * @type {number}
   * @memberof ArchiveStatus
   */
  sm_id?: number;
  /**
   *
   * @type {string}
   * @memberof ArchiveStatus
   */
  sm_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ArchiveStatus
   */
  poll?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ArchiveStatus
   */
  survey_schema?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ArchiveStatus
   */
  responses?: boolean;
}
/**
 *
 * @export
 * @interface AudienceSplitDefinition
 */
export interface AudienceSplitDefinition {
  /**
   *
   * @type {string}
   * @memberof AudienceSplitDefinition
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AudienceSplitDefinition
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AudienceSplitDefinition
   */
  eth: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AudienceSplitDefinition
   */
  pol: Array<string>;
  /**
   *
   * @type {Array<SurveyLocation>}
   * @memberof AudienceSplitDefinition
   */
  locations: Array<SurveyLocation>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Backend = {
  Redis: 'redis',
  Postgres: 'postgres',
} as const;

export type Backend = typeof Backend[keyof typeof Backend];

/**
 *
 * @export
 * @interface BatchCrosstabsResponse
 */
export interface BatchCrosstabsResponse {
  /**
   *
   * @type {boolean}
   * @memberof BatchCrosstabsResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof BatchCrosstabsResponse
   */
  start_time: string;
  /**
   *
   * @type {string}
   * @memberof BatchCrosstabsResponse
   */
  end_time: string;
  /**
   *
   * @type {string}
   * @memberof BatchCrosstabsResponse
   */
  engagement_id: string;
  /**
   *
   * @type {string}
   * @memberof BatchCrosstabsResponse
   */
  weighting_config_name: string;
  /**
   *
   * @type {Array<CrosstabRunSummary>}
   * @memberof BatchCrosstabsResponse
   */
  run_summaries: Array<CrosstabRunSummary>;
}
/**
 *
 * @export
 * @interface BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut
 */
export interface BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut
   */
  poll_id: PollId;
  /**
   *
   * @type {ConfigId}
   * @memberof BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut
   */
  config_id: ConfigId;
}
/**
 *
 * @export
 * @interface BodyBatchUpdateSegmentsBatchUpdateSegmentsPut
 */
export interface BodyBatchUpdateSegmentsBatchUpdateSegmentsPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyBatchUpdateSegmentsBatchUpdateSegmentsPut
   */
  poll_id: PollId;
  /**
   *
   * @type {Array<object>}
   * @memberof BodyBatchUpdateSegmentsBatchUpdateSegmentsPut
   */
  batch_column_data: Array<object>;
}
/**
 *
 * @export
 * @interface BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut
 */
export interface BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut
   */
  poll_id: PollId;
  /**
   *
   * @type {CustomPresentationSpec}
   * @memberof BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut
   */
  custom_presentation_spec: CustomPresentationSpec;
}
/**
 *
 * @export
 * @interface BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut
 */
export interface BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut
   */
  poll_id: PollId;
  /**
   *
   * @type {WeightingConfigSpec}
   * @memberof BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut
   */
  weighting_config_spec: WeightingConfigSpec;
}
/**
 *
 * @export
 * @interface BodyCommitConfigCommitConfigPut
 */
export interface BodyCommitConfigCommitConfigPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyCommitConfigCommitConfigPut
   */
  poll_id: PollId;
  /**
   *
   * @type {string}
   * @memberof BodyCommitConfigCommitConfigPut
   */
  config: string;
  /**
   *
   * @type {string}
   * @memberof BodyCommitConfigCommitConfigPut
   */
  do_weights: string;
  /**
   *
   * @type {string}
   * @memberof BodyCommitConfigCommitConfigPut
   */
  original_zip_base64: string;
}
/**
 *
 * @export
 * @interface BodyCreateSegmentCreateSegmentPut
 */
export interface BodyCreateSegmentCreateSegmentPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyCreateSegmentCreateSegmentPut
   */
  poll_id: PollId;
  /**
   *
   * @type {object}
   * @memberof BodyCreateSegmentCreateSegmentPut
   */
  column_data: object;
}
/**
 *
 * @export
 * @interface BodyGetConfigGetConfigPut
 */
export interface BodyGetConfigGetConfigPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyGetConfigGetConfigPut
   */
  poll_id: PollId;
  /**
   *
   * @type {ConfigId}
   * @memberof BodyGetConfigGetConfigPut
   */
  config_id: ConfigId;
}
/**
 *
 * @export
 * @interface BodyGetDefaultConfigGetDefaultConfigPut
 */
export interface BodyGetDefaultConfigGetDefaultConfigPut {
  /**
   *
   * @type {SurveyMonkeyId}
   * @memberof BodyGetDefaultConfigGetDefaultConfigPut
   */
  survey_monkey_id: SurveyMonkeyId;
  /**
   *
   * @type {PollId}
   * @memberof BodyGetDefaultConfigGetDefaultConfigPut
   */
  poll_id: PollId;
}
/**
 *
 * @export
 * @interface BodyRunConfigRunConfigPut
 */
export interface BodyRunConfigRunConfigPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyRunConfigRunConfigPut
   */
  poll_id: PollId;
  /**
   *
   * @type {ConfigId}
   * @memberof BodyRunConfigRunConfigPut
   */
  config_id: ConfigId;
  /**
   *
   * @type {ConfigId}
   * @memberof BodyRunConfigRunConfigPut
   */
  fielding_config_id: ConfigId;
}
/**
 *
 * @export
 * @interface BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut
 */
export interface BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut
   */
  poll_id: PollId;
  /**
   *
   * @type {CrosstabPresentation}
   * @memberof BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut
   */
  crosstabs_presentation: CrosstabPresentation;
}
/**
 *
 * @export
 * @interface BodyUpdateMetadataUpdateMetadataPut
 */
export interface BodyUpdateMetadataUpdateMetadataPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyUpdateMetadataUpdateMetadataPut
   */
  poll_id: PollId;
  /**
   *
   * @type {MetaData}
   * @memberof BodyUpdateMetadataUpdateMetadataPut
   */
  metadata: MetaData;
}
/**
 *
 * @export
 * @interface BodyUpdateSegmentUpdateSegmentPut
 */
export interface BodyUpdateSegmentUpdateSegmentPut {
  /**
   *
   * @type {PollId}
   * @memberof BodyUpdateSegmentUpdateSegmentPut
   */
  poll_id: PollId;
  /**
   *
   * @type {object}
   * @memberof BodyUpdateSegmentUpdateSegmentPut
   */
  column_data: object;
}
/**
 *
 * @export
 * @interface BulkEngagementSinglePollInstance
 */
export interface BulkEngagementSinglePollInstance {
  /**
   *
   * @type {number}
   * @memberof BulkEngagementSinglePollInstance
   */
  survey_monkey_id: number;
  /**
   *
   * @type {PollId}
   * @memberof BulkEngagementSinglePollInstance
   */
  poll_id?: PollId;
}
/**
 *
 * @export
 * @interface BulkPollingEngagement
 */
export interface BulkPollingEngagement {
  /**
   *
   * @type {string}
   * @memberof BulkPollingEngagement
   */
  zendesk_id: string;
  /**
   *
   * @type {Array<BulkEngagementSinglePollInstance>}
   * @memberof BulkPollingEngagement
   */
  poll_metadata?: Array<BulkEngagementSinglePollInstance>;
  /**
   *
   * @type {Array<BatchCrosstabsResponse>}
   * @memberof BulkPollingEngagement
   */
  batch_crosstab_run_results?: Array<BatchCrosstabsResponse>;
}
/**
 *
 * @export
 * @interface ChoiceUpdate
 */
export interface ChoiceUpdate {
  /**
   *
   * @type {string}
   * @memberof ChoiceUpdate
   */
  question_type: ChoiceUpdateQuestionTypeEnum;
  /**
   *
   * @type {Heading}
   * @memberof ChoiceUpdate
   */
  heading: Heading;
  /**
   *
   * @type {number}
   * @memberof ChoiceUpdate
   */
  page_number: number;
  /**
   *
   * @type {number}
   * @memberof ChoiceUpdate
   */
  page_position: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ChoiceUpdate
   */
  choices?: Array<string>;
}

export const ChoiceUpdateQuestionTypeEnum = {
  SingleChoice: 'single_choice',
  MultipleChoice: 'multiple_choice',
  Horserace: 'horserace',
} as const;

export type ChoiceUpdateQuestionTypeEnum =
  typeof ChoiceUpdateQuestionTypeEnum[keyof typeof ChoiceUpdateQuestionTypeEnum];

/**
 *
 * @export
 * @interface CleanTextViolation
 */
export interface CleanTextViolation {
  /**
   *
   * @type {number}
   * @memberof CleanTextViolation
   */
  survey_question_id: number;
  /**
   *
   * @type {string}
   * @memberof CleanTextViolation
   */
  heading_text: string;
  /**
   *
   * @type {CleanTextViolationType}
   * @memberof CleanTextViolation
   */
  type: CleanTextViolationType;
  /**
   *
   * @type {string}
   * @memberof CleanTextViolation
   */
  violating_text: string;
  /**
   *
   * @type {QuestionComponentPart}
   * @memberof CleanTextViolation
   */
  question_component_part: QuestionComponentPart;
  /**
   *
   * @type {ViolationRating}
   * @memberof CleanTextViolation
   */
  violation_rating: ViolationRating;
  /**
   *
   * @type {string}
   * @memberof CleanTextViolation
   */
  violating_character?: string;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const CleanTextViolationType = {
  LeadingWhitespace: 'leading whitespace',
  TrailingWhitespace: 'trailing whitespace',
  UnsupportedCharacter: 'unsupported character',
} as const;

export type CleanTextViolationType =
  typeof CleanTextViolationType[keyof typeof CleanTextViolationType];

/**
 *
 * @export
 * @interface CloneSurveyCreatedResponse
 */
export interface CloneSurveyCreatedResponse {
  /**
   *
   * @type {SurveyMonkeyId}
   * @memberof CloneSurveyCreatedResponse
   */
  survey_id: SurveyMonkeyId;
  /**
   *
   * @type {string}
   * @memberof CloneSurveyCreatedResponse
   */
  url: string;
}
/**
 * :param survey_monkey_id: id of base survey :param survey_name: respondent facing name of new clone survey to be created :param survey_nickname: new survey nickname (e.g. ny_st_haha_2020_08) :param new_survey_data: form data provided by pollster for customizing                         clone survey questions and choices. keys will                         vary depending on which base survey is used
 * @export
 * @interface CloneSurveyInput
 */
export interface CloneSurveyInput {
  /**
   *
   * @type {SurveyMonkeyId}
   * @memberof CloneSurveyInput
   */
  survey_monkey_id: SurveyMonkeyId;
  /**
   *
   * @type {string}
   * @memberof CloneSurveyInput
   */
  survey_name: string;
  /**
   *
   * @type {string}
   * @memberof CloneSurveyInput
   */
  survey_nickname: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CloneSurveyInput
   */
  new_survey_data: { [key: string]: string };
}
/**
 *
 * @export
 * @interface CollectorCount
 */
export interface CollectorCount {
  /**
   * Name of the SurveyMonkey collector
   * @type {string}
   * @memberof CollectorCount
   */
  name: string;
  /**
   * A count of the respondents
   * @type {number}
   * @memberof CollectorCount
   */
  count: number;
}
/**
 *
 * @export
 * @interface CollectorFloatCount
 */
export interface CollectorFloatCount {
  /**
   * Name of the SurveyMonkey collector
   * @type {string}
   * @memberof CollectorFloatCount
   */
  name: string;
  /**
   * A float count of the respondents
   * @type {number}
   * @memberof CollectorFloatCount
   */
  count: number;
}
/**
 *
 * @export
 * @interface Config
 */
export interface Config {
  /**
   *
   * @type {ConfigId}
   * @memberof Config
   */
  id?: ConfigId;
  /**
   *
   * @type {string}
   * @memberof Config
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Config
   */
  config?: string;
  /**
   *
   * @type {string}
   * @memberof Config
   */
  do_weights?: string;
  /**
   *
   * @type {string}
   * @memberof Config
   */
  zip?: string;
  /**
   *
   * @type {PollId}
   * @memberof Config
   */
  poll_id?: PollId;
}
/**
 *
 * @export
 * @interface ConfigId
 */
export interface ConfigId {
  /**
   *
   * @type {Backend}
   * @memberof ConfigId
   */
  backend: Backend;
  /**
   *
   * @type {string}
   * @memberof ConfigId
   */
  id: string;
}

/**
 *
 * @export
 * @interface ConfigResponse
 */
export interface ConfigResponse {
  /**
   *
   * @type {Config}
   * @memberof ConfigResponse
   */
  config: Config;
}
/**
 *
 * @export
 * @interface CreateSurveyTemplateRequest
 */
export interface CreateSurveyTemplateRequest {
  /**
   *
   * @type {SurveyTemplate}
   * @memberof CreateSurveyTemplateRequest
   */
  template: SurveyTemplate;
}
/**
 *
 * @export
 * @interface CrosstabPresentation
 */
export interface CrosstabPresentation {
  /**
   *
   * @type {Array<DenormSeriesPresentation>}
   * @memberof CrosstabPresentation
   */
  columns: Array<DenormSeriesPresentation>;
  /**
   *
   * @type {Array<DenormSeriesPresentationGroup>}
   * @memberof CrosstabPresentation
   */
  rows: Array<DenormSeriesPresentationGroup>;
  /**
   *
   * @type {SmallColumnFilter}
   * @memberof CrosstabPresentation
   */
  small_column_filter?: SmallColumnFilter;
}
/**
 *
 * @export
 * @interface CrosstabPresentationUpdateReport
 */
export interface CrosstabPresentationUpdateReport {
  /**
   *
   * @type {boolean}
   * @memberof CrosstabPresentationUpdateReport
   */
  success: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CrosstabPresentationUpdateReport
   */
  errors?: Array<string>;
}
/**
 *
 * @export
 * @interface CrosstabRunSummary
 */
export interface CrosstabRunSummary {
  /**
   *
   * @type {boolean}
   * @memberof CrosstabRunSummary
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CrosstabRunSummary
   */
  survey_id: string;
  /**
   *
   * @type {PollId}
   * @memberof CrosstabRunSummary
   */
  poll_id: PollId;
  /**
   *
   * @type {string}
   * @memberof CrosstabRunSummary
   */
  url?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CrosstabRunSummary
   */
  errors: Array<string>;
}
/**
 *
 * @export
 * @interface CustomAudienceBqCustomAudienceServiceCountDemo
 */
export interface CustomAudienceBqCustomAudienceServiceCountDemo {
  /**
   *
   * @type {string}
   * @memberof CustomAudienceBqCustomAudienceServiceCountDemo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CustomAudienceBqCustomAudienceServiceCountDemo
   */
  percent: number;
  /**
   *
   * @type {number}
   * @memberof CustomAudienceBqCustomAudienceServiceCountDemo
   */
  count: number;
}
/**
 *
 * @export
 * @interface CustomAudienceBqCustomAudienceServiceCountSummary
 */
export interface CustomAudienceBqCustomAudienceServiceCountSummary {
  /**
   *
   * @type {Array<CustomAudienceBqCustomAudienceServiceCountDemo>}
   * @memberof CustomAudienceBqCustomAudienceServiceCountSummary
   */
  by_race: Array<CustomAudienceBqCustomAudienceServiceCountDemo>;
  /**
   *
   * @type {Array<CustomAudienceBqCustomAudienceServiceCountDemo>}
   * @memberof CustomAudienceBqCustomAudienceServiceCountSummary
   */
  by_pol: Array<CustomAudienceBqCustomAudienceServiceCountDemo>;
}
/**
 *
 * @export
 * @interface CustomPresentationSpec
 */
export interface CustomPresentationSpec {
  /**
   *
   * @type {DenormSeriesInclusionSpec}
   * @memberof CustomPresentationSpec
   */
  row_series_builders?: DenormSeriesInclusionSpec;
  /**
   *
   * @type {DenormSeriesInclusionSpec}
   * @memberof CustomPresentationSpec
   */
  column_series_builders?: DenormSeriesInclusionSpec;
}
/**
 *
 * @export
 * @interface DenormSeriesInclusionSpec
 */
export interface DenormSeriesInclusionSpec {
  /**
   *
   * @type {Array<string>}
   * @memberof DenormSeriesInclusionSpec
   */
  denorm_series_builder_names?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DenormSeriesInclusionSpec
   */
  question_matches?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DenormSeriesInclusionSpec
   */
  single_choice_question_regexes?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof DenormSeriesInclusionSpec
   */
  horserace_indices?: Array<number>;
}
/**
 *
 * @export
 * @interface DenormSeriesPresentation
 */
export interface DenormSeriesPresentation {
  /**
   *
   * @type {string}
   * @memberof DenormSeriesPresentation
   */
  heading: string;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesPresentation
   */
  denorm_series_name: string;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesPresentation
   */
  internal_display_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof DenormSeriesPresentation
   */
  dont_inc_subheading?: boolean;
  /**
   *
   * @type {ValueFormatter}
   * @memberof DenormSeriesPresentation
   */
  formatter?: ValueFormatter;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesPresentation
   */
  display_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof DenormSeriesPresentation
   */
  display_as_row?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof DenormSeriesPresentation
   */
  values_to_hide?: Array<string>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DenormSeriesPresentation
   */
  heading_mapper?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof DenormSeriesPresentation
   */
  allow_as_crosstab_column?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DenormSeriesPresentation
   */
  blank_row_before?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof DenormSeriesPresentation
   */
  tags?: Array<string>;
  /**
   *
   * @type {DenormSeriesSortOrder}
   * @memberof DenormSeriesPresentation
   */
  sort_order?: DenormSeriesSortOrder;
}

/**
 *
 * @export
 * @interface DenormSeriesPresentationGroup
 */
export interface DenormSeriesPresentationGroup {
  /**
   *
   * @type {string}
   * @memberof DenormSeriesPresentationGroup
   */
  heading?: string;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesPresentationGroup
   */
  denorm_series_builder_name: string;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesPresentationGroup
   */
  internal_display_name?: string;
  /**
   *
   * @type {Array<DenormSeriesPresentation>}
   * @memberof DenormSeriesPresentationGroup
   */
  groups?: Array<DenormSeriesPresentation>;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesPresentationGroup
   */
  display_name?: string;
  /**
   *
   * @type {DenormSeriesSortOrder}
   * @memberof DenormSeriesPresentationGroup
   */
  sort_order?: DenormSeriesSortOrder;
}

/**
 *
 * @export
 * @interface DenormSeriesSchema
 */
export interface DenormSeriesSchema {
  /**
   *
   * @type {string}
   * @memberof DenormSeriesSchema
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesSchema
   */
  display_name: string;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesSchema
   */
  internal_display_name?: string;
  /**
   *
   * @type {DenormSeriesValueType}
   * @memberof DenormSeriesSchema
   */
  type: DenormSeriesValueType;
  /**
   *
   * @type {string}
   * @memberof DenormSeriesSchema
   */
  parent_denorm_series_name: string;
  /**
   *
   * @type {SegmentSchema}
   * @memberof DenormSeriesSchema
   */
  parent_segment_schema: SegmentSchema;
  /**
   *
   * @type {SegmentValues}
   * @memberof DenormSeriesSchema
   */
  parent_segment_values?: SegmentValues;
  /**
   *
   * @type {Array<string>}
   * @memberof DenormSeriesSchema
   */
  categories?: Array<string>;
}

/**
 *
 * @export
 * @interface DenormSeriesSchemaResponse
 */
export interface DenormSeriesSchemaResponse {
  /**
   *
   * @type {Array<DenormSeriesSchema>}
   * @memberof DenormSeriesSchemaResponse
   */
  basic_columns?: Array<DenormSeriesSchema>;
  /**
   *
   * @type {Array<DenormSeriesSchema>}
   * @memberof DenormSeriesSchemaResponse
   */
  enriched_columns?: Array<DenormSeriesSchema>;
  /**
   *
   * @type {Array<DenormSeriesSchema>}
   * @memberof DenormSeriesSchemaResponse
   */
  survey_question_columns?: Array<DenormSeriesSchema>;
  /**
   *
   * @type {Array<DenormSeriesSchema>}
   * @memberof DenormSeriesSchemaResponse
   */
  segments?: Array<DenormSeriesSchema>;
  /**
   *
   * @type {{ [key: string]: Set<string>; }}
   * @memberof DenormSeriesSchemaResponse
   */
  parent_to_child_segments?: { [key: string]: Set<string> };
  /**
   *
   * @type {Array<string>}
   * @memberof DenormSeriesSchemaResponse
   */
  errors?: Array<string>;
}
/**
 * Constants determining the order of choices or questions controlled by a given DenormSeriesPresentation or DenormSeriesPresentationGroup
 * @export
 * @enum {string}
 */

export const DenormSeriesSortOrder = {
  Original: 'original',
  Frequency: 'frequency',
} as const;

export type DenormSeriesSortOrder =
  typeof DenormSeriesSortOrder[keyof typeof DenormSeriesSortOrder];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DenormSeriesValueType = {
  Unknown: 'unknown',
  Boolean: 'boolean',
  Categorical: 'categorical',
  Integer: 'integer',
  Float: 'float',
  OpenText: 'open text',
} as const;

export type DenormSeriesValueType =
  typeof DenormSeriesValueType[keyof typeof DenormSeriesValueType];

/**
 *
 * @export
 * @interface DenormalizedTableModifcationResponse
 */
export interface DenormalizedTableModifcationResponse {
  /**
   *
   * @type {boolean}
   * @memberof DenormalizedTableModifcationResponse
   */
  success: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof DenormalizedTableModifcationResponse
   */
  errors?: Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const EthPicker = {
  All: 'eth_all',
  WhiteNonwhite: 'eth_white_nonwhite',
  Granular: 'eth_granular',
} as const;

export type EthPicker = typeof EthPicker[keyof typeof EthPicker];

/**
 *
 * @export
 * @interface FacebookInsights
 */
export interface FacebookInsights {
  /**
   * The unique name for the ad insights
   * @type {string}
   * @memberof FacebookInsights
   */
  name: string;
  /**
   * A pollster-friendly name for the ad insights
   * @type {string}
   * @memberof FacebookInsights
   */
  display_name: string;
  /**
   * List of the Facebook ad metrics which make up the insights
   * @type {Array<Metric>}
   * @memberof FacebookInsights
   */
  metrics: Array<Metric>;
}
/**
 *
 * @export
 * @interface FeatureFlagStatusResponse
 */
export interface FeatureFlagStatusResponse {
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof FeatureFlagStatusResponse
   */
  feature_flag_status: { [key: string]: boolean };
}
/**
 *
 * @export
 * @interface FilterConfig
 */
export interface FilterConfig {
  /**
   *
   * @type {string}
   * @memberof FilterConfig
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof FilterConfig
   */
  min_answers?: number;
  /**
   *
   * @type {Array<MandatoryQuestion>}
   * @memberof FilterConfig
   */
  mandatory_questions?: Array<MandatoryQuestion>;
  /**
   *
   * @type {Array<FlagFilter>}
   * @memberof FilterConfig
   */
  flags?: Array<FlagFilter>;
  /**
   *
   * @type {Array<Geography>}
   * @memberof FilterConfig
   */
  geographies?: Array<Geography>;
  /**
   *
   * @type {boolean}
   * @memberof FilterConfig
   */
  registered_voters?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FilterConfig
   */
  not_registered?: boolean;
}
/**
 *
 * @export
 * @interface FilterConfigResponse
 */
export interface FilterConfigResponse {
  /**
   *
   * @type {FilterConfig}
   * @memberof FilterConfigResponse
   */
  config: FilterConfig;
  /**
   *
   * @type {Array<string>}
   * @memberof FilterConfigResponse
   */
  errors: Array<string>;
}
/**
 * A single category segment for counting the number of respondents excluded by the filter
 * @export
 * @interface FilteredSegment
 */
export interface FilteredSegment {
  /**
   * The unique denorm_series_builder name of the segment.
   * @type {string}
   * @memberof FilteredSegment
   */
  name: string;
  /**
   * A pollster-friendly name for this segment
   * @type {string}
   * @memberof FilteredSegment
   */
  display_name: string;
  /**
   * Ordered list of the subsets which make up the segment
   * @type {Array<SegmentCategory>}
   * @memberof FilteredSegment
   */
  categories: Array<SegmentCategory>;
}
/**
 *
 * @export
 * @interface FlagFilter
 */
export interface FlagFilter {
  /**
   * Whether to combine the flags by AND or OR
   * @type {boolean}
   * @memberof FlagFilter
   */
  is_and?: boolean;
  /**
   * Column names to combine
   * @type {Array<string>}
   * @memberof FlagFilter
   */
  columns?: Array<string>;
}
/**
 *
 * @export
 * @interface FormVariable
 */
export interface FormVariable {
  /**
   *
   * @type {string}
   * @memberof FormVariable
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FormVariable
   */
  field_type: string;
  /**
   *
   * @type {string}
   * @memberof FormVariable
   */
  subheader_text: string;
  /**
   *
   * @type {string}
   * @memberof FormVariable
   */
  default_value: string;
}
/**
 *
 * @export
 * @interface Geography
 */
export interface Geography {
  /**
   *
   * @type {boolean}
   * @memberof Geography
   */
  enabled?: boolean;
  /**
   *
   * @type {SurveyLocation}
   * @memberof Geography
   */
  location?: SurveyLocation;
  /**
   *
   * @type {Array<string>}
   * @memberof Geography
   */
  zips?: Array<string>;
}
/**
 *
 * @export
 * @interface GetBulkPollingEngagement
 */
export interface GetBulkPollingEngagement {
  /**
   *
   * @type {string}
   * @memberof GetBulkPollingEngagement
   */
  zendesk_id: string;
  /**
   *
   * @type {Array<BulkEngagementSinglePollInstance>}
   * @memberof GetBulkPollingEngagement
   */
  poll_metadata?: Array<BulkEngagementSinglePollInstance>;
  /**
   *
   * @type {Array<BatchCrosstabsResponse>}
   * @memberof GetBulkPollingEngagement
   */
  batch_crosstab_run_results?: Array<BatchCrosstabsResponse>;
  /**
   *
   * @type {string}
   * @memberof GetBulkPollingEngagement
   */
  id: string;
}
/**
 *
 * @export
 * @interface GetConfigsResponse
 */
export interface GetConfigsResponse {
  /**
   * A list of config ids
   * @type {Array<ConfigId>}
   * @memberof GetConfigsResponse
   */
  configs: Array<ConfigId>;
}
/**
 *
 * @export
 * @interface GetPollResponse
 */
export interface GetPollResponse {
  /**
   *
   * @type {PollSummary}
   * @memberof GetPollResponse
   */
  poll: PollSummary;
}
/**
 *
 * @export
 * @interface GetPollsResponse
 */
export interface GetPollsResponse {
  /**
   *
   * @type {Array<PollSummary>}
   * @memberof GetPollsResponse
   */
  polls: Array<PollSummary>;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface Heading
 */
export interface Heading {}
/**
 *
 * @export
 * @interface HorseraceQuestionData
 */
export interface HorseraceQuestionData {
  /**
   *
   * @type {number}
   * @memberof HorseraceQuestionData
   */
  page_number: number;
  /**
   *
   * @type {number}
   * @memberof HorseraceQuestionData
   */
  position: number;
  /**
   *
   * @type {number}
   * @memberof HorseraceQuestionData
   */
  question_id: number;
  /**
   *
   * @type {QuestionNames}
   * @memberof HorseraceQuestionData
   */
  type: QuestionNames;
  /**
   *
   * @type {Array<string>}
   * @memberof HorseraceQuestionData
   */
  candidate_list: Array<string>;
  /**
   *
   * @type {string}
   * @memberof HorseraceQuestionData
   */
  question_family: string;
  /**
   *
   * @type {string}
   * @memberof HorseraceQuestionData
   */
  denorm_series_name: string;
}

/**
 *
 * @export
 * @interface HorseraceSeriesSchemaResponse
 */
export interface HorseraceSeriesSchemaResponse {
  /**
   *
   * @type {Array<DenormSeriesSchema>}
   * @memberof HorseraceSeriesSchemaResponse
   */
  horserace_bundles?: Array<DenormSeriesSchema>;
  /**
   *
   * @type {Array<HorseraceQuestionData>}
   * @memberof HorseraceSeriesSchemaResponse
   */
  election_columns?: Array<HorseraceQuestionData>;
  /**
   *
   * @type {Array<HorseraceQuestionData>}
   * @memberof HorseraceSeriesSchemaResponse
   */
  election_force_columns?: Array<HorseraceQuestionData>;
  /**
   *
   * @type {Array<HorseraceQuestionData>}
   * @memberof HorseraceSeriesSchemaResponse
   */
  election_voted_columns?: Array<HorseraceQuestionData>;
  /**
   *
   * @type {Array<string>}
   * @memberof HorseraceSeriesSchemaResponse
   */
  errors?: Array<string>;
}
/**
 *
 * @export
 * @interface LocationInner
 */
export interface LocationInner {}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LocationType = {
  Nationwide: 'Nationwide',
  State: 'State',
  County: 'County',
  City: 'City',
  CongressionalDistrict: 'Congressional District',
  StateLegislativeUpper: 'State Legislative Upper',
  StateLegislativeLower: 'State Legislative Lower',
  ZipCode: 'Zip Code',
  Other: 'Other',
} as const;

export type LocationType = typeof LocationType[keyof typeof LocationType];

/**
 *
 * @export
 * @interface MandatoryQuestion
 */
export interface MandatoryQuestion {
  /**
   *
   * @type {string}
   * @memberof MandatoryQuestion
   */
  question_name: string;
  /**
   *
   * @type {MandatoryQuestionType}
   * @memberof MandatoryQuestion
   */
  mq_type?: MandatoryQuestionType;
  /**
   *
   * @type {Array<string>}
   * @memberof MandatoryQuestion
   */
  specific_answers?: Array<string>;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MandatoryQuestionType = {
  Any: 'any',
  Unanswered: 'unanswered',
  Specific: 'specific',
} as const;

export type MandatoryQuestionType =
  typeof MandatoryQuestionType[keyof typeof MandatoryQuestionType];

/**
 *
 * @export
 * @interface MatrixQuestionUpdate
 */
export interface MatrixQuestionUpdate {
  /**
   *
   * @type {string}
   * @memberof MatrixQuestionUpdate
   */
  question_type: MatrixQuestionUpdateQuestionTypeEnum;
  /**
   *
   * @type {Heading}
   * @memberof MatrixQuestionUpdate
   */
  heading: Heading;
  /**
   *
   * @type {number}
   * @memberof MatrixQuestionUpdate
   */
  page_number: number;
  /**
   *
   * @type {number}
   * @memberof MatrixQuestionUpdate
   */
  page_position: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MatrixQuestionUpdate
   */
  rows: Array<string>;
}

export const MatrixQuestionUpdateQuestionTypeEnum = {
  Matrix: 'matrix',
} as const;

export type MatrixQuestionUpdateQuestionTypeEnum =
  typeof MatrixQuestionUpdateQuestionTypeEnum[keyof typeof MatrixQuestionUpdateQuestionTypeEnum];

/**
 *
 * @export
 * @interface MetaData
 */
export interface MetaData {
  /**
   *
   * @type {number}
   * @memberof MetaData
   */
  sampleSize: number;
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  smId: string;
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  zendeskId: string;
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  electionType: string;
  /**
   *
   * @type {Array<SurveyLocation>}
   * @memberof MetaData
   */
  locations: Array<SurveyLocation>;
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  assignee: string;
  /**
   *
   * @type {string}
   * @memberof MetaData
   */
  name: string;
}
/**
 *
 * @export
 * @interface Metric
 */
export interface Metric {
  /**
   * Pollster-friendly name for the metric of the Facebook Ad Insights
   * @type {string}
   * @memberof Metric
   */
  name: string;
  /**
   * Target number of blah - None if unknown
   * @type {number}
   * @memberof Metric
   */
  target: number;
  /**
   * number of X collected
   * @type {number}
   * @memberof Metric
   */
  total: number;
  /**
   *
   * @type {Array<CollectorFloatCount>}
   * @memberof Metric
   */
  collector_counts: Array<CollectorFloatCount>;
}
/**
 *
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  filename: string;
  /**
   *
   * @type {File}
   * @memberof ModelFile
   */
  data: File;
}
/**
 *
 * @export
 * @interface OpenEndedUpdate
 */
export interface OpenEndedUpdate {
  /**
   *
   * @type {string}
   * @memberof OpenEndedUpdate
   */
  question_type: OpenEndedUpdateQuestionTypeEnum;
  /**
   *
   * @type {Heading}
   * @memberof OpenEndedUpdate
   */
  heading: Heading;
  /**
   *
   * @type {number}
   * @memberof OpenEndedUpdate
   */
  page_number: number;
  /**
   *
   * @type {number}
   * @memberof OpenEndedUpdate
   */
  page_position: number;
}

export const OpenEndedUpdateQuestionTypeEnum = {
  OpenEnded: 'open_ended',
} as const;

export type OpenEndedUpdateQuestionTypeEnum =
  typeof OpenEndedUpdateQuestionTypeEnum[keyof typeof OpenEndedUpdateQuestionTypeEnum];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PartyPicker = {
  All: 'party_all',
  LiberNopol: 'party_liber_nopol',
  Granular: 'party_granular',
} as const;

export type PartyPicker = typeof PartyPicker[keyof typeof PartyPicker];

/**
 * The poll ID to associate with these ads
 * @export
 * @interface PollID
 */
export interface PollID {
  /**
   *
   * @type {Backend}
   * @memberof PollID
   */
  backend?: Backend;
  /**
   *
   * @type {string}
   * @memberof PollID
   */
  id: string;
}

/**
 *
 * @export
 * @interface PollId
 */
export interface PollId {
  /**
   *
   * @type {Backend}
   * @memberof PollId
   */
  backend?: Backend;
  /**
   *
   * @type {string}
   * @memberof PollId
   */
  id: string;
}

/**
 *
 * @export
 * @interface PollServerException
 */
export interface PollServerException {
  /**
   *
   * @type {string}
   * @memberof PollServerException
   */
  exception_class: string;
  /**
   *
   * @type {string}
   * @memberof PollServerException
   */
  message: string;
}
/**
 *
 * @export
 * @interface PollSummary
 */
export interface PollSummary {
  /**
   *
   * @type {PollId}
   * @memberof PollSummary
   */
  id: PollId;
  /**
   *
   * @type {string}
   * @memberof PollSummary
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PollSummary
   */
  googleDocsQuestionsUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PollSummary
   */
  googleSheetsCrosstabsUrl?: string;
  /**
   *
   * @type {Set<number>}
   * @memberof PollSummary
   */
  facebookCampaignIds?: Set<number>;
  /**
   *
   * @type {ConfigId}
   * @memberof PollSummary
   */
  currentCrosstabsConfig?: ConfigId;
  /**
   *
   * @type {Array<ConfigId>}
   * @memberof PollSummary
   */
  configs?: Array<ConfigId>;
  /**
   *
   * @type {Pollster}
   * @memberof PollSummary
   */
  assignee?: Pollster;
  /**
   *
   * @type {string}
   * @memberof PollSummary
   */
  smId?: string;
  /**
   *
   * @type {string}
   * @memberof PollSummary
   */
  zendeskId?: string;
  /**
   *
   * @type {Array<SurveyLocation>}
   * @memberof PollSummary
   */
  locations?: Array<SurveyLocation>;
  /**
   *
   * @type {string}
   * @memberof PollSummary
   */
  electionType?: string;
  /**
   *
   * @type {number}
   * @memberof PollSummary
   */
  sampleSize?: number;
  /**
   *
   * @type {string}
   * @memberof PollSummary
   */
  finalConfigId?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PollSummary
   */
  closedTimestamps?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof PollSummary
   */
  horseracesConfirmed?: boolean;
}
/**
 * Represents the primary pollster assigned to this poll
 * @export
 * @interface Pollster
 */
export interface Pollster {
  /**
   *
   * @type {string}
   * @memberof Pollster
   */
  email: string;
}
/**
 *
 * @export
 * @interface QuestionAndAnswers
 */
export interface QuestionAndAnswers {
  /**
   *
   * @type {string}
   * @memberof QuestionAndAnswers
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof QuestionAndAnswers
   */
  heading: string;
  /**
   *
   * @type {Array<string>}
   * @memberof QuestionAndAnswers
   */
  aliases: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof QuestionAndAnswers
   */
  answers: Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const QuestionComponentPart = {
  Heading: 'heading',
  ChoiceText: 'choice text',
  RowText: 'row text',
} as const;

export type QuestionComponentPart =
  typeof QuestionComponentPart[keyof typeof QuestionComponentPart];

/**
 *
 * @export
 * @interface QuestionInfo
 */
export interface QuestionInfo {
  /**
   *
   * @type {string}
   * @memberof QuestionInfo
   */
  question_text: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof QuestionInfo
   */
  answers: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof QuestionInfo
   */
  page_number: number;
  /**
   *
   * @type {number}
   * @memberof QuestionInfo
   */
  page_position: number;
}
/**
 *
 * @export
 * @interface QuestionMatch
 */
export interface QuestionMatch {
  /**
   *
   * @type {QuestionNames}
   * @memberof QuestionMatch
   */
  name?: QuestionNames;
  /**
   *
   * @type {QuestionMatchType}
   * @memberof QuestionMatch
   */
  type: QuestionMatchType;
  /**
   * Helper class that provides a standard way to create an ABC using inheritance.
   * @type {object}
   * @memberof QuestionMatch
   */
  data?: object;
}

/**
 *
 * @export
 * @interface QuestionMatchSummary
 */
export interface QuestionMatchSummary {
  /**
   *
   * @type {Array<Array>}
   * @memberof QuestionMatchSummary
   */
  question_matches_and_answer_values?: Array<Array<any>>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const QuestionMatchType = {
  SingleChoice: 'single_choice',
  MultiSelect: 'multi_select',
  OrdinalCategorical: 'ordinal_categorical',
  Matrix: 'matrix',
  MatrixOrdinalCategorical: 'matrix_ordinal_categorical',
  FreeResponse: 'free_response',
  Unmatched: 'unmatched',
} as const;

export type QuestionMatchType =
  typeof QuestionMatchType[keyof typeof QuestionMatchType];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const QuestionNames = {
  Gender: 'gender',
  GenderNonBinary: 'gender_non_binary',
  BirthYear: 'birth_year',
  ZipCode: 'zip_code',
  Education: 'education',
  HispanicOrigin: 'hispanic_origin',
  PresidentVote2016: 'president_vote_2016',
  PresidentVote2020: 'president_vote_2020',
  HouseRepVote2020: 'house_rep_vote_2020',
  ElectionVoted: 'election_voted',
  Election: 'election',
  ElectionForce: 'election_force',
  Ethnicity: 'ethnicity',
  VoteIntention: 'vote_intention',
  Registration: 'registration',
  DemocratDegree: 'democrat_degree',
  RepublicanDegree: 'republican_degree',
  PartyAlignment: 'party_alignment',
  PartyId: 'party_id',
  MatrixFavorability: 'matrix_favorability',
  FirstName: 'first_name',
  LastName: 'last_name',
  Email: 'email',
  BidenRating: 'biden_rating',
  TrumpRating: 'trump_rating',
  DemocratOrRepublican: 'democrat or republican',
  LiberalOrConservative: 'liberal or conservative',
  GenericDoubt: 'generic doubt',
  GenericMotivatedToVote: 'generic motivated to vote',
  GenericSerious: 'generic serious',
  GenericFavorability: 'generic_favorability',
  GenericAgree: 'generic_agree',
  GenericSatisfied: 'generic_satisfied',
  GenericAppropriate: 'generic_appropriate',
  GenericApproval: 'generic_approval',
  GenericLikely: 'generic_likely',
  GenericMoreLessLikely: 'generic more/less likely',
  GenericSupport: 'generic_support',
  GenericConvincing: 'generic convincing',
  GenericImportant: 'generic important',
  GenericVoteConsideration: 'generic vote consideration',
  GenericNoWouldVoteConsideration: 'generic no would vote consideration',
  GenericVoteNoPositiveChoiceConsideration:
    'generic vote no positive choice consideration',
  GenericFamiliar: 'generic familiar',
  GenericTrust: 'generic trust',
  GenericSafety: 'generic safety',
  GenericClosely: 'generic closely',
  GenericEasyToDifficult: 'generic easy to difficult',
  GenericExcellentToPoor: 'generic excellent to poor',
  GenericPersuasive: 'generic persuasive',
  GenericConcerned: 'generic concerned',
  GenericSeriousMinorConcerns: 'generic serious minor concerns',
  GenericALotToNothingAtAll: 'generic a lot to nothing at all',
  GenericOneToTen: 'generic_one_to_ten',
  GenericZeroToTen: 'generic_zero_to_ten',
  PositivelyNegatively: 'positively negatively',
  GenericWellNotWell: 'generic well not well',
  GenericOrdinal: 'generic_ordinal',
  BlockRandomPage: 'block_random_page',
  WhyNotRegistered: 'why_not_registered',
} as const;

export type QuestionNames = typeof QuestionNames[keyof typeof QuestionNames];

/**
 * @type QuestionUpdatesInner
 * @export
 */
export type QuestionUpdatesInner =
  | ({ question_type: 'horserace' } & ChoiceUpdate)
  | ({ question_type: 'matrix' } & MatrixQuestionUpdate)
  | ({ question_type: 'multiple_choice' } & ChoiceUpdate)
  | ({ question_type: 'open_ended' } & OpenEndedUpdate)
  | ({ question_type: 'single_choice' } & ChoiceUpdate);

/**
 *
 * @export
 * @interface RecruitmentSummaryResponse
 */
export interface RecruitmentSummaryResponse {
  /**
   *
   * @type {TotalSegment}
   * @memberof RecruitmentSummaryResponse
   */
  total_segment: TotalSegment;
  /**
   *
   * @type {FilteredSegment}
   * @memberof RecruitmentSummaryResponse
   */
  filtered_segment: FilteredSegment;
  /**
   * The different partitionings of the respondents
   * @type {Array<Segment>}
   * @memberof RecruitmentSummaryResponse
   */
  segments: Array<Segment>;
  /**
   * Time of last response to the survey (timezone aware)
   * @type {string}
   * @memberof RecruitmentSummaryResponse
   */
  time_of_last_response: string;
  /**
   * Time that we last checked Surveymonkey for new data (timezone aware)
   * @type {string}
   * @memberof RecruitmentSummaryResponse
   */
  time_of_data_refresh: string;
  /**
   *
   * @type {FacebookInsights}
   * @memberof RecruitmentSummaryResponse
   */
  faceboook_insights?: FacebookInsights;
  /**
   * Description of Turnout Scenario
   * @type {TurnoutScenario}
   * @memberof RecruitmentSummaryResponse
   */
  turnout_scenario: TurnoutScenario;
}
/**
 *
 * @export
 * @interface RunCrosstabsResponse
 */
export interface RunCrosstabsResponse {
  /**
   *
   * @type {boolean}
   * @memberof RunCrosstabsResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof RunCrosstabsResponse
   */
  url?: string;
  /**
   *
   * @type {Array<PollServerException>}
   * @memberof RunCrosstabsResponse
   */
  errors?: Array<PollServerException>;
  /**
   *
   * @type {Array<TargetMarginalDeviationResponse>}
   * @memberof RunCrosstabsResponse
   */
  variables_outside_margins?: Array<TargetMarginalDeviationResponse>;
  /**
   *
   * @type {string}
   * @memberof RunCrosstabsResponse
   */
  topline_url?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RunCrosstabsResponse
   */
  warnings?: Array<string>;
}
/**
 *
 * @export
 * @interface Segment
 */
export interface Segment {
  /**
   * The unique denorm_series_builder name of the segment.
   * @type {string}
   * @memberof Segment
   */
  name: string;
  /**
   * A pollster-friendly name for this segment
   * @type {string}
   * @memberof Segment
   */
  display_name: string;
  /**
   * Ordered list of the subsets which make up the segment
   * @type {Array<SegmentCategory>}
   * @memberof Segment
   */
  categories: Array<SegmentCategory>;
}
/**
 *
 * @export
 * @interface SegmentCategory
 */
export interface SegmentCategory {
  /**
   * Pollster friendly name for the subset of the segment
   * @type {string}
   * @memberof SegmentCategory
   */
  name: string;
  /**
   * The target number of respondents in this category - None if unknown
   * @type {number}
   * @memberof SegmentCategory
   */
  target: number;
  /**
   * number of respondents collected in this category
   * @type {number}
   * @memberof SegmentCategory
   */
  total: number;
  /**
   * List of the counts of respondents from specific collectors that are members of this category
   * @type {Array<CollectorCount>}
   * @memberof SegmentCategory
   */
  collector_counts: Array<CollectorCount>;
}
/**
 *
 * @export
 * @interface SegmentColumnInput
 */
export interface SegmentColumnInput {
  /**
   *
   * @type {Array<DenormSeriesValueType>}
   * @memberof SegmentColumnInput
   */
  allowed_types: Array<DenormSeriesValueType>;
  /**
   *
   * @type {boolean}
   * @memberof SegmentColumnInput
   */
  nargs?: boolean;
}
/**
 *
 * @export
 * @interface SegmentFormStructure
 */
export interface SegmentFormStructure {
  /**
   *
   * @type {SegmentColumnInput}
   * @memberof SegmentFormStructure
   */
  column_input: SegmentColumnInput;
  /**
   *
   * @type {SegmentValueInputType}
   * @memberof SegmentFormStructure
   */
  value_input_type?: SegmentValueInputType;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SegmentGroup = {
  Unexposed: 'unexposed',
  Flag: 'flag',
  Collapse: 'collapse',
  Horserace: 'horserace',
} as const;

export type SegmentGroup = typeof SegmentGroup[keyof typeof SegmentGroup];

/**
 *
 * @export
 * @interface SegmentSchema
 */
export interface SegmentSchema {
  /**
   *
   * @type {string}
   * @memberof SegmentSchema
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SegmentSchema
   */
  display_name?: string;
  /**
   *
   * @type {SegmentGroup}
   * @memberof SegmentSchema
   */
  segment_group: SegmentGroup;
  /**
   *
   * @type {SegmentFormStructure}
   * @memberof SegmentSchema
   */
  form_structure?: SegmentFormStructure;
}

/**
 *
 * @export
 * @interface SegmentSchemaListResponse
 */
export interface SegmentSchemaListResponse {
  /**
   *
   * @type {Array<SegmentSchema>}
   * @memberof SegmentSchemaListResponse
   */
  segment_schemas: Array<SegmentSchema>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SegmentValueInputType = {
  Flexible: 'flexible',
  List: 'list',
  MappingGrid: 'mapping_grid',
  CaseWhen: 'case_when',
  CategorySubset: 'category_subset',
} as const;

export type SegmentValueInputType =
  typeof SegmentValueInputType[keyof typeof SegmentValueInputType];

/**
 *
 * @export
 * @interface SegmentValues
 */
export interface SegmentValues {
  /**
   *
   * @type {Array<string>}
   * @memberof SegmentValues
   */
  input_denorm_series_names: Array<string>;
  /**
   *
   * @type {Values}
   * @memberof SegmentValues
   */
  values: Values;
  /**
   *
   * @type {boolean}
   * @memberof SegmentValues
   */
  null_handling?: boolean;
}
/**
 *
 * @export
 * @interface SmallColumnFilter
 */
export interface SmallColumnFilter {
  /**
   *
   * @type {number}
   * @memberof SmallColumnFilter
   */
  min_respondent_count?: number;
  /**
   *
   * @type {number}
   * @memberof SmallColumnFilter
   */
  min_respondent_percentage?: number;
}
/**
 *
 * @export
 * @interface Step
 */
export interface Step {
  /**
   * Identifier for the job or poll this step is part of, or None if top level
   * @type {string}
   * @memberof Step
   */
  parent_id: string;
  /**
   * short name of the step
   * @type {string}
   * @memberof Step
   */
  action: string;
  /**
   * Human readable description of this step
   * @type {string}
   * @memberof Step
   */
  description?: string;
  /**
   * Timestamp of this step
   * @type {string}
   * @memberof Step
   */
  created_at?: string;
  /**
   * Timestamp of this step
   * @type {string}
   * @memberof Step
   */
  start_time?: string;
  /**
   * Timestamp of this step
   * @type {string}
   * @memberof Step
   */
  end_time?: string;
  /**
   *
   * @type {StepStatus}
   * @memberof Step
   */
  status?: StepStatus;
  /**
   * Identifier for the job this step is part of.
   * @type {string}
   * @memberof Step
   */
  step_id: string;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StepStatus = {
  Pending: 'pending',
  Starting: 'starting',
  Canceled: 'canceled',
  Succeeded: 'succeeded',
  Failed: 'failed',
  TimedOut: 'timed_out',
  Interrupted: 'interrupted',
  Message: 'message',
} as const;

export type StepStatus = typeof StepStatus[keyof typeof StepStatus];

/**
 *
 * @export
 * @interface SurveyLocation
 */
export interface SurveyLocation {
  /**
   *
   * @type {LocationType}
   * @memberof SurveyLocation
   */
  locationType?: LocationType;
  /**
   *
   * @type {string}
   * @memberof SurveyLocation
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof SurveyLocation
   */
  place?: string;
}

/**
 *
 * @export
 * @interface SurveyMonkeyCallback
 */
export interface SurveyMonkeyCallback {
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyCallback
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyCallback
   */
  filter_type: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyCallback
   */
  filter_id: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyCallback
   */
  event_type: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyCallback
   */
  event_id: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyCallback
   */
  object_type: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyCallback
   */
  object_id: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyCallback
   */
  event_datetime: string;
  /**
   *
   * @type {SurveyMonkeyResources}
   * @memberof SurveyMonkeyCallback
   */
  resources: SurveyMonkeyResources;
}
/**
 *
 * @export
 * @interface SurveyMonkeyId
 */
export interface SurveyMonkeyId {
  /**
   *
   * @type {number}
   * @memberof SurveyMonkeyId
   */
  id: number;
}
/**
 *
 * @export
 * @interface SurveyMonkeyResources
 */
export interface SurveyMonkeyResources {
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyResources
   */
  respondent_id?: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyResources
   */
  recipient_id?: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyResources
   */
  collector_id?: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyResources
   */
  survey_id?: string;
  /**
   *
   * @type {string}
   * @memberof SurveyMonkeyResources
   */
  user_id?: string;
}
/**
 *
 * @export
 * @interface SurveyTemplate
 */
export interface SurveyTemplate {
  /**
   *
   * @type {string}
   * @memberof SurveyTemplate
   */
  name: string;
  /**
   *
   * @type {Array<FormVariable>}
   * @memberof SurveyTemplate
   */
  form_variables: Array<FormVariable>;
  /**
   *
   * @type {Array<QuestionUpdatesInner>}
   * @memberof SurveyTemplate
   */
  question_updates: Array<QuestionUpdatesInner>;
}
/**
 *
 * @export
 * @interface SurveyTemplatesResponse
 */
export interface SurveyTemplatesResponse {
  /**
   *
   * @type {{ [key: string]: SurveyTemplate; }}
   * @memberof SurveyTemplatesResponse
   */
  templates: { [key: string]: SurveyTemplate };
}
/**
 *
 * @export
 * @interface SurveyValidationReport
 */
export interface SurveyValidationReport {
  /**
   *
   * @type {PollId}
   * @memberof SurveyValidationReport
   */
  poll_id: PollId;
  /**
   *
   * @type {boolean}
   * @memberof SurveyValidationReport
   */
  valid: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SurveyValidationReport
   */
  missing_survey_monkey_id: boolean;
  /**
   *
   * @type {QuestionMatchSummary}
   * @memberof SurveyValidationReport
   */
  question_match_summary: QuestionMatchSummary;
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof SurveyValidationReport
   */
  essential_question_summary: { [key: string]: boolean };
  /**
   *
   * @type {Array<QuestionInfo>}
   * @memberof SurveyValidationReport
   */
  duplicate_questions: Array<QuestionInfo>;
  /**
   *
   * @type {Array<Array>}
   * @memberof SurveyValidationReport
   */
  duplicate_default_questions: Array<Array<any>>;
  /**
   *
   * @type {Array<QuestionInfo>}
   * @memberof SurveyValidationReport
   */
  duplicate_survey_choices: Array<QuestionInfo>;
  /**
   *
   * @type {Array<UnsupportedQuestionDatum>}
   * @memberof SurveyValidationReport
   */
  unsupported_questions: Array<UnsupportedQuestionDatum>;
  /**
   *
   * @type {Array<CleanTextViolation>}
   * @memberof SurveyValidationReport
   */
  clean_text_report: Array<CleanTextViolation>;
  /**
   *
   * @type {Array<string>}
   * @memberof SurveyValidationReport
   */
  breaking_errors: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SurveyValidationReport
   */
  need_to_reset_reasons: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SurveyValidationReport
   */
  non_breaking_errors: Array<string>;
}
/**
 *
 * @export
 * @interface TargetMarginalDeviationResponse
 */
export interface TargetMarginalDeviationResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof TargetMarginalDeviationResponse
   */
  name: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TargetMarginalDeviationResponse
   */
  target_marginal: number;
  /**
   *
   * @type {number}
   * @memberof TargetMarginalDeviationResponse
   */
  final_marginal: number;
  /**
   *
   * @type {number}
   * @memberof TargetMarginalDeviationResponse
   */
  deviation: number;
  /**
   *
   * @type {number}
   * @memberof TargetMarginalDeviationResponse
   */
  deviation_threshold: number;
}
/**
 *
 * @export
 * @interface TargetResult
 */
export interface TargetResult {
  /**
   *
   * @type {string}
   * @memberof TargetResult
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TargetResult
   */
  sub_name: string;
  /**
   *
   * @type {number}
   * @memberof TargetResult
   */
  target_pct: number;
  /**
   *
   * @type {number}
   * @memberof TargetResult
   */
  unweighted_among_all: number;
  /**
   *
   * @type {number}
   * @memberof TargetResult
   */
  weighted_among_all: number;
  /**
   *
   * @type {number}
   * @memberof TargetResult
   */
  avg_weight: number;
  /**
   *
   * @type {number}
   * @memberof TargetResult
   */
  weighted_among_non_missing: number;
  /**
   *
   * @type {number}
   * @memberof TargetResult
   */
  abs_diff_target_wanm: number;
}
/**
 * A single category segment for counting the number of unfiltered respondents
 * @export
 * @interface TotalSegment
 */
export interface TotalSegment {
  /**
   * The unique denorm_series_builder name of the segment.
   * @type {string}
   * @memberof TotalSegment
   */
  name: string;
  /**
   * A pollster-friendly name for this segment
   * @type {string}
   * @memberof TotalSegment
   */
  display_name: string;
  /**
   * Ordered list of the subsets which make up the segment
   * @type {Array<SegmentCategory>}
   * @memberof TotalSegment
   */
  categories: Array<SegmentCategory>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const TurnoutScenario = {
  Voters22g2022Lv: '["voters_22g","2022 LV"]',
  RegVotersRv: '["reg_voters","RV"]',
} as const;

export type TurnoutScenario =
  typeof TurnoutScenario[keyof typeof TurnoutScenario];

/**
 *
 * @export
 * @interface UnsupportedQuestionDatum
 */
export interface UnsupportedQuestionDatum {
  /**
   *
   * @type {QuestionInfo}
   * @memberof UnsupportedQuestionDatum
   */
  question_info: QuestionInfo;
  /**
   *
   * @type {Array<string>}
   * @memberof UnsupportedQuestionDatum
   */
  errors: Array<string>;
}
/**
 *
 * @export
 * @interface ValidateSurveyResponse
 */
export interface ValidateSurveyResponse {
  /**
   *
   * @type {SurveyValidationReport}
   * @memberof ValidateSurveyResponse
   */
  validation_report: SurveyValidationReport;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<LocationInner>}
   * @memberof ValidationError
   */
  loc: Array<LocationInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ValueFormatter = {
  Identity: 'identity',
  TsToGmt: 'ts_to_gmt',
  TsToPython: 'ts_to_python',
  BoolToYesOrNo: 'bool_to_yes_or_no',
} as const;

export type ValueFormatter = typeof ValueFormatter[keyof typeof ValueFormatter];

/**
 *
 * @export
 * @interface Values
 */
export interface Values {}
/**
 *
 * @export
 * @interface VarzResponse
 */
export interface VarzResponse {
  /**
   *
   * @type {Array<PollSummary>}
   * @memberof VarzResponse
   */
  polls: Array<PollSummary>;
  /**
   *
   * @type {Array<string>}
   * @memberof VarzResponse
   */
  survey_responses?: Array<string>;
}
/**
 *
 * @export
 * @interface ViewClosedPollsResponse
 */
export interface ViewClosedPollsResponse {
  /**
   *
   * @type {Array<ArchiveStatus>}
   * @memberof ViewClosedPollsResponse
   */
  polls: Array<ArchiveStatus>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ViolationRating = {
  Red: 'red',
  Yellow: 'yellow',
} as const;

export type ViolationRating =
  typeof ViolationRating[keyof typeof ViolationRating];

/**
 *
 * @export
 * @interface WeightingConfig
 */
export interface WeightingConfig {
  /**
   *
   * @type {string}
   * @memberof WeightingConfig
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof WeightingConfig
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof WeightingConfig
   */
  filter_column?: string;
  /**
   *
   * @type {{ [key: string]: WeightingTargetGroup; }}
   * @memberof WeightingConfig
   */
  targets?: { [key: string]: WeightingTargetGroup };
  /**
   *
   * @type {number}
   * @memberof WeightingConfig
   */
  min_weight?: number;
  /**
   *
   * @type {number}
   * @memberof WeightingConfig
   */
  max_weight?: number;
  /**
   *
   * @type {number}
   * @memberof WeightingConfig
   */
  design_effect_penalty?: number;
  /**
   *
   * @type {number}
   * @memberof WeightingConfig
   */
  edited?: number;
  /**
   *
   * @type {number}
   * @memberof WeightingConfig
   */
  created?: number;
  /**
   *
   * @type {boolean}
   * @memberof WeightingConfig
   */
  is_recruitment_config?: boolean;
}
/**
 *
 * @export
 * @interface WeightingConfigResponse
 */
export interface WeightingConfigResponse {
  /**
   *
   * @type {WeightingConfig}
   * @memberof WeightingConfigResponse
   */
  config?: WeightingConfig;
  /**
   *
   * @type {Array<string>}
   * @memberof WeightingConfigResponse
   */
  errors: Array<string>;
}
/**
 *
 * @export
 * @interface WeightingConfigSpec
 */
export interface WeightingConfigSpec {
  /**
   *
   * @type {string}
   * @memberof WeightingConfigSpec
   */
  name: string;
  /**
   *
   * @type {Array<Array<WeightingTargetSubgroup>>}
   * @memberof WeightingConfigSpec
   */
  weights?: Array<Array<WeightingTargetSubgroup>>;
}
/**
 *
 * @export
 * @interface WeightingReport
 */
export interface WeightingReport {
  /**
   *
   * @type {Array<TargetResult>}
   * @memberof WeightingReport
   */
  target_responses: Array<TargetResult>;
  /**
   *
   * @type {Array<string>}
   * @memberof WeightingReport
   */
  errors: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof WeightingReport
   */
  warnings: Array<string>;
  /**
   *
   * @type {WeightsInfo}
   * @memberof WeightingReport
   */
  info?: WeightsInfo;
}
/**
 *
 * @export
 * @interface WeightingTargetGroup
 */
export interface WeightingTargetGroup {
  /**
   *
   * @type {string}
   * @memberof WeightingTargetGroup
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof WeightingTargetGroup
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof WeightingTargetGroup
   */
  enabled?: boolean;
  /**
   *
   * @type {{ [key: string]: WeightingTargetSubgroup; }}
   * @memberof WeightingTargetGroup
   */
  subgroups?: { [key: string]: WeightingTargetSubgroup };
  /**
   *
   * @type {number}
   * @memberof WeightingTargetGroup
   */
  relative_importance?: number;
}
/**
 *
 * @export
 * @interface WeightingTargetSubgroup
 */
export interface WeightingTargetSubgroup {
  /**
   *
   * @type {string}
   * @memberof WeightingTargetSubgroup
   */
  uuid?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof WeightingTargetSubgroup
   */
  specification: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof WeightingTargetSubgroup
   */
  target: number;
  /**
   *
   * @type {boolean}
   * @memberof WeightingTargetSubgroup
   */
  include?: boolean;
}
/**
 *
 * @export
 * @interface WeightsInfo
 */
export interface WeightsInfo {
  /**
   *
   * @type {number}
   * @memberof WeightsInfo
   */
  min_observed_weight: number;
  /**
   *
   * @type {number}
   * @memberof WeightsInfo
   */
  max_observed_weight: number;
  /**
   *
   * @type {number}
   * @memberof WeightsInfo
   */
  design_effect: number;
  /**
   *
   * @type {number}
   * @memberof WeightsInfo
   */
  modeled_margin_of_error: number;
  /**
   *
   * @type {Array<number>}
   * @memberof WeightsInfo
   */
  histogram: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof WeightsInfo
   */
  bin_edges: Array<number>;
}

/**
 * BulkPollingApi - axios parameter creator
 * @export
 */
export const BulkPollingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Single Poll Instance
     * @param {string} zendeskId
     * @param {BulkEngagementSinglePollInstance} bulkEngagementSinglePollInstance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut:
      async (
        zendeskId: string,
        bulkEngagementSinglePollInstance: BulkEngagementSinglePollInstance,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'zendeskId' is not null or undefined
        assertParamExists(
          'addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut',
          'zendeskId',
          zendeskId,
        );
        // verify required parameter 'bulkEngagementSinglePollInstance' is not null or undefined
        assertParamExists(
          'addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut',
          'bulkEngagementSinglePollInstance',
          bulkEngagementSinglePollInstance,
        );
        const localVarPath =
          `/bulk_polling/engagements/{zendesk_id}/add_single_poll_instance`.replace(
            `{${'zendesk_id'}}`,
            encodeURIComponent(String(zendeskId)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          bulkEngagementSinglePollInstance,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Build Custom Presentation
     * @param {BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut} bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildCustomPresentationBulkPollingBuildCustomPresentationPut: async (
      bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut: BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut' is not null or undefined
      assertParamExists(
        'buildCustomPresentationBulkPollingBuildCustomPresentationPut',
        'bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut',
        bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
      );
      const localVarPath = `/bulk_polling/build_custom_presentation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Build Weighting Config
     * @param {BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut} bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildWeightingConfigBulkPollingBuildWeightingConfigPut: async (
      bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut: BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut' is not null or undefined
      assertParamExists(
        'buildWeightingConfigBulkPollingBuildWeightingConfigPut',
        'bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut',
        bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
      );
      const localVarPath = `/bulk_polling/build_weighting_config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Engagement
     * @param {string} zendeskId
     * @param {Array<BulkEngagementSinglePollInstance>} [bulkEngagementSinglePollInstance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEngagementBulkPollingEngagementsCreatePost: async (
      zendeskId: string,
      bulkEngagementSinglePollInstance?: Array<BulkEngagementSinglePollInstance>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'zendeskId' is not null or undefined
      assertParamExists(
        'createEngagementBulkPollingEngagementsCreatePost',
        'zendeskId',
        zendeskId,
      );
      const localVarPath = `/bulk_polling/engagements/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (zendeskId !== undefined) {
        localVarQueryParameter['zendesk_id'] = zendeskId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkEngagementSinglePollInstance,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Survey From Base
     * @param {CloneSurveyInput} cloneSurveyInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSurveyFromBaseBulkPollingCreateSurveyFromBasePut: async (
      cloneSurveyInput: CloneSurveyInput,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cloneSurveyInput' is not null or undefined
      assertParamExists(
        'createSurveyFromBaseBulkPollingCreateSurveyFromBasePut',
        'cloneSurveyInput',
        cloneSurveyInput,
      );
      const localVarPath = `/bulk_polling/create_survey_from_base`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cloneSurveyInput,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Engagement
     * @param {string} zendeskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEngagementBulkPollingEngagementsZendeskIdDelete: async (
      zendeskId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'zendeskId' is not null or undefined
      assertParamExists(
        'deleteEngagementBulkPollingEngagementsZendeskIdDelete',
        'zendeskId',
        zendeskId,
      );
      const localVarPath = `/bulk_polling/engagements/{zendesk_id}`.replace(
        `{${'zendesk_id'}}`,
        encodeURIComponent(String(zendeskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Single Poll Instance By Survey Id
     * @param {string} zendeskId
     * @param {number} surveyMonkeyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete:
      async (
        zendeskId: string,
        surveyMonkeyId: number,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'zendeskId' is not null or undefined
        assertParamExists(
          'deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete',
          'zendeskId',
          zendeskId,
        );
        // verify required parameter 'surveyMonkeyId' is not null or undefined
        assertParamExists(
          'deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete',
          'surveyMonkeyId',
          surveyMonkeyId,
        );
        const localVarPath =
          `/bulk_polling/engagements/{zendesk_id}/delete_single_poll_instance`.replace(
            `{${'zendesk_id'}}`,
            encodeURIComponent(String(zendeskId)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'DELETE',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (surveyMonkeyId !== undefined) {
          localVarQueryParameter['survey_monkey_id'] = surveyMonkeyId;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get All Engagements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEngagementsBulkPollingEngagementsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/bulk_polling/engagements`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Engagement By Zendesk Id
     * @param {string} zendeskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEngagementByZendeskIdBulkPollingEngagementsZendeskIdGet: async (
      zendeskId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'zendeskId' is not null or undefined
      assertParamExists(
        'getEngagementByZendeskIdBulkPollingEngagementsZendeskIdGet',
        'zendeskId',
        zendeskId,
      );
      const localVarPath = `/bulk_polling/engagements/{zendesk_id}`.replace(
        `{${'zendesk_id'}}`,
        encodeURIComponent(String(zendeskId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Run Batch Crosstabs
     * @param {string} zendeskId
     * @param {string} weightingConfigName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost:
      async (
        zendeskId: string,
        weightingConfigName: string,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'zendeskId' is not null or undefined
        assertParamExists(
          'runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost',
          'zendeskId',
          zendeskId,
        );
        // verify required parameter 'weightingConfigName' is not null or undefined
        assertParamExists(
          'runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost',
          'weightingConfigName',
          weightingConfigName,
        );
        const localVarPath =
          `/bulk_polling/engagements/{zendesk_id}/run_batch_crosstabs`.replace(
            `{${'zendesk_id'}}`,
            encodeURIComponent(String(zendeskId)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (weightingConfigName !== undefined) {
          localVarQueryParameter['weighting_config_name'] = weightingConfigName;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Update Single Poll Instance
     * @param {string} zendeskId
     * @param {BulkEngagementSinglePollInstance} bulkEngagementSinglePollInstance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut:
      async (
        zendeskId: string,
        bulkEngagementSinglePollInstance: BulkEngagementSinglePollInstance,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'zendeskId' is not null or undefined
        assertParamExists(
          'updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut',
          'zendeskId',
          zendeskId,
        );
        // verify required parameter 'bulkEngagementSinglePollInstance' is not null or undefined
        assertParamExists(
          'updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut',
          'bulkEngagementSinglePollInstance',
          bulkEngagementSinglePollInstance,
        );
        const localVarPath =
          `/bulk_polling/engagements/{zendesk_id}/update_single_poll_instance`.replace(
            `{${'zendesk_id'}}`,
            encodeURIComponent(String(zendeskId)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          bulkEngagementSinglePollInstance,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * BulkPollingApi - functional programming interface
 * @export
 */
export const BulkPollingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    BulkPollingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Single Poll Instance
     * @param {string} zendeskId
     * @param {BulkEngagementSinglePollInstance} bulkEngagementSinglePollInstance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut(
      zendeskId: string,
      bulkEngagementSinglePollInstance: BulkEngagementSinglePollInstance,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkEngagementSinglePollInstance>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut(
          zendeskId,
          bulkEngagementSinglePollInstance,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Build Custom Presentation
     * @param {BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut} bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buildCustomPresentationBulkPollingBuildCustomPresentationPut(
      bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut: BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CrosstabPresentationUpdateReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.buildCustomPresentationBulkPollingBuildCustomPresentationPut(
          bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Build Weighting Config
     * @param {BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut} bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buildWeightingConfigBulkPollingBuildWeightingConfigPut(
      bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut: BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WeightingConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.buildWeightingConfigBulkPollingBuildWeightingConfigPut(
          bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create Engagement
     * @param {string} zendeskId
     * @param {Array<BulkEngagementSinglePollInstance>} [bulkEngagementSinglePollInstance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEngagementBulkPollingEngagementsCreatePost(
      zendeskId: string,
      bulkEngagementSinglePollInstance?: Array<BulkEngagementSinglePollInstance>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkPollingEngagement>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createEngagementBulkPollingEngagementsCreatePost(
          zendeskId,
          bulkEngagementSinglePollInstance,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create Survey From Base
     * @param {CloneSurveyInput} cloneSurveyInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSurveyFromBaseBulkPollingCreateSurveyFromBasePut(
      cloneSurveyInput: CloneSurveyInput,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CloneSurveyCreatedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSurveyFromBaseBulkPollingCreateSurveyFromBasePut(
          cloneSurveyInput,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete Engagement
     * @param {string} zendeskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEngagementBulkPollingEngagementsZendeskIdDelete(
      zendeskId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteEngagementBulkPollingEngagementsZendeskIdDelete(
          zendeskId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete Single Poll Instance By Survey Id
     * @param {string} zendeskId
     * @param {number} surveyMonkeyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete(
      zendeskId: string,
      surveyMonkeyId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete(
          zendeskId,
          surveyMonkeyId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get All Engagements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllEngagementsBulkPollingEngagementsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: GetBulkPollingEngagement }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllEngagementsBulkPollingEngagementsGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Engagement By Zendesk Id
     * @param {string} zendeskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEngagementByZendeskIdBulkPollingEngagementsZendeskIdGet(
      zendeskId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetBulkPollingEngagement>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEngagementByZendeskIdBulkPollingEngagementsZendeskIdGet(
          zendeskId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Run Batch Crosstabs
     * @param {string} zendeskId
     * @param {string} weightingConfigName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost(
      zendeskId: string,
      weightingConfigName: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BatchCrosstabsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost(
          zendeskId,
          weightingConfigName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Single Poll Instance
     * @param {string} zendeskId
     * @param {BulkEngagementSinglePollInstance} bulkEngagementSinglePollInstance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut(
      zendeskId: string,
      bulkEngagementSinglePollInstance: BulkEngagementSinglePollInstance,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkEngagementSinglePollInstance>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut(
          zendeskId,
          bulkEngagementSinglePollInstance,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BulkPollingApi - factory interface
 * @export
 */
export const BulkPollingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BulkPollingApiFp(configuration);
  return {
    /**
     *
     * @summary Add Single Poll Instance
     * @param {string} zendeskId
     * @param {BulkEngagementSinglePollInstance} bulkEngagementSinglePollInstance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut(
      zendeskId: string,
      bulkEngagementSinglePollInstance: BulkEngagementSinglePollInstance,
      options?: any,
    ): AxiosPromise<BulkEngagementSinglePollInstance> {
      return localVarFp
        .addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut(
          zendeskId,
          bulkEngagementSinglePollInstance,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Build Custom Presentation
     * @param {BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut} bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildCustomPresentationBulkPollingBuildCustomPresentationPut(
      bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut: BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
      options?: any,
    ): AxiosPromise<CrosstabPresentationUpdateReport> {
      return localVarFp
        .buildCustomPresentationBulkPollingBuildCustomPresentationPut(
          bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Build Weighting Config
     * @param {BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut} bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildWeightingConfigBulkPollingBuildWeightingConfigPut(
      bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut: BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
      options?: any,
    ): AxiosPromise<WeightingConfig> {
      return localVarFp
        .buildWeightingConfigBulkPollingBuildWeightingConfigPut(
          bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Engagement
     * @param {string} zendeskId
     * @param {Array<BulkEngagementSinglePollInstance>} [bulkEngagementSinglePollInstance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEngagementBulkPollingEngagementsCreatePost(
      zendeskId: string,
      bulkEngagementSinglePollInstance?: Array<BulkEngagementSinglePollInstance>,
      options?: any,
    ): AxiosPromise<BulkPollingEngagement> {
      return localVarFp
        .createEngagementBulkPollingEngagementsCreatePost(
          zendeskId,
          bulkEngagementSinglePollInstance,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Survey From Base
     * @param {CloneSurveyInput} cloneSurveyInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSurveyFromBaseBulkPollingCreateSurveyFromBasePut(
      cloneSurveyInput: CloneSurveyInput,
      options?: any,
    ): AxiosPromise<CloneSurveyCreatedResponse> {
      return localVarFp
        .createSurveyFromBaseBulkPollingCreateSurveyFromBasePut(
          cloneSurveyInput,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Engagement
     * @param {string} zendeskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEngagementBulkPollingEngagementsZendeskIdDelete(
      zendeskId: string,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .deleteEngagementBulkPollingEngagementsZendeskIdDelete(
          zendeskId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Single Poll Instance By Survey Id
     * @param {string} zendeskId
     * @param {number} surveyMonkeyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete(
      zendeskId: string,
      surveyMonkeyId: number,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete(
          zendeskId,
          surveyMonkeyId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get All Engagements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEngagementsBulkPollingEngagementsGet(
      options?: any,
    ): AxiosPromise<{ [key: string]: GetBulkPollingEngagement }> {
      return localVarFp
        .getAllEngagementsBulkPollingEngagementsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Engagement By Zendesk Id
     * @param {string} zendeskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEngagementByZendeskIdBulkPollingEngagementsZendeskIdGet(
      zendeskId: string,
      options?: any,
    ): AxiosPromise<GetBulkPollingEngagement> {
      return localVarFp
        .getEngagementByZendeskIdBulkPollingEngagementsZendeskIdGet(
          zendeskId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Run Batch Crosstabs
     * @param {string} zendeskId
     * @param {string} weightingConfigName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost(
      zendeskId: string,
      weightingConfigName: string,
      options?: any,
    ): AxiosPromise<BatchCrosstabsResponse> {
      return localVarFp
        .runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost(
          zendeskId,
          weightingConfigName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Single Poll Instance
     * @param {string} zendeskId
     * @param {BulkEngagementSinglePollInstance} bulkEngagementSinglePollInstance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut(
      zendeskId: string,
      bulkEngagementSinglePollInstance: BulkEngagementSinglePollInstance,
      options?: any,
    ): AxiosPromise<BulkEngagementSinglePollInstance> {
      return localVarFp
        .updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut(
          zendeskId,
          bulkEngagementSinglePollInstance,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BulkPollingApi - object-oriented interface
 * @export
 * @class BulkPollingApi
 * @extends {BaseAPI}
 */
export class BulkPollingApi extends BaseAPI {
  /**
   *
   * @summary Add Single Poll Instance
   * @param {string} zendeskId
   * @param {BulkEngagementSinglePollInstance} bulkEngagementSinglePollInstance
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut(
    zendeskId: string,
    bulkEngagementSinglePollInstance: BulkEngagementSinglePollInstance,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .addSinglePollInstanceBulkPollingEngagementsZendeskIdAddSinglePollInstancePut(
        zendeskId,
        bulkEngagementSinglePollInstance,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Build Custom Presentation
   * @param {BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut} bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public buildCustomPresentationBulkPollingBuildCustomPresentationPut(
    bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut: BodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .buildCustomPresentationBulkPollingBuildCustomPresentationPut(
        bodyBuildCustomPresentationBulkPollingBuildCustomPresentationPut,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Build Weighting Config
   * @param {BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut} bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public buildWeightingConfigBulkPollingBuildWeightingConfigPut(
    bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut: BodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .buildWeightingConfigBulkPollingBuildWeightingConfigPut(
        bodyBuildWeightingConfigBulkPollingBuildWeightingConfigPut,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Engagement
   * @param {string} zendeskId
   * @param {Array<BulkEngagementSinglePollInstance>} [bulkEngagementSinglePollInstance]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public createEngagementBulkPollingEngagementsCreatePost(
    zendeskId: string,
    bulkEngagementSinglePollInstance?: Array<BulkEngagementSinglePollInstance>,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .createEngagementBulkPollingEngagementsCreatePost(
        zendeskId,
        bulkEngagementSinglePollInstance,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Survey From Base
   * @param {CloneSurveyInput} cloneSurveyInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public createSurveyFromBaseBulkPollingCreateSurveyFromBasePut(
    cloneSurveyInput: CloneSurveyInput,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .createSurveyFromBaseBulkPollingCreateSurveyFromBasePut(
        cloneSurveyInput,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Engagement
   * @param {string} zendeskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public deleteEngagementBulkPollingEngagementsZendeskIdDelete(
    zendeskId: string,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .deleteEngagementBulkPollingEngagementsZendeskIdDelete(zendeskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Single Poll Instance By Survey Id
   * @param {string} zendeskId
   * @param {number} surveyMonkeyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete(
    zendeskId: string,
    surveyMonkeyId: number,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .deleteSinglePollInstanceBySurveyIdBulkPollingEngagementsZendeskIdDeleteSinglePollInstanceDelete(
        zendeskId,
        surveyMonkeyId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get All Engagements
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public getAllEngagementsBulkPollingEngagementsGet(
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .getAllEngagementsBulkPollingEngagementsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Engagement By Zendesk Id
   * @param {string} zendeskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public getEngagementByZendeskIdBulkPollingEngagementsZendeskIdGet(
    zendeskId: string,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .getEngagementByZendeskIdBulkPollingEngagementsZendeskIdGet(
        zendeskId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Run Batch Crosstabs
   * @param {string} zendeskId
   * @param {string} weightingConfigName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost(
    zendeskId: string,
    weightingConfigName: string,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .runBatchCrosstabsBulkPollingEngagementsZendeskIdRunBatchCrosstabsPost(
        zendeskId,
        weightingConfigName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Single Poll Instance
   * @param {string} zendeskId
   * @param {BulkEngagementSinglePollInstance} bulkEngagementSinglePollInstance
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BulkPollingApi
   */
  public updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut(
    zendeskId: string,
    bulkEngagementSinglePollInstance: BulkEngagementSinglePollInstance,
    options?: AxiosRequestConfig,
  ) {
    return BulkPollingApiFp(this.configuration)
      .updateSinglePollInstanceBulkPollingEngagementsZendeskIdUpdateSinglePollInstancePut(
        zendeskId,
        bulkEngagementSinglePollInstance,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CrosstabPresentationApi - axios parameter creator
 * @export
 */
export const CrosstabPresentationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Addable Presentation Groups
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddablePresentationGroupsCrosstabsPresentationGetAddablePresentationGroupsPut:
      async (
        pollId: PollId,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'pollId' is not null or undefined
        assertParamExists(
          'getAddablePresentationGroupsCrosstabsPresentationGetAddablePresentationGroupsPut',
          'pollId',
          pollId,
        );
        const localVarPath = `/crosstabs_presentation/get_addable_presentation_groups`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          pollId,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Addable Presentations
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddablePresentationsCrosstabsPresentationGetAddablePresentationsPut:
      async (
        pollId: PollId,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'pollId' is not null or undefined
        assertParamExists(
          'getAddablePresentationsCrosstabsPresentationGetAddablePresentationsPut',
          'pollId',
          pollId,
        );
        const localVarPath = `/crosstabs_presentation/get_addable_presentations`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          pollId,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Get the Crosstabs Presentation
     * @summary Get Crosstabs Presentation
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCrosstabsPresentationCrosstabsPresentationGetCrosstabsPresentationPut:
      async (
        pollId: PollId,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'pollId' is not null or undefined
        assertParamExists(
          'getCrosstabsPresentationCrosstabsPresentationGetCrosstabsPresentationPut',
          'pollId',
          pollId,
        );
        const localVarPath = `/crosstabs_presentation/get_crosstabs_presentation`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          pollId,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Update the Crosstabs Presentation
     * @summary Reset Crosstabs Presentation
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetCrosstabsPresentationCrosstabsPresentationResetCrosstabsPresentationPut:
      async (
        pollId: PollId,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'pollId' is not null or undefined
        assertParamExists(
          'resetCrosstabsPresentationCrosstabsPresentationResetCrosstabsPresentationPut',
          'pollId',
          pollId,
        );
        const localVarPath = `/crosstabs_presentation/reset_crosstabs_presentation`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          pollId,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Run Crosstabs
     * @param {string} pollId
     * @param {string} [weightingConfig]
     * @param {boolean} [outputTopline]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runCrosstabsCrosstabsPresentationRunCrosstabsPut: async (
      pollId: string,
      weightingConfig?: string,
      outputTopline?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'runCrosstabsCrosstabsPresentationRunCrosstabsPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/crosstabs_presentation/run_crosstabs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pollId !== undefined) {
        localVarQueryParameter['poll_id'] = pollId;
      }

      if (weightingConfig !== undefined) {
        localVarQueryParameter['weighting_config'] = weightingConfig;
      }

      if (outputTopline !== undefined) {
        localVarQueryParameter['output_topline'] = outputTopline;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the Crosstabs Presentation
     * @summary Update Crosstabs Presentation
     * @param {BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut} bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut:
      async (
        bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut: BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut' is not null or undefined
        assertParamExists(
          'updateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut',
          'bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut',
          bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
        );
        const localVarPath = `/crosstabs_presentation/update_crosstabs_presentation`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * CrosstabPresentationApi - functional programming interface
 * @export
 */
export const CrosstabPresentationApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    CrosstabPresentationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Addable Presentation Groups
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAddablePresentationGroupsCrosstabsPresentationGetAddablePresentationGroupsPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DenormSeriesPresentationGroup>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAddablePresentationGroupsCrosstabsPresentationGetAddablePresentationGroupsPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Addable Presentations
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAddablePresentationsCrosstabsPresentationGetAddablePresentationsPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DenormSeriesPresentation>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAddablePresentationsCrosstabsPresentationGetAddablePresentationsPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the Crosstabs Presentation
     * @summary Get Crosstabs Presentation
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCrosstabsPresentationCrosstabsPresentationGetCrosstabsPresentationPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CrosstabPresentation>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCrosstabsPresentationCrosstabsPresentationGetCrosstabsPresentationPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update the Crosstabs Presentation
     * @summary Reset Crosstabs Presentation
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetCrosstabsPresentationCrosstabsPresentationResetCrosstabsPresentationPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CrosstabPresentationUpdateReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resetCrosstabsPresentationCrosstabsPresentationResetCrosstabsPresentationPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Run Crosstabs
     * @param {string} pollId
     * @param {string} [weightingConfig]
     * @param {boolean} [outputTopline]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runCrosstabsCrosstabsPresentationRunCrosstabsPut(
      pollId: string,
      weightingConfig?: string,
      outputTopline?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RunCrosstabsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.runCrosstabsCrosstabsPresentationRunCrosstabsPut(
          pollId,
          weightingConfig,
          outputTopline,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update the Crosstabs Presentation
     * @summary Update Crosstabs Presentation
     * @param {BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut} bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut(
      bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut: BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CrosstabPresentationUpdateReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut(
          bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CrosstabPresentationApi - factory interface
 * @export
 */
export const CrosstabPresentationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CrosstabPresentationApiFp(configuration);
  return {
    /**
     *
     * @summary Get Addable Presentation Groups
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddablePresentationGroupsCrosstabsPresentationGetAddablePresentationGroupsPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<Array<DenormSeriesPresentationGroup>> {
      return localVarFp
        .getAddablePresentationGroupsCrosstabsPresentationGetAddablePresentationGroupsPut(
          pollId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Addable Presentations
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddablePresentationsCrosstabsPresentationGetAddablePresentationsPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<Array<DenormSeriesPresentation>> {
      return localVarFp
        .getAddablePresentationsCrosstabsPresentationGetAddablePresentationsPut(
          pollId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the Crosstabs Presentation
     * @summary Get Crosstabs Presentation
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCrosstabsPresentationCrosstabsPresentationGetCrosstabsPresentationPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<CrosstabPresentation> {
      return localVarFp
        .getCrosstabsPresentationCrosstabsPresentationGetCrosstabsPresentationPut(
          pollId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the Crosstabs Presentation
     * @summary Reset Crosstabs Presentation
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetCrosstabsPresentationCrosstabsPresentationResetCrosstabsPresentationPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<CrosstabPresentationUpdateReport> {
      return localVarFp
        .resetCrosstabsPresentationCrosstabsPresentationResetCrosstabsPresentationPut(
          pollId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Run Crosstabs
     * @param {string} pollId
     * @param {string} [weightingConfig]
     * @param {boolean} [outputTopline]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runCrosstabsCrosstabsPresentationRunCrosstabsPut(
      pollId: string,
      weightingConfig?: string,
      outputTopline?: boolean,
      options?: any,
    ): AxiosPromise<RunCrosstabsResponse> {
      return localVarFp
        .runCrosstabsCrosstabsPresentationRunCrosstabsPut(
          pollId,
          weightingConfig,
          outputTopline,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the Crosstabs Presentation
     * @summary Update Crosstabs Presentation
     * @param {BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut} bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut(
      bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut: BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
      options?: any,
    ): AxiosPromise<CrosstabPresentationUpdateReport> {
      return localVarFp
        .updateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut(
          bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CrosstabPresentationApi - object-oriented interface
 * @export
 * @class CrosstabPresentationApi
 * @extends {BaseAPI}
 */
export class CrosstabPresentationApi extends BaseAPI {
  /**
   *
   * @summary Get Addable Presentation Groups
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrosstabPresentationApi
   */
  public getAddablePresentationGroupsCrosstabsPresentationGetAddablePresentationGroupsPut(
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return CrosstabPresentationApiFp(this.configuration)
      .getAddablePresentationGroupsCrosstabsPresentationGetAddablePresentationGroupsPut(
        pollId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Addable Presentations
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrosstabPresentationApi
   */
  public getAddablePresentationsCrosstabsPresentationGetAddablePresentationsPut(
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return CrosstabPresentationApiFp(this.configuration)
      .getAddablePresentationsCrosstabsPresentationGetAddablePresentationsPut(
        pollId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the Crosstabs Presentation
   * @summary Get Crosstabs Presentation
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrosstabPresentationApi
   */
  public getCrosstabsPresentationCrosstabsPresentationGetCrosstabsPresentationPut(
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return CrosstabPresentationApiFp(this.configuration)
      .getCrosstabsPresentationCrosstabsPresentationGetCrosstabsPresentationPut(
        pollId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the Crosstabs Presentation
   * @summary Reset Crosstabs Presentation
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrosstabPresentationApi
   */
  public resetCrosstabsPresentationCrosstabsPresentationResetCrosstabsPresentationPut(
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return CrosstabPresentationApiFp(this.configuration)
      .resetCrosstabsPresentationCrosstabsPresentationResetCrosstabsPresentationPut(
        pollId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Run Crosstabs
   * @param {string} pollId
   * @param {string} [weightingConfig]
   * @param {boolean} [outputTopline]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrosstabPresentationApi
   */
  public runCrosstabsCrosstabsPresentationRunCrosstabsPut(
    pollId: string,
    weightingConfig?: string,
    outputTopline?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return CrosstabPresentationApiFp(this.configuration)
      .runCrosstabsCrosstabsPresentationRunCrosstabsPut(
        pollId,
        weightingConfig,
        outputTopline,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the Crosstabs Presentation
   * @summary Update Crosstabs Presentation
   * @param {BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut} bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrosstabPresentationApi
   */
  public updateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut(
    bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut: BodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
    options?: AxiosRequestConfig,
  ) {
    return CrosstabPresentationApiFp(this.configuration)
      .updateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut(
        bodyUpdateCrosstabsPresentationCrosstabsPresentationUpdateCrosstabsPresentationPut,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CustomAudienceApi - axios parameter creator
 * @export
 */
export const CustomAudienceApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Build Default Recruitment Targets
     * @param {string} pollIdUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut:
      async (
        pollIdUuid: string,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'pollIdUuid' is not null or undefined
        assertParamExists(
          'buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut',
          'pollIdUuid',
          pollIdUuid,
        );
        const localVarPath =
          `/recruitment/{poll_id_uuid}/make_default_recruitment_targets`.replace(
            `{${'poll_id_uuid'}}`,
            encodeURIComponent(String(pollIdUuid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Counts
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountsCustomAudienceGetCountsPut: async (
      pollId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'getCountsCustomAudienceGetCountsPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/custom_audience/get_counts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pollId !== undefined) {
        localVarQueryParameter['poll_id'] = pollId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Counts
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountsRecruitmentGetCountsPollIdPut: async (
      pollId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'getCountsRecruitmentGetCountsPollIdPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/recruitment/get_counts/{poll_id}`.replace(
        `{${'poll_id'}}`,
        encodeURIComponent(String(pollId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Progress
     * @param {string} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgressRecruitmentGetProgressStepIdGet: async (
      stepId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists(
        'getProgressRecruitmentGetProgressStepIdGet',
        'stepId',
        stepId,
      );
      const localVarPath = `/recruitment/get_progress/{step_id}`.replace(
        `{${'step_id'}}`,
        encodeURIComponent(String(stepId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Recruitment Summary
     * @param {string} pollId
     * @param {TurnoutScenario} [turnoutScenario]
     * @param {boolean} [refreshSurveyRespondents]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecruitmentSummaryRecruitmentSummaryPollIdPut: async (
      pollId: string,
      turnoutScenario?: TurnoutScenario,
      refreshSurveyRespondents?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'getRecruitmentSummaryRecruitmentSummaryPollIdPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/recruitment/summary/{poll_id}`.replace(
        `{${'poll_id'}}`,
        encodeURIComponent(String(pollId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (turnoutScenario !== undefined) {
        localVarQueryParameter['turnout_scenario'] = turnoutScenario;
      }

      if (refreshSurveyRespondents !== undefined) {
        localVarQueryParameter['refresh_survey_respondents'] =
          refreshSurveyRespondents;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Target Marginals
     * @param {string} pollId
     * @param {TurnoutScenario} [turnoutScenario]
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTargetMarginalsRecruitmentTargetMarginalsPollIdPost: async (
      pollId: string,
      turnoutScenario?: TurnoutScenario,
      refresh?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'getTargetMarginalsRecruitmentTargetMarginalsPollIdPost',
        'pollId',
        pollId,
      );
      const localVarPath = `/recruitment/target_marginals/{poll_id}`.replace(
        `{${'poll_id'}}`,
        encodeURIComponent(String(pollId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (turnoutScenario !== undefined) {
        localVarQueryParameter['turnout_scenario'] = turnoutScenario;
      }

      if (refresh !== undefined) {
        localVarQueryParameter['refresh'] = refresh;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Recruitment Targets
     * @param {string} pollIdUuid
     * @param {string} weightingConfigId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut:
      async (
        pollIdUuid: string,
        weightingConfigId: string,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'pollIdUuid' is not null or undefined
        assertParamExists(
          'setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut',
          'pollIdUuid',
          pollIdUuid,
        );
        // verify required parameter 'weightingConfigId' is not null or undefined
        assertParamExists(
          'setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut',
          'weightingConfigId',
          weightingConfigId,
        );
        const localVarPath =
          `/recruitment/{poll_id_uuid}/recruitment_targets/{weighting_config_id}`
            .replace(
              `{${'poll_id_uuid'}}`,
              encodeURIComponent(String(pollIdUuid)),
            )
            .replace(
              `{${'weighting_config_id'}}`,
              encodeURIComponent(String(weightingConfigId)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Start Audience Creation
     * @param {string} pollId
     * @param {EthPicker} ethPick
     * @param {PartyPicker} partyPick
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startAudienceCreationRecruitmentStartAudienceCreationGet: async (
      pollId: string,
      ethPick: EthPicker,
      partyPick: PartyPicker,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'startAudienceCreationRecruitmentStartAudienceCreationGet',
        'pollId',
        pollId,
      );
      // verify required parameter 'ethPick' is not null or undefined
      assertParamExists(
        'startAudienceCreationRecruitmentStartAudienceCreationGet',
        'ethPick',
        ethPick,
      );
      // verify required parameter 'partyPick' is not null or undefined
      assertParamExists(
        'startAudienceCreationRecruitmentStartAudienceCreationGet',
        'partyPick',
        partyPick,
      );
      const localVarPath = `/recruitment/start_audience_creation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pollId !== undefined) {
        localVarQueryParameter['poll_id'] = pollId;
      }

      if (ethPick !== undefined) {
        localVarQueryParameter['eth_pick'] = ethPick;
      }

      if (partyPick !== undefined) {
        localVarQueryParameter['party_pick'] = partyPick;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomAudienceApi - functional programming interface
 * @export
 */
export const CustomAudienceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CustomAudienceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Build Default Recruitment Targets
     * @param {string} pollIdUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut(
      pollIdUuid: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut(
          pollIdUuid,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Counts
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCountsCustomAudienceGetCountsPut(
      pollId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CustomAudienceBqCustomAudienceServiceCountSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCountsCustomAudienceGetCountsPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Counts
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCountsRecruitmentGetCountsPollIdPut(
      pollId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdsFacebookServiceCountSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCountsRecruitmentGetCountsPollIdPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Progress
     * @param {string} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProgressRecruitmentGetProgressStepIdGet(
      stepId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Step>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProgressRecruitmentGetProgressStepIdGet(
          stepId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Recruitment Summary
     * @param {string} pollId
     * @param {TurnoutScenario} [turnoutScenario]
     * @param {boolean} [refreshSurveyRespondents]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecruitmentSummaryRecruitmentSummaryPollIdPut(
      pollId: string,
      turnoutScenario?: TurnoutScenario,
      refreshSurveyRespondents?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RecruitmentSummaryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecruitmentSummaryRecruitmentSummaryPollIdPut(
          pollId,
          turnoutScenario,
          refreshSurveyRespondents,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Target Marginals
     * @param {string} pollId
     * @param {TurnoutScenario} [turnoutScenario]
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTargetMarginalsRecruitmentTargetMarginalsPollIdPost(
      pollId: string,
      turnoutScenario?: TurnoutScenario,
      refresh?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: number }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTargetMarginalsRecruitmentTargetMarginalsPollIdPost(
          pollId,
          turnoutScenario,
          refresh,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Set Recruitment Targets
     * @param {string} pollIdUuid
     * @param {string} weightingConfigId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut(
      pollIdUuid: string,
      weightingConfigId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut(
          pollIdUuid,
          weightingConfigId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Start Audience Creation
     * @param {string} pollId
     * @param {EthPicker} ethPick
     * @param {PartyPicker} partyPick
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startAudienceCreationRecruitmentStartAudienceCreationGet(
      pollId: string,
      ethPick: EthPicker,
      partyPick: PartyPicker,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AudienceSplitDefinition>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startAudienceCreationRecruitmentStartAudienceCreationGet(
          pollId,
          ethPick,
          partyPick,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CustomAudienceApi - factory interface
 * @export
 */
export const CustomAudienceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomAudienceApiFp(configuration);
  return {
    /**
     *
     * @summary Build Default Recruitment Targets
     * @param {string} pollIdUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut(
      pollIdUuid: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut(
          pollIdUuid,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Counts
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountsCustomAudienceGetCountsPut(
      pollId: string,
      options?: any,
    ): AxiosPromise<CustomAudienceBqCustomAudienceServiceCountSummary> {
      return localVarFp
        .getCountsCustomAudienceGetCountsPut(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Counts
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountsRecruitmentGetCountsPollIdPut(
      pollId: string,
      options?: any,
    ): AxiosPromise<AdsFacebookServiceCountSummary> {
      return localVarFp
        .getCountsRecruitmentGetCountsPollIdPut(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Progress
     * @param {string} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgressRecruitmentGetProgressStepIdGet(
      stepId: string,
      options?: any,
    ): AxiosPromise<Array<Step>> {
      return localVarFp
        .getProgressRecruitmentGetProgressStepIdGet(stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Recruitment Summary
     * @param {string} pollId
     * @param {TurnoutScenario} [turnoutScenario]
     * @param {boolean} [refreshSurveyRespondents]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecruitmentSummaryRecruitmentSummaryPollIdPut(
      pollId: string,
      turnoutScenario?: TurnoutScenario,
      refreshSurveyRespondents?: boolean,
      options?: any,
    ): AxiosPromise<RecruitmentSummaryResponse> {
      return localVarFp
        .getRecruitmentSummaryRecruitmentSummaryPollIdPut(
          pollId,
          turnoutScenario,
          refreshSurveyRespondents,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Target Marginals
     * @param {string} pollId
     * @param {TurnoutScenario} [turnoutScenario]
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTargetMarginalsRecruitmentTargetMarginalsPollIdPost(
      pollId: string,
      turnoutScenario?: TurnoutScenario,
      refresh?: boolean,
      options?: any,
    ): AxiosPromise<{ [key: string]: number }> {
      return localVarFp
        .getTargetMarginalsRecruitmentTargetMarginalsPollIdPost(
          pollId,
          turnoutScenario,
          refresh,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Set Recruitment Targets
     * @param {string} pollIdUuid
     * @param {string} weightingConfigId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut(
      pollIdUuid: string,
      weightingConfigId: string,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut(
          pollIdUuid,
          weightingConfigId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Start Audience Creation
     * @param {string} pollId
     * @param {EthPicker} ethPick
     * @param {PartyPicker} partyPick
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startAudienceCreationRecruitmentStartAudienceCreationGet(
      pollId: string,
      ethPick: EthPicker,
      partyPick: PartyPicker,
      options?: any,
    ): AxiosPromise<Array<AudienceSplitDefinition>> {
      return localVarFp
        .startAudienceCreationRecruitmentStartAudienceCreationGet(
          pollId,
          ethPick,
          partyPick,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CustomAudienceApi - object-oriented interface
 * @export
 * @class CustomAudienceApi
 * @extends {BaseAPI}
 */
export class CustomAudienceApi extends BaseAPI {
  /**
   *
   * @summary Build Default Recruitment Targets
   * @param {string} pollIdUuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomAudienceApi
   */
  public buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut(
    pollIdUuid: string,
    options?: AxiosRequestConfig,
  ) {
    return CustomAudienceApiFp(this.configuration)
      .buildDefaultRecruitmentTargetsRecruitmentPollIdUuidMakeDefaultRecruitmentTargetsPut(
        pollIdUuid,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Counts
   * @param {string} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomAudienceApi
   */
  public getCountsCustomAudienceGetCountsPut(
    pollId: string,
    options?: AxiosRequestConfig,
  ) {
    return CustomAudienceApiFp(this.configuration)
      .getCountsCustomAudienceGetCountsPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Counts
   * @param {string} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomAudienceApi
   */
  public getCountsRecruitmentGetCountsPollIdPut(
    pollId: string,
    options?: AxiosRequestConfig,
  ) {
    return CustomAudienceApiFp(this.configuration)
      .getCountsRecruitmentGetCountsPollIdPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Progress
   * @param {string} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomAudienceApi
   */
  public getProgressRecruitmentGetProgressStepIdGet(
    stepId: string,
    options?: AxiosRequestConfig,
  ) {
    return CustomAudienceApiFp(this.configuration)
      .getProgressRecruitmentGetProgressStepIdGet(stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Recruitment Summary
   * @param {string} pollId
   * @param {TurnoutScenario} [turnoutScenario]
   * @param {boolean} [refreshSurveyRespondents]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomAudienceApi
   */
  public getRecruitmentSummaryRecruitmentSummaryPollIdPut(
    pollId: string,
    turnoutScenario?: TurnoutScenario,
    refreshSurveyRespondents?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return CustomAudienceApiFp(this.configuration)
      .getRecruitmentSummaryRecruitmentSummaryPollIdPut(
        pollId,
        turnoutScenario,
        refreshSurveyRespondents,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Target Marginals
   * @param {string} pollId
   * @param {TurnoutScenario} [turnoutScenario]
   * @param {boolean} [refresh]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomAudienceApi
   */
  public getTargetMarginalsRecruitmentTargetMarginalsPollIdPost(
    pollId: string,
    turnoutScenario?: TurnoutScenario,
    refresh?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return CustomAudienceApiFp(this.configuration)
      .getTargetMarginalsRecruitmentTargetMarginalsPollIdPost(
        pollId,
        turnoutScenario,
        refresh,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Recruitment Targets
   * @param {string} pollIdUuid
   * @param {string} weightingConfigId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomAudienceApi
   */
  public setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut(
    pollIdUuid: string,
    weightingConfigId: string,
    options?: AxiosRequestConfig,
  ) {
    return CustomAudienceApiFp(this.configuration)
      .setRecruitmentTargetsRecruitmentPollIdUuidRecruitmentTargetsWeightingConfigIdPut(
        pollIdUuid,
        weightingConfigId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start Audience Creation
   * @param {string} pollId
   * @param {EthPicker} ethPick
   * @param {PartyPicker} partyPick
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomAudienceApi
   */
  public startAudienceCreationRecruitmentStartAudienceCreationGet(
    pollId: string,
    ethPick: EthPicker,
    partyPick: PartyPicker,
    options?: AxiosRequestConfig,
  ) {
    return CustomAudienceApiFp(this.configuration)
      .startAudienceCreationRecruitmentStartAudienceCreationGet(
        pollId,
        ethPick,
        partyPick,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Handle Http Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleHttpGetGraphqlGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/graphql`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Handle Http Post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleHttpPostGraphqlPost: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/graphql`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Handle Http Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleHttpGetGraphqlGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handleHttpGetGraphqlGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Handle Http Post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleHttpPostGraphqlPost(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handleHttpPostGraphqlPost(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Handle Http Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleHttpGetGraphqlGet(options?: any): AxiosPromise<any> {
      return localVarFp
        .handleHttpGetGraphqlGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Handle Http Post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleHttpPostGraphqlPost(options?: any): AxiosPromise<any> {
      return localVarFp
        .handleHttpPostGraphqlPost(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Handle Http Get
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handleHttpGetGraphqlGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handleHttpGetGraphqlGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Handle Http Post
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handleHttpPostGraphqlPost(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handleHttpPostGraphqlPost(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DenormalizedTableApi - axios parameter creator
 * @export
 */
export const DenormalizedTableApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Auto Detect Horseraces
     * @param {PollId} pollId
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoDetectHorseracesAutoDetectHorseracesPut: async (
      pollId: PollId,
      refresh?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'autoDetectHorseracesAutoDetectHorseracesPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/auto_detect_horseraces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (refresh !== undefined) {
        localVarQueryParameter['refresh'] = refresh;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Batch Update Segments
     * @param {BodyBatchUpdateSegmentsBatchUpdateSegmentsPut} bodyBatchUpdateSegmentsBatchUpdateSegmentsPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchUpdateSegmentsBatchUpdateSegmentsPut: async (
      bodyBatchUpdateSegmentsBatchUpdateSegmentsPut: BodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyBatchUpdateSegmentsBatchUpdateSegmentsPut' is not null or undefined
      assertParamExists(
        'batchUpdateSegmentsBatchUpdateSegmentsPut',
        'bodyBatchUpdateSegmentsBatchUpdateSegmentsPut',
        bodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
      );
      const localVarPath = `/batch_update_segments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Segment
     * @param {BodyCreateSegmentCreateSegmentPut} bodyCreateSegmentCreateSegmentPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSegmentCreateSegmentPut: async (
      bodyCreateSegmentCreateSegmentPut: BodyCreateSegmentCreateSegmentPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyCreateSegmentCreateSegmentPut' is not null or undefined
      assertParamExists(
        'createSegmentCreateSegmentPut',
        'bodyCreateSegmentCreateSegmentPut',
        bodyCreateSegmentCreateSegmentPut,
      );
      const localVarPath = `/create_segment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyCreateSegmentCreateSegmentPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Segment
     * @param {string} segmentName
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSegmentDeleteSegmentDelete: async (
      segmentName: string,
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'segmentName' is not null or undefined
      assertParamExists(
        'deleteSegmentDeleteSegmentDelete',
        'segmentName',
        segmentName,
      );
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('deleteSegmentDeleteSegmentDelete', 'pollId', pollId);
      const localVarPath = `/delete_segment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (segmentName !== undefined) {
        localVarQueryParameter['segment_name'] = segmentName;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Denorm Series Names
     * @param {PollId} pollId
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDenormSeriesNamesGetAllDenormSeriesNamesPut: async (
      pollId: PollId,
      refresh?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'getAllDenormSeriesNamesGetAllDenormSeriesNamesPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/get_all_denorm_series_names`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (refresh !== undefined) {
        localVarQueryParameter['refresh'] = refresh;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Horserace Series Names
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllHorseraceSeriesNamesGetAllHorseraceSeriesNamesPut: async (
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'getAllHorseraceSeriesNamesGetAllHorseraceSeriesNamesPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/get_all_horserace_series_names`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Segment Schemas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSegmentSchemasGetSegmentSchemasGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/get_segment_schemas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Refresh Survey Schema
     * @param {PollId} pollId
     * @param {boolean} [refreshResponses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshSurveySchemaRefreshSurveySchemaPost: async (
      pollId: PollId,
      refreshResponses?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'refreshSurveySchemaRefreshSurveySchemaPost',
        'pollId',
        pollId,
      );
      const localVarPath = `/refresh_survey_schema`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (refreshResponses !== undefined) {
        localVarQueryParameter['refresh_responses'] = refreshResponses;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Segment
     * @param {BodyUpdateSegmentUpdateSegmentPut} bodyUpdateSegmentUpdateSegmentPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSegmentUpdateSegmentPut: async (
      bodyUpdateSegmentUpdateSegmentPut: BodyUpdateSegmentUpdateSegmentPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyUpdateSegmentUpdateSegmentPut' is not null or undefined
      assertParamExists(
        'updateSegmentUpdateSegmentPut',
        'bodyUpdateSegmentUpdateSegmentPut',
        bodyUpdateSegmentUpdateSegmentPut,
      );
      const localVarPath = `/update_segment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyUpdateSegmentUpdateSegmentPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DenormalizedTableApi - functional programming interface
 * @export
 */
export const DenormalizedTableApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DenormalizedTableApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Auto Detect Horseraces
     * @param {PollId} pollId
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async autoDetectHorseracesAutoDetectHorseracesPut(
      pollId: PollId,
      refresh?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HorseraceSeriesSchemaResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.autoDetectHorseracesAutoDetectHorseracesPut(
          pollId,
          refresh,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Batch Update Segments
     * @param {BodyBatchUpdateSegmentsBatchUpdateSegmentsPut} bodyBatchUpdateSegmentsBatchUpdateSegmentsPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchUpdateSegmentsBatchUpdateSegmentsPut(
      bodyBatchUpdateSegmentsBatchUpdateSegmentsPut: BodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DenormalizedTableModifcationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.batchUpdateSegmentsBatchUpdateSegmentsPut(
          bodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create Segment
     * @param {BodyCreateSegmentCreateSegmentPut} bodyCreateSegmentCreateSegmentPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSegmentCreateSegmentPut(
      bodyCreateSegmentCreateSegmentPut: BodyCreateSegmentCreateSegmentPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DenormalizedTableModifcationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSegmentCreateSegmentPut(
          bodyCreateSegmentCreateSegmentPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete Segment
     * @param {string} segmentName
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSegmentDeleteSegmentDelete(
      segmentName: string,
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DenormalizedTableModifcationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSegmentDeleteSegmentDelete(
          segmentName,
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get All Denorm Series Names
     * @param {PollId} pollId
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDenormSeriesNamesGetAllDenormSeriesNamesPut(
      pollId: PollId,
      refresh?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DenormSeriesSchemaResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllDenormSeriesNamesGetAllDenormSeriesNamesPut(
          pollId,
          refresh,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get All Horserace Series Names
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllHorseraceSeriesNamesGetAllHorseraceSeriesNamesPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HorseraceSeriesSchemaResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllHorseraceSeriesNamesGetAllHorseraceSeriesNamesPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Segment Schemas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSegmentSchemasGetSegmentSchemasGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SegmentSchemaListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSegmentSchemasGetSegmentSchemasGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Refresh Survey Schema
     * @param {PollId} pollId
     * @param {boolean} [refreshResponses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshSurveySchemaRefreshSurveySchemaPost(
      pollId: PollId,
      refreshResponses?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshSurveySchemaRefreshSurveySchemaPost(
          pollId,
          refreshResponses,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Segment
     * @param {BodyUpdateSegmentUpdateSegmentPut} bodyUpdateSegmentUpdateSegmentPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSegmentUpdateSegmentPut(
      bodyUpdateSegmentUpdateSegmentPut: BodyUpdateSegmentUpdateSegmentPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DenormalizedTableModifcationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSegmentUpdateSegmentPut(
          bodyUpdateSegmentUpdateSegmentPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DenormalizedTableApi - factory interface
 * @export
 */
export const DenormalizedTableApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DenormalizedTableApiFp(configuration);
  return {
    /**
     *
     * @summary Auto Detect Horseraces
     * @param {PollId} pollId
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoDetectHorseracesAutoDetectHorseracesPut(
      pollId: PollId,
      refresh?: boolean,
      options?: any,
    ): AxiosPromise<HorseraceSeriesSchemaResponse> {
      return localVarFp
        .autoDetectHorseracesAutoDetectHorseracesPut(pollId, refresh, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Batch Update Segments
     * @param {BodyBatchUpdateSegmentsBatchUpdateSegmentsPut} bodyBatchUpdateSegmentsBatchUpdateSegmentsPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchUpdateSegmentsBatchUpdateSegmentsPut(
      bodyBatchUpdateSegmentsBatchUpdateSegmentsPut: BodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
      options?: any,
    ): AxiosPromise<DenormalizedTableModifcationResponse> {
      return localVarFp
        .batchUpdateSegmentsBatchUpdateSegmentsPut(
          bodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Segment
     * @param {BodyCreateSegmentCreateSegmentPut} bodyCreateSegmentCreateSegmentPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSegmentCreateSegmentPut(
      bodyCreateSegmentCreateSegmentPut: BodyCreateSegmentCreateSegmentPut,
      options?: any,
    ): AxiosPromise<DenormalizedTableModifcationResponse> {
      return localVarFp
        .createSegmentCreateSegmentPut(
          bodyCreateSegmentCreateSegmentPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Segment
     * @param {string} segmentName
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSegmentDeleteSegmentDelete(
      segmentName: string,
      pollId: PollId,
      options?: any,
    ): AxiosPromise<DenormalizedTableModifcationResponse> {
      return localVarFp
        .deleteSegmentDeleteSegmentDelete(segmentName, pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get All Denorm Series Names
     * @param {PollId} pollId
     * @param {boolean} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDenormSeriesNamesGetAllDenormSeriesNamesPut(
      pollId: PollId,
      refresh?: boolean,
      options?: any,
    ): AxiosPromise<DenormSeriesSchemaResponse> {
      return localVarFp
        .getAllDenormSeriesNamesGetAllDenormSeriesNamesPut(
          pollId,
          refresh,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get All Horserace Series Names
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllHorseraceSeriesNamesGetAllHorseraceSeriesNamesPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<HorseraceSeriesSchemaResponse> {
      return localVarFp
        .getAllHorseraceSeriesNamesGetAllHorseraceSeriesNamesPut(
          pollId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Segment Schemas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSegmentSchemasGetSegmentSchemasGet(
      options?: any,
    ): AxiosPromise<SegmentSchemaListResponse> {
      return localVarFp
        .getSegmentSchemasGetSegmentSchemasGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Refresh Survey Schema
     * @param {PollId} pollId
     * @param {boolean} [refreshResponses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshSurveySchemaRefreshSurveySchemaPost(
      pollId: PollId,
      refreshResponses?: boolean,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .refreshSurveySchemaRefreshSurveySchemaPost(
          pollId,
          refreshResponses,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Segment
     * @param {BodyUpdateSegmentUpdateSegmentPut} bodyUpdateSegmentUpdateSegmentPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSegmentUpdateSegmentPut(
      bodyUpdateSegmentUpdateSegmentPut: BodyUpdateSegmentUpdateSegmentPut,
      options?: any,
    ): AxiosPromise<DenormalizedTableModifcationResponse> {
      return localVarFp
        .updateSegmentUpdateSegmentPut(
          bodyUpdateSegmentUpdateSegmentPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DenormalizedTableApi - object-oriented interface
 * @export
 * @class DenormalizedTableApi
 * @extends {BaseAPI}
 */
export class DenormalizedTableApi extends BaseAPI {
  /**
   *
   * @summary Auto Detect Horseraces
   * @param {PollId} pollId
   * @param {boolean} [refresh]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public autoDetectHorseracesAutoDetectHorseracesPut(
    pollId: PollId,
    refresh?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return DenormalizedTableApiFp(this.configuration)
      .autoDetectHorseracesAutoDetectHorseracesPut(pollId, refresh, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Batch Update Segments
   * @param {BodyBatchUpdateSegmentsBatchUpdateSegmentsPut} bodyBatchUpdateSegmentsBatchUpdateSegmentsPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public batchUpdateSegmentsBatchUpdateSegmentsPut(
    bodyBatchUpdateSegmentsBatchUpdateSegmentsPut: BodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
    options?: AxiosRequestConfig,
  ) {
    return DenormalizedTableApiFp(this.configuration)
      .batchUpdateSegmentsBatchUpdateSegmentsPut(
        bodyBatchUpdateSegmentsBatchUpdateSegmentsPut,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Segment
   * @param {BodyCreateSegmentCreateSegmentPut} bodyCreateSegmentCreateSegmentPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public createSegmentCreateSegmentPut(
    bodyCreateSegmentCreateSegmentPut: BodyCreateSegmentCreateSegmentPut,
    options?: AxiosRequestConfig,
  ) {
    return DenormalizedTableApiFp(this.configuration)
      .createSegmentCreateSegmentPut(bodyCreateSegmentCreateSegmentPut, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Segment
   * @param {string} segmentName
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public deleteSegmentDeleteSegmentDelete(
    segmentName: string,
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return DenormalizedTableApiFp(this.configuration)
      .deleteSegmentDeleteSegmentDelete(segmentName, pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get All Denorm Series Names
   * @param {PollId} pollId
   * @param {boolean} [refresh]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public getAllDenormSeriesNamesGetAllDenormSeriesNamesPut(
    pollId: PollId,
    refresh?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return DenormalizedTableApiFp(this.configuration)
      .getAllDenormSeriesNamesGetAllDenormSeriesNamesPut(
        pollId,
        refresh,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get All Horserace Series Names
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public getAllHorseraceSeriesNamesGetAllHorseraceSeriesNamesPut(
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return DenormalizedTableApiFp(this.configuration)
      .getAllHorseraceSeriesNamesGetAllHorseraceSeriesNamesPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Segment Schemas
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public getSegmentSchemasGetSegmentSchemasGet(options?: AxiosRequestConfig) {
    return DenormalizedTableApiFp(this.configuration)
      .getSegmentSchemasGetSegmentSchemasGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Refresh Survey Schema
   * @param {PollId} pollId
   * @param {boolean} [refreshResponses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public refreshSurveySchemaRefreshSurveySchemaPost(
    pollId: PollId,
    refreshResponses?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return DenormalizedTableApiFp(this.configuration)
      .refreshSurveySchemaRefreshSurveySchemaPost(
        pollId,
        refreshResponses,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Segment
   * @param {BodyUpdateSegmentUpdateSegmentPut} bodyUpdateSegmentUpdateSegmentPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DenormalizedTableApi
   */
  public updateSegmentUpdateSegmentPut(
    bodyUpdateSegmentUpdateSegmentPut: BodyUpdateSegmentUpdateSegmentPut,
    options?: AxiosRequestConfig,
  ) {
    return DenormalizedTableApiFp(this.configuration)
      .updateSegmentUpdateSegmentPut(bodyUpdateSegmentUpdateSegmentPut, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LegacyAdLaunchApi - axios parameter creator
 * @export
 */
export const LegacyAdLaunchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Ads Launch Log
     * @param {string} jobId
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adsLaunchLogAdsLaunchLogPut: async (
      jobId: string,
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('adsLaunchLogAdsLaunchLogPut', 'jobId', jobId);
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('adsLaunchLogAdsLaunchLogPut', 'pollId', pollId);
      const localVarPath = `/ads_launch_log`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobId !== undefined) {
        localVarQueryParameter['job_id'] = jobId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ads Launch Overview
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adsLaunchOverviewAdsLaunchOverviewPut: async (
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'adsLaunchOverviewAdsLaunchOverviewPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/ads_launch_overview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cancel pending ads creation request.
     * @summary Cancel Ads
     * @param {AdCancelRequest} adCancelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelAdsCancelAdsPut: async (
      adCancelRequest: AdCancelRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adCancelRequest' is not null or undefined
      assertParamExists(
        'cancelAdsCancelAdsPut',
        'adCancelRequest',
        adCancelRequest,
      );
      const localVarPath = `/cancel_ads`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adCancelRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Launch Facebook Ads based on the configuration contained in a google spreadsheet
     * @summary Launch Ads
     * @param {AdSheetLaunchRequest} adSheetLaunchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    launchAdsLaunchAdsSheetPut: async (
      adSheetLaunchRequest: AdSheetLaunchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adSheetLaunchRequest' is not null or undefined
      assertParamExists(
        'launchAdsLaunchAdsSheetPut',
        'adSheetLaunchRequest',
        adSheetLaunchRequest,
      );
      const localVarPath = `/launch_ads_sheet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adSheetLaunchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LegacyAdLaunchApi - functional programming interface
 * @export
 */
export const LegacyAdLaunchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LegacyAdLaunchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Ads Launch Log
     * @param {string} jobId
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adsLaunchLogAdsLaunchLogPut(
      jobId: string,
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adsLaunchLogAdsLaunchLogPut(
          jobId,
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Ads Launch Overview
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adsLaunchOverviewAdsLaunchOverviewPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AdSheetLaunchJob>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adsLaunchOverviewAdsLaunchOverviewPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cancel pending ads creation request.
     * @summary Cancel Ads
     * @param {AdCancelRequest} adCancelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelAdsCancelAdsPut(
      adCancelRequest: AdCancelRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelAdsCancelAdsPut(
          adCancelRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Launch Facebook Ads based on the configuration contained in a google spreadsheet
     * @summary Launch Ads
     * @param {AdSheetLaunchRequest} adSheetLaunchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async launchAdsLaunchAdsSheetPut(
      adSheetLaunchRequest: AdSheetLaunchRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.launchAdsLaunchAdsSheetPut(
          adSheetLaunchRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * LegacyAdLaunchApi - factory interface
 * @export
 */
export const LegacyAdLaunchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LegacyAdLaunchApiFp(configuration);
  return {
    /**
     *
     * @summary Ads Launch Log
     * @param {string} jobId
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adsLaunchLogAdsLaunchLogPut(
      jobId: string,
      pollId: PollId,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .adsLaunchLogAdsLaunchLogPut(jobId, pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ads Launch Overview
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adsLaunchOverviewAdsLaunchOverviewPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<Array<AdSheetLaunchJob>> {
      return localVarFp
        .adsLaunchOverviewAdsLaunchOverviewPut(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cancel pending ads creation request.
     * @summary Cancel Ads
     * @param {AdCancelRequest} adCancelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelAdsCancelAdsPut(
      adCancelRequest: AdCancelRequest,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .cancelAdsCancelAdsPut(adCancelRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Launch Facebook Ads based on the configuration contained in a google spreadsheet
     * @summary Launch Ads
     * @param {AdSheetLaunchRequest} adSheetLaunchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    launchAdsLaunchAdsSheetPut(
      adSheetLaunchRequest: AdSheetLaunchRequest,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .launchAdsLaunchAdsSheetPut(adSheetLaunchRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LegacyAdLaunchApi - object-oriented interface
 * @export
 * @class LegacyAdLaunchApi
 * @extends {BaseAPI}
 */
export class LegacyAdLaunchApi extends BaseAPI {
  /**
   *
   * @summary Ads Launch Log
   * @param {string} jobId
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyAdLaunchApi
   */
  public adsLaunchLogAdsLaunchLogPut(
    jobId: string,
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return LegacyAdLaunchApiFp(this.configuration)
      .adsLaunchLogAdsLaunchLogPut(jobId, pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ads Launch Overview
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyAdLaunchApi
   */
  public adsLaunchOverviewAdsLaunchOverviewPut(
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return LegacyAdLaunchApiFp(this.configuration)
      .adsLaunchOverviewAdsLaunchOverviewPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cancel pending ads creation request.
   * @summary Cancel Ads
   * @param {AdCancelRequest} adCancelRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyAdLaunchApi
   */
  public cancelAdsCancelAdsPut(
    adCancelRequest: AdCancelRequest,
    options?: AxiosRequestConfig,
  ) {
    return LegacyAdLaunchApiFp(this.configuration)
      .cancelAdsCancelAdsPut(adCancelRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Launch Facebook Ads based on the configuration contained in a google spreadsheet
   * @summary Launch Ads
   * @param {AdSheetLaunchRequest} adSheetLaunchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyAdLaunchApi
   */
  public launchAdsLaunchAdsSheetPut(
    adSheetLaunchRequest: AdSheetLaunchRequest,
    options?: AxiosRequestConfig,
  ) {
    return LegacyAdLaunchApiFp(this.configuration)
      .launchAdsLaunchAdsSheetPut(adSheetLaunchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LegacyCrosstabsApi - axios parameter creator
 * @export
 */
export const LegacyCrosstabsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Add google sheet url containing generated crosstabs.  - **poll_id**: server generated poll_id of the poll - **config_id**: config used to produce crosstabs - **url**: Google docs url
     * @summary Add Google Sheet Crosstabs Url
     * @param {string} url
     * @param {BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut} bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut: async (
      url: string,
      bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut: BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'url' is not null or undefined
      assertParamExists(
        'addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut',
        'url',
        url,
      );
      // verify required parameter 'bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut' is not null or undefined
      assertParamExists(
        'addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut',
        'bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut',
        bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
      );
      const localVarPath = `/add_google_sheet_crosstabs_url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (url !== undefined) {
        localVarQueryParameter['url'] = url;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates named config in the poll store, adding a new config if the named config does not yet exist.  - **poll_id**: PollId of the poll - **config_name**: name of the config - **config**: string value of the config json file - **do_weights**: string value of the do_weights sql file - **original_zip_base64**: base64 encoded bytes of the original zip   file. This zip file will be unzipped and the config, do_weights files   updated with the provided values before being written to the poll   store.  Returns a ConfigResponse with updated values.
     * @summary Commit Config
     * @param {string} configName
     * @param {BodyCommitConfigCommitConfigPut} bodyCommitConfigCommitConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitConfigCommitConfigPut: async (
      configName: string,
      bodyCommitConfigCommitConfigPut: BodyCommitConfigCommitConfigPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'configName' is not null or undefined
      assertParamExists(
        'commitConfigCommitConfigPut',
        'configName',
        configName,
      );
      // verify required parameter 'bodyCommitConfigCommitConfigPut' is not null or undefined
      assertParamExists(
        'commitConfigCommitConfigPut',
        'bodyCommitConfigCommitConfigPut',
        bodyCommitConfigCommitConfigPut,
      );
      const localVarPath = `/commit_config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (configName !== undefined) {
        localVarQueryParameter['config_name'] = configName;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyCommitConfigCommitConfigPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Config
     * @param {BodyGetConfigGetConfigPut} bodyGetConfigGetConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigGetConfigPut: async (
      bodyGetConfigGetConfigPut: BodyGetConfigGetConfigPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyGetConfigGetConfigPut' is not null or undefined
      assertParamExists(
        'getConfigGetConfigPut',
        'bodyGetConfigGetConfigPut',
        bodyGetConfigGetConfigPut,
      );
      const localVarPath = `/get_config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyGetConfigGetConfigPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Configs
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigsGetConfigsPut: async (
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('getConfigsGetConfigsPut', 'pollId', pollId);
      const localVarPath = `/get_configs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Default Config
     * @param {string} googleUsername
     * @param {BodyGetDefaultConfigGetDefaultConfigPut} bodyGetDefaultConfigGetDefaultConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultConfigGetDefaultConfigPut: async (
      googleUsername: string,
      bodyGetDefaultConfigGetDefaultConfigPut: BodyGetDefaultConfigGetDefaultConfigPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'googleUsername' is not null or undefined
      assertParamExists(
        'getDefaultConfigGetDefaultConfigPut',
        'googleUsername',
        googleUsername,
      );
      // verify required parameter 'bodyGetDefaultConfigGetDefaultConfigPut' is not null or undefined
      assertParamExists(
        'getDefaultConfigGetDefaultConfigPut',
        'bodyGetDefaultConfigGetDefaultConfigPut',
        bodyGetDefaultConfigGetDefaultConfigPut,
      );
      const localVarPath = `/get_default_config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (googleUsername !== undefined) {
        localVarQueryParameter['google_username'] = googleUsername;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyGetDefaultConfigGetDefaultConfigPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Run Config
     * @param {BodyRunConfigRunConfigPut} bodyRunConfigRunConfigPut
     * @param {boolean} [skipSurveyMonkeyImport]
     * @param {boolean} [skipValidation]
     * @param {boolean} [skipDemoBinCheck]
     * @param {boolean} [skipCollectorAlerts]
     * @param {boolean} [skipNlp]
     * @param {boolean} [skipTopLinesDoc]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runConfigRunConfigPut: async (
      bodyRunConfigRunConfigPut: BodyRunConfigRunConfigPut,
      skipSurveyMonkeyImport?: boolean,
      skipValidation?: boolean,
      skipDemoBinCheck?: boolean,
      skipCollectorAlerts?: boolean,
      skipNlp?: boolean,
      skipTopLinesDoc?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyRunConfigRunConfigPut' is not null or undefined
      assertParamExists(
        'runConfigRunConfigPut',
        'bodyRunConfigRunConfigPut',
        bodyRunConfigRunConfigPut,
      );
      const localVarPath = `/run_config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (skipSurveyMonkeyImport !== undefined) {
        localVarQueryParameter['skip_survey_monkey_import'] =
          skipSurveyMonkeyImport;
      }

      if (skipValidation !== undefined) {
        localVarQueryParameter['skip_validation'] = skipValidation;
      }

      if (skipDemoBinCheck !== undefined) {
        localVarQueryParameter['skip_demo_bin_check'] = skipDemoBinCheck;
      }

      if (skipCollectorAlerts !== undefined) {
        localVarQueryParameter['skip_collector_alerts'] = skipCollectorAlerts;
      }

      if (skipNlp !== undefined) {
        localVarQueryParameter['skip_nlp'] = skipNlp;
      }

      if (skipTopLinesDoc !== undefined) {
        localVarQueryParameter['skip_top_lines_doc'] = skipTopLinesDoc;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyRunConfigRunConfigPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LegacyCrosstabsApi - functional programming interface
 * @export
 */
export const LegacyCrosstabsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LegacyCrosstabsApiAxiosParamCreator(configuration);
  return {
    /**
     * Add google sheet url containing generated crosstabs.  - **poll_id**: server generated poll_id of the poll - **config_id**: config used to produce crosstabs - **url**: Google docs url
     * @summary Add Google Sheet Crosstabs Url
     * @param {string} url
     * @param {BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut} bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut(
      url: string,
      bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut: BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AddGoogleSheetCrosstabsUrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut(
          url,
          bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Updates named config in the poll store, adding a new config if the named config does not yet exist.  - **poll_id**: PollId of the poll - **config_name**: name of the config - **config**: string value of the config json file - **do_weights**: string value of the do_weights sql file - **original_zip_base64**: base64 encoded bytes of the original zip   file. This zip file will be unzipped and the config, do_weights files   updated with the provided values before being written to the poll   store.  Returns a ConfigResponse with updated values.
     * @summary Commit Config
     * @param {string} configName
     * @param {BodyCommitConfigCommitConfigPut} bodyCommitConfigCommitConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commitConfigCommitConfigPut(
      configName: string,
      bodyCommitConfigCommitConfigPut: BodyCommitConfigCommitConfigPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commitConfigCommitConfigPut(
          configName,
          bodyCommitConfigCommitConfigPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Config
     * @param {BodyGetConfigGetConfigPut} bodyGetConfigGetConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConfigGetConfigPut(
      bodyGetConfigGetConfigPut: BodyGetConfigGetConfigPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getConfigGetConfigPut(
          bodyGetConfigGetConfigPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Configs
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConfigsGetConfigsPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetConfigsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getConfigsGetConfigsPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Default Config
     * @param {string} googleUsername
     * @param {BodyGetDefaultConfigGetDefaultConfigPut} bodyGetDefaultConfigGetDefaultConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDefaultConfigGetDefaultConfigPut(
      googleUsername: string,
      bodyGetDefaultConfigGetDefaultConfigPut: BodyGetDefaultConfigGetDefaultConfigPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDefaultConfigGetDefaultConfigPut(
          googleUsername,
          bodyGetDefaultConfigGetDefaultConfigPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Run Config
     * @param {BodyRunConfigRunConfigPut} bodyRunConfigRunConfigPut
     * @param {boolean} [skipSurveyMonkeyImport]
     * @param {boolean} [skipValidation]
     * @param {boolean} [skipDemoBinCheck]
     * @param {boolean} [skipCollectorAlerts]
     * @param {boolean} [skipNlp]
     * @param {boolean} [skipTopLinesDoc]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runConfigRunConfigPut(
      bodyRunConfigRunConfigPut: BodyRunConfigRunConfigPut,
      skipSurveyMonkeyImport?: boolean,
      skipValidation?: boolean,
      skipDemoBinCheck?: boolean,
      skipCollectorAlerts?: boolean,
      skipNlp?: boolean,
      skipTopLinesDoc?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.runConfigRunConfigPut(
          bodyRunConfigRunConfigPut,
          skipSurveyMonkeyImport,
          skipValidation,
          skipDemoBinCheck,
          skipCollectorAlerts,
          skipNlp,
          skipTopLinesDoc,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * LegacyCrosstabsApi - factory interface
 * @export
 */
export const LegacyCrosstabsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LegacyCrosstabsApiFp(configuration);
  return {
    /**
     * Add google sheet url containing generated crosstabs.  - **poll_id**: server generated poll_id of the poll - **config_id**: config used to produce crosstabs - **url**: Google docs url
     * @summary Add Google Sheet Crosstabs Url
     * @param {string} url
     * @param {BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut} bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut(
      url: string,
      bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut: BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
      options?: any,
    ): AxiosPromise<AddGoogleSheetCrosstabsUrlResponse> {
      return localVarFp
        .addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut(
          url,
          bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates named config in the poll store, adding a new config if the named config does not yet exist.  - **poll_id**: PollId of the poll - **config_name**: name of the config - **config**: string value of the config json file - **do_weights**: string value of the do_weights sql file - **original_zip_base64**: base64 encoded bytes of the original zip   file. This zip file will be unzipped and the config, do_weights files   updated with the provided values before being written to the poll   store.  Returns a ConfigResponse with updated values.
     * @summary Commit Config
     * @param {string} configName
     * @param {BodyCommitConfigCommitConfigPut} bodyCommitConfigCommitConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitConfigCommitConfigPut(
      configName: string,
      bodyCommitConfigCommitConfigPut: BodyCommitConfigCommitConfigPut,
      options?: any,
    ): AxiosPromise<ConfigResponse> {
      return localVarFp
        .commitConfigCommitConfigPut(
          configName,
          bodyCommitConfigCommitConfigPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Config
     * @param {BodyGetConfigGetConfigPut} bodyGetConfigGetConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigGetConfigPut(
      bodyGetConfigGetConfigPut: BodyGetConfigGetConfigPut,
      options?: any,
    ): AxiosPromise<ConfigResponse> {
      return localVarFp
        .getConfigGetConfigPut(bodyGetConfigGetConfigPut, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Configs
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigsGetConfigsPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<GetConfigsResponse> {
      return localVarFp
        .getConfigsGetConfigsPut(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Default Config
     * @param {string} googleUsername
     * @param {BodyGetDefaultConfigGetDefaultConfigPut} bodyGetDefaultConfigGetDefaultConfigPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultConfigGetDefaultConfigPut(
      googleUsername: string,
      bodyGetDefaultConfigGetDefaultConfigPut: BodyGetDefaultConfigGetDefaultConfigPut,
      options?: any,
    ): AxiosPromise<ConfigResponse> {
      return localVarFp
        .getDefaultConfigGetDefaultConfigPut(
          googleUsername,
          bodyGetDefaultConfigGetDefaultConfigPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Run Config
     * @param {BodyRunConfigRunConfigPut} bodyRunConfigRunConfigPut
     * @param {boolean} [skipSurveyMonkeyImport]
     * @param {boolean} [skipValidation]
     * @param {boolean} [skipDemoBinCheck]
     * @param {boolean} [skipCollectorAlerts]
     * @param {boolean} [skipNlp]
     * @param {boolean} [skipTopLinesDoc]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runConfigRunConfigPut(
      bodyRunConfigRunConfigPut: BodyRunConfigRunConfigPut,
      skipSurveyMonkeyImport?: boolean,
      skipValidation?: boolean,
      skipDemoBinCheck?: boolean,
      skipCollectorAlerts?: boolean,
      skipNlp?: boolean,
      skipTopLinesDoc?: boolean,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .runConfigRunConfigPut(
          bodyRunConfigRunConfigPut,
          skipSurveyMonkeyImport,
          skipValidation,
          skipDemoBinCheck,
          skipCollectorAlerts,
          skipNlp,
          skipTopLinesDoc,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LegacyCrosstabsApi - object-oriented interface
 * @export
 * @class LegacyCrosstabsApi
 * @extends {BaseAPI}
 */
export class LegacyCrosstabsApi extends BaseAPI {
  /**
   * Add google sheet url containing generated crosstabs.  - **poll_id**: server generated poll_id of the poll - **config_id**: config used to produce crosstabs - **url**: Google docs url
   * @summary Add Google Sheet Crosstabs Url
   * @param {string} url
   * @param {BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut} bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyCrosstabsApi
   */
  public addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut(
    url: string,
    bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut: BodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
    options?: AxiosRequestConfig,
  ) {
    return LegacyCrosstabsApiFp(this.configuration)
      .addGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut(
        url,
        bodyAddGoogleSheetCrosstabsUrlAddGoogleSheetCrosstabsUrlPut,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates named config in the poll store, adding a new config if the named config does not yet exist.  - **poll_id**: PollId of the poll - **config_name**: name of the config - **config**: string value of the config json file - **do_weights**: string value of the do_weights sql file - **original_zip_base64**: base64 encoded bytes of the original zip   file. This zip file will be unzipped and the config, do_weights files   updated with the provided values before being written to the poll   store.  Returns a ConfigResponse with updated values.
   * @summary Commit Config
   * @param {string} configName
   * @param {BodyCommitConfigCommitConfigPut} bodyCommitConfigCommitConfigPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyCrosstabsApi
   */
  public commitConfigCommitConfigPut(
    configName: string,
    bodyCommitConfigCommitConfigPut: BodyCommitConfigCommitConfigPut,
    options?: AxiosRequestConfig,
  ) {
    return LegacyCrosstabsApiFp(this.configuration)
      .commitConfigCommitConfigPut(
        configName,
        bodyCommitConfigCommitConfigPut,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Config
   * @param {BodyGetConfigGetConfigPut} bodyGetConfigGetConfigPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyCrosstabsApi
   */
  public getConfigGetConfigPut(
    bodyGetConfigGetConfigPut: BodyGetConfigGetConfigPut,
    options?: AxiosRequestConfig,
  ) {
    return LegacyCrosstabsApiFp(this.configuration)
      .getConfigGetConfigPut(bodyGetConfigGetConfigPut, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Configs
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyCrosstabsApi
   */
  public getConfigsGetConfigsPut(pollId: PollId, options?: AxiosRequestConfig) {
    return LegacyCrosstabsApiFp(this.configuration)
      .getConfigsGetConfigsPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Default Config
   * @param {string} googleUsername
   * @param {BodyGetDefaultConfigGetDefaultConfigPut} bodyGetDefaultConfigGetDefaultConfigPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyCrosstabsApi
   */
  public getDefaultConfigGetDefaultConfigPut(
    googleUsername: string,
    bodyGetDefaultConfigGetDefaultConfigPut: BodyGetDefaultConfigGetDefaultConfigPut,
    options?: AxiosRequestConfig,
  ) {
    return LegacyCrosstabsApiFp(this.configuration)
      .getDefaultConfigGetDefaultConfigPut(
        googleUsername,
        bodyGetDefaultConfigGetDefaultConfigPut,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Run Config
   * @param {BodyRunConfigRunConfigPut} bodyRunConfigRunConfigPut
   * @param {boolean} [skipSurveyMonkeyImport]
   * @param {boolean} [skipValidation]
   * @param {boolean} [skipDemoBinCheck]
   * @param {boolean} [skipCollectorAlerts]
   * @param {boolean} [skipNlp]
   * @param {boolean} [skipTopLinesDoc]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LegacyCrosstabsApi
   */
  public runConfigRunConfigPut(
    bodyRunConfigRunConfigPut: BodyRunConfigRunConfigPut,
    skipSurveyMonkeyImport?: boolean,
    skipValidation?: boolean,
    skipDemoBinCheck?: boolean,
    skipCollectorAlerts?: boolean,
    skipNlp?: boolean,
    skipTopLinesDoc?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return LegacyCrosstabsApiFp(this.configuration)
      .runConfigRunConfigPut(
        bodyRunConfigRunConfigPut,
        skipSurveyMonkeyImport,
        skipValidation,
        skipDemoBinCheck,
        skipCollectorAlerts,
        skipNlp,
        skipTopLinesDoc,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MainApi - axios parameter creator
 * @export
 */
export const MainApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Close Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closePollClosePollPut: async (
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('closePollClosePollPut', 'pollId', pollId);
      const localVarPath = `/close_poll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Poll Auto Fielder
     * @param {MetaData} metaData
     * @param {string} [pollId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPollAutoFielderCreatePollAutoFielderPut: async (
      metaData: MetaData,
      pollId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'metaData' is not null or undefined
      assertParamExists(
        'createPollAutoFielderCreatePollAutoFielderPut',
        'metaData',
        metaData,
      );
      const localVarPath = `/create_poll_auto_fielder`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pollId !== undefined) {
        localVarQueryParameter['poll_id'] = pollId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        metaData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Feature Flag Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeatureFlagStatusFeatureFlagsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/feature_flags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Individual Level Data
     * @param {string} pollId
     * @param {string} weightingConfigUuid
     * @param {boolean} dontFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIndividualLevelDataExportIndividualLevelDataPut: async (
      pollId: string,
      weightingConfigUuid: string,
      dontFilter: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'getIndividualLevelDataExportIndividualLevelDataPut',
        'pollId',
        pollId,
      );
      // verify required parameter 'weightingConfigUuid' is not null or undefined
      assertParamExists(
        'getIndividualLevelDataExportIndividualLevelDataPut',
        'weightingConfigUuid',
        weightingConfigUuid,
      );
      // verify required parameter 'dontFilter' is not null or undefined
      assertParamExists(
        'getIndividualLevelDataExportIndividualLevelDataPut',
        'dontFilter',
        dontFilter,
      );
      const localVarPath = `/export_individual_level_data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pollId !== undefined) {
        localVarQueryParameter['poll_id'] = pollId;
      }

      if (weightingConfigUuid !== undefined) {
        localVarQueryParameter['weighting_config_uuid'] = weightingConfigUuid;
      }

      if (dontFilter !== undefined) {
        localVarQueryParameter['dont_filter'] = dontFilter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns complete view of poll for poll_id.  - **poll_id**: server generated poll_id of the poll
     * @summary Get Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPollGetPollPut: async (
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('getPollGetPollPut', 'pollId', pollId);
      const localVarPath = `/get_poll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns partial view of all current polls, including their ids and names.
     * @summary Get Polls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPollsGetPollsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/get_polls`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Process Webhook Callback
     * @param {SurveyMonkeyCallback} surveyMonkeyCallback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processWebhookCallbackProcessWebhookCallbackPost: async (
      surveyMonkeyCallback: SurveyMonkeyCallback,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'surveyMonkeyCallback' is not null or undefined
      assertParamExists(
        'processWebhookCallbackProcessWebhookCallbackPost',
        'surveyMonkeyCallback',
        surveyMonkeyCallback,
      );
      const localVarPath = `/process_webhook_callback`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        surveyMonkeyCallback,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reopen Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reopenPollReopenPollPut: async (
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('reopenPollReopenPollPut', 'pollId', pollId);
      const localVarPath = `/reopen_poll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reset Poll
     * @param {string} pollIdUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPollResetPollPollIdUuidPost: async (
      pollIdUuid: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollIdUuid' is not null or undefined
      assertParamExists(
        'resetPollResetPollPollIdUuidPost',
        'pollIdUuid',
        pollIdUuid,
      );
      const localVarPath = `/reset_poll/{poll_id_uuid}`.replace(
        `{${'poll_id_uuid'}}`,
        encodeURIComponent(String(pollIdUuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Horserace Confirmation
     * @param {boolean} isConfirmed
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setHorseraceConfirmationHorseraceConfirmationPut: async (
      isConfirmed: boolean,
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isConfirmed' is not null or undefined
      assertParamExists(
        'setHorseraceConfirmationHorseraceConfirmationPut',
        'isConfirmed',
        isConfirmed,
      );
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'setHorseraceConfirmationHorseraceConfirmationPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/horserace_confirmation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isConfirmed !== undefined) {
        localVarQueryParameter['is_confirmed'] = isConfirmed;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Metadata
     * @param {BodyUpdateMetadataUpdateMetadataPut} bodyUpdateMetadataUpdateMetadataPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMetadataUpdateMetadataPut: async (
      bodyUpdateMetadataUpdateMetadataPut: BodyUpdateMetadataUpdateMetadataPut,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyUpdateMetadataUpdateMetadataPut' is not null or undefined
      assertParamExists(
        'updateMetadataUpdateMetadataPut',
        'bodyUpdateMetadataUpdateMetadataPut',
        bodyUpdateMetadataUpdateMetadataPut,
      );
      const localVarPath = `/update_metadata`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyUpdateMetadataUpdateMetadataPut,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get responses to quickly check if we have permissions to access survey
     * @summary Validate Survey Permissions
     * @param {string} smId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateSurveyPermissionsValidateSurveyPermissionsPut: async (
      smId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'smId' is not null or undefined
      assertParamExists(
        'validateSurveyPermissionsValidateSurveyPermissionsPut',
        'smId',
        smId,
      );
      const localVarPath = `/validate_survey_permissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (smId !== undefined) {
        localVarQueryParameter['sm_id'] = smId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate Survey
     * @param {PollId} pollId
     * @param {boolean} [useLegacyValidation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateSurveyValidateSurveyPut: async (
      pollId: PollId,
      useLegacyValidation?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('validateSurveyValidateSurveyPut', 'pollId', pollId);
      const localVarPath = `/validate_survey`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (useLegacyValidation !== undefined) {
        localVarQueryParameter['use_legacy_validation'] = useLegacyValidation;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Debug endpoint. Returns full view of all current polls.
     * @summary Varz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    varzVarzGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/varz`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary View Closed Polls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    viewClosedPollsViewClosedPollsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/view_closed_polls`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MainApi - functional programming interface
 * @export
 */
export const MainApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MainApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Close Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closePollClosePollPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.closePollClosePollPut(pollId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create Poll Auto Fielder
     * @param {MetaData} metaData
     * @param {string} [pollId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPollAutoFielderCreatePollAutoFielderPut(
      metaData: MetaData,
      pollId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PollSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPollAutoFielderCreatePollAutoFielderPut(
          metaData,
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Feature Flag Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFeatureFlagStatusFeatureFlagsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FeatureFlagStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFeatureFlagStatusFeatureFlagsGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Individual Level Data
     * @param {string} pollId
     * @param {string} weightingConfigUuid
     * @param {boolean} dontFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIndividualLevelDataExportIndividualLevelDataPut(
      pollId: string,
      weightingConfigUuid: string,
      dontFilter: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getIndividualLevelDataExportIndividualLevelDataPut(
          pollId,
          weightingConfigUuid,
          dontFilter,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns complete view of poll for poll_id.  - **poll_id**: server generated poll_id of the poll
     * @summary Get Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPollGetPollPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPollResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPollGetPollPut(pollId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns partial view of all current polls, including their ids and names.
     * @summary Get Polls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPollsGetPollsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPollsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPollsGetPollsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Process Webhook Callback
     * @param {SurveyMonkeyCallback} surveyMonkeyCallback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async processWebhookCallbackProcessWebhookCallbackPost(
      surveyMonkeyCallback: SurveyMonkeyCallback,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.processWebhookCallbackProcessWebhookCallbackPost(
          surveyMonkeyCallback,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Reopen Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reopenPollReopenPollPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.reopenPollReopenPollPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Reset Poll
     * @param {string} pollIdUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPollResetPollPollIdUuidPost(
      pollIdUuid: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PollSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resetPollResetPollPollIdUuidPost(
          pollIdUuid,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Set Horserace Confirmation
     * @param {boolean} isConfirmed
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setHorseraceConfirmationHorseraceConfirmationPut(
      isConfirmed: boolean,
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setHorseraceConfirmationHorseraceConfirmationPut(
          isConfirmed,
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Metadata
     * @param {BodyUpdateMetadataUpdateMetadataPut} bodyUpdateMetadataUpdateMetadataPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMetadataUpdateMetadataPut(
      bodyUpdateMetadataUpdateMetadataPut: BodyUpdateMetadataUpdateMetadataPut,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateMetadataUpdateMetadataPut(
          bodyUpdateMetadataUpdateMetadataPut,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get responses to quickly check if we have permissions to access survey
     * @summary Validate Survey Permissions
     * @param {string} smId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateSurveyPermissionsValidateSurveyPermissionsPut(
      smId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.validateSurveyPermissionsValidateSurveyPermissionsPut(
          smId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Validate Survey
     * @param {PollId} pollId
     * @param {boolean} [useLegacyValidation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateSurveyValidateSurveyPut(
      pollId: PollId,
      useLegacyValidation?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ValidateSurveyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.validateSurveyValidateSurveyPut(
          pollId,
          useLegacyValidation,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Debug endpoint. Returns full view of all current polls.
     * @summary Varz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async varzVarzGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VarzResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.varzVarzGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary View Closed Polls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async viewClosedPollsViewClosedPollsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ViewClosedPollsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.viewClosedPollsViewClosedPollsGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MainApi - factory interface
 * @export
 */
export const MainApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MainApiFp(configuration);
  return {
    /**
     *
     * @summary Close Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closePollClosePollPut(pollId: PollId, options?: any): AxiosPromise<any> {
      return localVarFp
        .closePollClosePollPut(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Poll Auto Fielder
     * @param {MetaData} metaData
     * @param {string} [pollId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPollAutoFielderCreatePollAutoFielderPut(
      metaData: MetaData,
      pollId?: string,
      options?: any,
    ): AxiosPromise<PollSummary> {
      return localVarFp
        .createPollAutoFielderCreatePollAutoFielderPut(
          metaData,
          pollId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Feature Flag Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeatureFlagStatusFeatureFlagsGet(
      options?: any,
    ): AxiosPromise<FeatureFlagStatusResponse> {
      return localVarFp
        .getFeatureFlagStatusFeatureFlagsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Individual Level Data
     * @param {string} pollId
     * @param {string} weightingConfigUuid
     * @param {boolean} dontFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIndividualLevelDataExportIndividualLevelDataPut(
      pollId: string,
      weightingConfigUuid: string,
      dontFilter: boolean,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .getIndividualLevelDataExportIndividualLevelDataPut(
          pollId,
          weightingConfigUuid,
          dontFilter,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns complete view of poll for poll_id.  - **poll_id**: server generated poll_id of the poll
     * @summary Get Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPollGetPollPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<GetPollResponse> {
      return localVarFp
        .getPollGetPollPut(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns partial view of all current polls, including their ids and names.
     * @summary Get Polls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPollsGetPollsGet(options?: any): AxiosPromise<GetPollsResponse> {
      return localVarFp
        .getPollsGetPollsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Process Webhook Callback
     * @param {SurveyMonkeyCallback} surveyMonkeyCallback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processWebhookCallbackProcessWebhookCallbackPost(
      surveyMonkeyCallback: SurveyMonkeyCallback,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .processWebhookCallbackProcessWebhookCallbackPost(
          surveyMonkeyCallback,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reopen Poll
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reopenPollReopenPollPut(pollId: PollId, options?: any): AxiosPromise<any> {
      return localVarFp
        .reopenPollReopenPollPut(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reset Poll
     * @param {string} pollIdUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPollResetPollPollIdUuidPost(
      pollIdUuid: string,
      options?: any,
    ): AxiosPromise<PollSummary> {
      return localVarFp
        .resetPollResetPollPollIdUuidPost(pollIdUuid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Set Horserace Confirmation
     * @param {boolean} isConfirmed
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setHorseraceConfirmationHorseraceConfirmationPut(
      isConfirmed: boolean,
      pollId: PollId,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .setHorseraceConfirmationHorseraceConfirmationPut(
          isConfirmed,
          pollId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Metadata
     * @param {BodyUpdateMetadataUpdateMetadataPut} bodyUpdateMetadataUpdateMetadataPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMetadataUpdateMetadataPut(
      bodyUpdateMetadataUpdateMetadataPut: BodyUpdateMetadataUpdateMetadataPut,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .updateMetadataUpdateMetadataPut(
          bodyUpdateMetadataUpdateMetadataPut,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get responses to quickly check if we have permissions to access survey
     * @summary Validate Survey Permissions
     * @param {string} smId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateSurveyPermissionsValidateSurveyPermissionsPut(
      smId: string,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .validateSurveyPermissionsValidateSurveyPermissionsPut(smId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Validate Survey
     * @param {PollId} pollId
     * @param {boolean} [useLegacyValidation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateSurveyValidateSurveyPut(
      pollId: PollId,
      useLegacyValidation?: boolean,
      options?: any,
    ): AxiosPromise<ValidateSurveyResponse> {
      return localVarFp
        .validateSurveyValidateSurveyPut(pollId, useLegacyValidation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Debug endpoint. Returns full view of all current polls.
     * @summary Varz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    varzVarzGet(options?: any): AxiosPromise<VarzResponse> {
      return localVarFp
        .varzVarzGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary View Closed Polls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    viewClosedPollsViewClosedPollsGet(
      options?: any,
    ): AxiosPromise<ViewClosedPollsResponse> {
      return localVarFp
        .viewClosedPollsViewClosedPollsGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MainApi - object-oriented interface
 * @export
 * @class MainApi
 * @extends {BaseAPI}
 */
export class MainApi extends BaseAPI {
  /**
   *
   * @summary Close Poll
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public closePollClosePollPut(pollId: PollId, options?: AxiosRequestConfig) {
    return MainApiFp(this.configuration)
      .closePollClosePollPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Poll Auto Fielder
   * @param {MetaData} metaData
   * @param {string} [pollId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public createPollAutoFielderCreatePollAutoFielderPut(
    metaData: MetaData,
    pollId?: string,
    options?: AxiosRequestConfig,
  ) {
    return MainApiFp(this.configuration)
      .createPollAutoFielderCreatePollAutoFielderPut(metaData, pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Feature Flag Status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public getFeatureFlagStatusFeatureFlagsGet(options?: AxiosRequestConfig) {
    return MainApiFp(this.configuration)
      .getFeatureFlagStatusFeatureFlagsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Individual Level Data
   * @param {string} pollId
   * @param {string} weightingConfigUuid
   * @param {boolean} dontFilter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public getIndividualLevelDataExportIndividualLevelDataPut(
    pollId: string,
    weightingConfigUuid: string,
    dontFilter: boolean,
    options?: AxiosRequestConfig,
  ) {
    return MainApiFp(this.configuration)
      .getIndividualLevelDataExportIndividualLevelDataPut(
        pollId,
        weightingConfigUuid,
        dontFilter,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns complete view of poll for poll_id.  - **poll_id**: server generated poll_id of the poll
   * @summary Get Poll
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public getPollGetPollPut(pollId: PollId, options?: AxiosRequestConfig) {
    return MainApiFp(this.configuration)
      .getPollGetPollPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns partial view of all current polls, including their ids and names.
   * @summary Get Polls
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public getPollsGetPollsGet(options?: AxiosRequestConfig) {
    return MainApiFp(this.configuration)
      .getPollsGetPollsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Process Webhook Callback
   * @param {SurveyMonkeyCallback} surveyMonkeyCallback
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public processWebhookCallbackProcessWebhookCallbackPost(
    surveyMonkeyCallback: SurveyMonkeyCallback,
    options?: AxiosRequestConfig,
  ) {
    return MainApiFp(this.configuration)
      .processWebhookCallbackProcessWebhookCallbackPost(
        surveyMonkeyCallback,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reopen Poll
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public reopenPollReopenPollPut(pollId: PollId, options?: AxiosRequestConfig) {
    return MainApiFp(this.configuration)
      .reopenPollReopenPollPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reset Poll
   * @param {string} pollIdUuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public resetPollResetPollPollIdUuidPost(
    pollIdUuid: string,
    options?: AxiosRequestConfig,
  ) {
    return MainApiFp(this.configuration)
      .resetPollResetPollPollIdUuidPost(pollIdUuid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Horserace Confirmation
   * @param {boolean} isConfirmed
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public setHorseraceConfirmationHorseraceConfirmationPut(
    isConfirmed: boolean,
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return MainApiFp(this.configuration)
      .setHorseraceConfirmationHorseraceConfirmationPut(
        isConfirmed,
        pollId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Metadata
   * @param {BodyUpdateMetadataUpdateMetadataPut} bodyUpdateMetadataUpdateMetadataPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public updateMetadataUpdateMetadataPut(
    bodyUpdateMetadataUpdateMetadataPut: BodyUpdateMetadataUpdateMetadataPut,
    options?: AxiosRequestConfig,
  ) {
    return MainApiFp(this.configuration)
      .updateMetadataUpdateMetadataPut(
        bodyUpdateMetadataUpdateMetadataPut,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get responses to quickly check if we have permissions to access survey
   * @summary Validate Survey Permissions
   * @param {string} smId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public validateSurveyPermissionsValidateSurveyPermissionsPut(
    smId: string,
    options?: AxiosRequestConfig,
  ) {
    return MainApiFp(this.configuration)
      .validateSurveyPermissionsValidateSurveyPermissionsPut(smId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Validate Survey
   * @param {PollId} pollId
   * @param {boolean} [useLegacyValidation]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public validateSurveyValidateSurveyPut(
    pollId: PollId,
    useLegacyValidation?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return MainApiFp(this.configuration)
      .validateSurveyValidateSurveyPut(pollId, useLegacyValidation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Debug endpoint. Returns full view of all current polls.
   * @summary Varz
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public varzVarzGet(options?: AxiosRequestConfig) {
    return MainApiFp(this.configuration)
      .varzVarzGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary View Closed Polls
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MainApi
   */
  public viewClosedPollsViewClosedPollsGet(options?: AxiosRequestConfig) {
    return MainApiFp(this.configuration)
      .viewClosedPollsViewClosedPollsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SurveyTemplatesApi - axios parameter creator
 * @export
 */
export const SurveyTemplatesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Survey Template
     * @param {CreateSurveyTemplateRequest} createSurveyTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSurveyTemplateSurveyTemplatesPost: async (
      createSurveyTemplateRequest: CreateSurveyTemplateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSurveyTemplateRequest' is not null or undefined
      assertParamExists(
        'createSurveyTemplateSurveyTemplatesPost',
        'createSurveyTemplateRequest',
        createSurveyTemplateRequest,
      );
      const localVarPath = `/survey_templates/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSurveyTemplateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Survey Template
     * @param {number} surveyTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSurveyTemplateSurveyTemplatesSurveyTemplateIdDelete: async (
      surveyTemplateId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'surveyTemplateId' is not null or undefined
      assertParamExists(
        'deleteSurveyTemplateSurveyTemplatesSurveyTemplateIdDelete',
        'surveyTemplateId',
        surveyTemplateId,
      );
      const localVarPath = `/survey_templates/{survey_template_id}`.replace(
        `{${'survey_template_id'}}`,
        encodeURIComponent(String(surveyTemplateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Survey Template
     * @param {number} surveyTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSurveyTemplateSurveyTemplatesSurveyTemplateIdGet: async (
      surveyTemplateId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'surveyTemplateId' is not null or undefined
      assertParamExists(
        'getSurveyTemplateSurveyTemplatesSurveyTemplateIdGet',
        'surveyTemplateId',
        surveyTemplateId,
      );
      const localVarPath = `/survey_templates/{survey_template_id}`.replace(
        `{${'survey_template_id'}}`,
        encodeURIComponent(String(surveyTemplateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Survey Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSurveyTemplatesSurveyTemplatesGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/survey_templates/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Survey Template
     * @param {number} surveyTemplateId
     * @param {SurveyTemplate} surveyTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut: async (
      surveyTemplateId: number,
      surveyTemplate: SurveyTemplate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'surveyTemplateId' is not null or undefined
      assertParamExists(
        'updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut',
        'surveyTemplateId',
        surveyTemplateId,
      );
      // verify required parameter 'surveyTemplate' is not null or undefined
      assertParamExists(
        'updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut',
        'surveyTemplate',
        surveyTemplate,
      );
      const localVarPath = `/survey_templates/{survey_template_id}`.replace(
        `{${'survey_template_id'}}`,
        encodeURIComponent(String(surveyTemplateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        surveyTemplate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SurveyTemplatesApi - functional programming interface
 * @export
 */
export const SurveyTemplatesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SurveyTemplatesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Survey Template
     * @param {CreateSurveyTemplateRequest} createSurveyTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSurveyTemplateSurveyTemplatesPost(
      createSurveyTemplateRequest: CreateSurveyTemplateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSurveyTemplateSurveyTemplatesPost(
          createSurveyTemplateRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete Survey Template
     * @param {number} surveyTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSurveyTemplateSurveyTemplatesSurveyTemplateIdDelete(
      surveyTemplateId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSurveyTemplateSurveyTemplatesSurveyTemplateIdDelete(
          surveyTemplateId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Survey Template
     * @param {number} surveyTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSurveyTemplateSurveyTemplatesSurveyTemplateIdGet(
      surveyTemplateId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyTemplate>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSurveyTemplateSurveyTemplatesSurveyTemplateIdGet(
          surveyTemplateId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Survey Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSurveyTemplatesSurveyTemplatesGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SurveyTemplatesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSurveyTemplatesSurveyTemplatesGet(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Survey Template
     * @param {number} surveyTemplateId
     * @param {SurveyTemplate} surveyTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut(
      surveyTemplateId: number,
      surveyTemplate: SurveyTemplate,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut(
          surveyTemplateId,
          surveyTemplate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SurveyTemplatesApi - factory interface
 * @export
 */
export const SurveyTemplatesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SurveyTemplatesApiFp(configuration);
  return {
    /**
     *
     * @summary Create Survey Template
     * @param {CreateSurveyTemplateRequest} createSurveyTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSurveyTemplateSurveyTemplatesPost(
      createSurveyTemplateRequest: CreateSurveyTemplateRequest,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .createSurveyTemplateSurveyTemplatesPost(
          createSurveyTemplateRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Survey Template
     * @param {number} surveyTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSurveyTemplateSurveyTemplatesSurveyTemplateIdDelete(
      surveyTemplateId: number,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .deleteSurveyTemplateSurveyTemplatesSurveyTemplateIdDelete(
          surveyTemplateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Survey Template
     * @param {number} surveyTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSurveyTemplateSurveyTemplatesSurveyTemplateIdGet(
      surveyTemplateId: number,
      options?: any,
    ): AxiosPromise<SurveyTemplate> {
      return localVarFp
        .getSurveyTemplateSurveyTemplatesSurveyTemplateIdGet(
          surveyTemplateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Survey Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSurveyTemplatesSurveyTemplatesGet(
      options?: any,
    ): AxiosPromise<SurveyTemplatesResponse> {
      return localVarFp
        .getSurveyTemplatesSurveyTemplatesGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Survey Template
     * @param {number} surveyTemplateId
     * @param {SurveyTemplate} surveyTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut(
      surveyTemplateId: number,
      surveyTemplate: SurveyTemplate,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut(
          surveyTemplateId,
          surveyTemplate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SurveyTemplatesApi - object-oriented interface
 * @export
 * @class SurveyTemplatesApi
 * @extends {BaseAPI}
 */
export class SurveyTemplatesApi extends BaseAPI {
  /**
   *
   * @summary Create Survey Template
   * @param {CreateSurveyTemplateRequest} createSurveyTemplateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyTemplatesApi
   */
  public createSurveyTemplateSurveyTemplatesPost(
    createSurveyTemplateRequest: CreateSurveyTemplateRequest,
    options?: AxiosRequestConfig,
  ) {
    return SurveyTemplatesApiFp(this.configuration)
      .createSurveyTemplateSurveyTemplatesPost(
        createSurveyTemplateRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Survey Template
   * @param {number} surveyTemplateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyTemplatesApi
   */
  public deleteSurveyTemplateSurveyTemplatesSurveyTemplateIdDelete(
    surveyTemplateId: number,
    options?: AxiosRequestConfig,
  ) {
    return SurveyTemplatesApiFp(this.configuration)
      .deleteSurveyTemplateSurveyTemplatesSurveyTemplateIdDelete(
        surveyTemplateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Survey Template
   * @param {number} surveyTemplateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyTemplatesApi
   */
  public getSurveyTemplateSurveyTemplatesSurveyTemplateIdGet(
    surveyTemplateId: number,
    options?: AxiosRequestConfig,
  ) {
    return SurveyTemplatesApiFp(this.configuration)
      .getSurveyTemplateSurveyTemplatesSurveyTemplateIdGet(
        surveyTemplateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Survey Templates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyTemplatesApi
   */
  public getSurveyTemplatesSurveyTemplatesGet(options?: AxiosRequestConfig) {
    return SurveyTemplatesApiFp(this.configuration)
      .getSurveyTemplatesSurveyTemplatesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Survey Template
   * @param {number} surveyTemplateId
   * @param {SurveyTemplate} surveyTemplate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyTemplatesApi
   */
  public updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut(
    surveyTemplateId: number,
    surveyTemplate: SurveyTemplate,
    options?: AxiosRequestConfig,
  ) {
    return SurveyTemplatesApiFp(this.configuration)
      .updateSurveyTemplateSurveyTemplatesSurveyTemplateIdPut(
        surveyTemplateId,
        surveyTemplate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WeightingApi - axios parameter creator
 * @export
 */
export const WeightingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Config Create New
     * @param {string} name
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configCreateNewWeightingConfigPut: async (
      name: string,
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('configCreateNewWeightingConfigPut', 'name', name);
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('configCreateNewWeightingConfigPut', 'pollId', pollId);
      const localVarPath = `/weighting/config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Config Delete
     * @param {string} uuid
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configDeleteWeightingConfigUuidDelete: async (
      uuid: string,
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('configDeleteWeightingConfigUuidDelete', 'uuid', uuid);
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'configDeleteWeightingConfigUuidDelete',
        'pollId',
        pollId,
      );
      const localVarPath = `/weighting/config/{uuid}`.replace(
        `{${'uuid'}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Config Update
     * @param {string} uuid
     * @param {PollId} pollId
     * @param {string} [name]
     * @param {number} [minWeight]
     * @param {number} [maxWeight]
     * @param {string} [filterColumn]
     * @param {number} [designEffect]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configUpdateWeightingConfigUuidPut: async (
      uuid: string,
      pollId: PollId,
      name?: string,
      minWeight?: number,
      maxWeight?: number,
      filterColumn?: string,
      designEffect?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists('configUpdateWeightingConfigUuidPut', 'uuid', uuid);
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('configUpdateWeightingConfigUuidPut', 'pollId', pollId);
      const localVarPath = `/weighting/config/{uuid}`.replace(
        `{${'uuid'}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (minWeight !== undefined) {
        localVarQueryParameter['min_weight'] = minWeight;
      }

      if (maxWeight !== undefined) {
        localVarQueryParameter['max_weight'] = maxWeight;
      }

      if (filterColumn !== undefined) {
        localVarQueryParameter['filter_column'] = filterColumn;
      }

      if (designEffect !== undefined) {
        localVarQueryParameter['design_effect'] = designEffect;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List the weighting configs associated with a poll.
     * @summary Configs Map
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configsMapWeightingConfigsPut: async (
      pollId: PollId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('configsMapWeightingConfigsPut', 'pollId', pollId);
      const localVarPath = `/weighting/configs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filter Get
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterGetWeightingFilterGet: async (
      pollId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('filterGetWeightingFilterGet', 'pollId', pollId);
      const localVarPath = `/weighting/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pollId !== undefined) {
        localVarQueryParameter['poll_id'] = pollId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filter Post
     * @param {string} pollId
     * @param {FilterConfig} filterConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterPostWeightingFilterPut: async (
      pollId: string,
      filterConfig: FilterConfig,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists('filterPostWeightingFilterPut', 'pollId', pollId);
      // verify required parameter 'filterConfig' is not null or undefined
      assertParamExists(
        'filterPostWeightingFilterPut',
        'filterConfig',
        filterConfig,
      );
      const localVarPath = `/weighting/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pollId !== undefined) {
        localVarQueryParameter['poll_id'] = pollId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        filterConfig,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of SM questions and their answers if possible
     * @summary Filter Questions Get
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterQuestionsGetWeightingFilterQuestionsGet: async (
      pollId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'filterQuestionsGetWeightingFilterQuestionsGet',
        'pollId',
        pollId,
      );
      const localVarPath = `/weighting/filter/questions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pollId !== undefined) {
        localVarQueryParameter['poll_id'] = pollId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Given a list of column names as query parameters, create a target_group
     * @summary Target Group Add
     * @param {string} uuid
     * @param {string} name
     * @param {PollId} pollId
     * @param {string} [dncNames]
     * @param {string} [dncOutCols]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    targetGroupAddWeightingConfigUuidNewTargetGroupPut: async (
      uuid: string,
      name: string,
      pollId: PollId,
      dncNames?: string,
      dncOutCols?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists(
        'targetGroupAddWeightingConfigUuidNewTargetGroupPut',
        'uuid',
        uuid,
      );
      // verify required parameter 'name' is not null or undefined
      assertParamExists(
        'targetGroupAddWeightingConfigUuidNewTargetGroupPut',
        'name',
        name,
      );
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'targetGroupAddWeightingConfigUuidNewTargetGroupPut',
        'pollId',
        pollId,
      );
      const localVarPath = `/weighting/config/{uuid}/new_target_group`.replace(
        `{${'uuid'}}`,
        encodeURIComponent(String(uuid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (dncNames !== undefined) {
        localVarQueryParameter['dnc_names'] = dncNames;
      }

      if (dncOutCols !== undefined) {
        localVarQueryParameter['dnc_out_cols'] = dncOutCols;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Target Group Delete
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete:
      async (
        uuid: string,
        targetGroupUuid: string,
        pollId: PollId,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'uuid' is not null or undefined
        assertParamExists(
          'targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete',
          'uuid',
          uuid,
        );
        // verify required parameter 'targetGroupUuid' is not null or undefined
        assertParamExists(
          'targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete',
          'targetGroupUuid',
          targetGroupUuid,
        );
        // verify required parameter 'pollId' is not null or undefined
        assertParamExists(
          'targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete',
          'pollId',
          pollId,
        );
        const localVarPath =
          `/weighting/config/{uuid}/target_group/{target_group_uuid}`
            .replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)))
            .replace(
              `{${'target_group_uuid'}}`,
              encodeURIComponent(String(targetGroupUuid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'DELETE',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          pollId,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Target Group Update
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {PollId} pollId
     * @param {string} [name]
     * @param {boolean} [enabled]
     * @param {number} [relativeImportance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut: async (
      uuid: string,
      targetGroupUuid: string,
      pollId: PollId,
      name?: string,
      enabled?: boolean,
      relativeImportance?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists(
        'targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut',
        'uuid',
        uuid,
      );
      // verify required parameter 'targetGroupUuid' is not null or undefined
      assertParamExists(
        'targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut',
        'targetGroupUuid',
        targetGroupUuid,
      );
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut',
        'pollId',
        pollId,
      );
      const localVarPath =
        `/weighting/config/{uuid}/target_group/{target_group_uuid}`
          .replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)))
          .replace(
            `{${'target_group_uuid'}}`,
            encodeURIComponent(String(targetGroupUuid)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (enabled !== undefined) {
        localVarQueryParameter['enabled'] = enabled;
      }

      if (relativeImportance !== undefined) {
        localVarQueryParameter['relative_importance'] = relativeImportance;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pollId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Target Subgroup Update
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {string} targetSubgroupUuid
     * @param {PollId} pollId
     * @param {number} [targetValue]
     * @param {boolean} [include]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut:
      async (
        uuid: string,
        targetGroupUuid: string,
        targetSubgroupUuid: string,
        pollId: PollId,
        targetValue?: number,
        include?: boolean,
        options: AxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'uuid' is not null or undefined
        assertParamExists(
          'targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut',
          'uuid',
          uuid,
        );
        // verify required parameter 'targetGroupUuid' is not null or undefined
        assertParamExists(
          'targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut',
          'targetGroupUuid',
          targetGroupUuid,
        );
        // verify required parameter 'targetSubgroupUuid' is not null or undefined
        assertParamExists(
          'targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut',
          'targetSubgroupUuid',
          targetSubgroupUuid,
        );
        // verify required parameter 'pollId' is not null or undefined
        assertParamExists(
          'targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut',
          'pollId',
          pollId,
        );
        const localVarPath =
          `/weighting/config/{uuid}/target_group/{target_group_uuid}/subgroup/{target_subgroup_uuid}`
            .replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)))
            .replace(
              `{${'target_group_uuid'}}`,
              encodeURIComponent(String(targetGroupUuid)),
            )
            .replace(
              `{${'target_subgroup_uuid'}}`,
              encodeURIComponent(String(targetSubgroupUuid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (targetValue !== undefined) {
          localVarQueryParameter['target_value'] = targetValue;
        }

        if (include !== undefined) {
          localVarQueryParameter['include'] = include;
        }

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          pollId,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Weighting Report
     * @param {string} pollId
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    weightingReportWeightingReportPollIdConfigIdGet: async (
      pollId: string,
      configId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pollId' is not null or undefined
      assertParamExists(
        'weightingReportWeightingReportPollIdConfigIdGet',
        'pollId',
        pollId,
      );
      // verify required parameter 'configId' is not null or undefined
      assertParamExists(
        'weightingReportWeightingReportPollIdConfigIdGet',
        'configId',
        configId,
      );
      const localVarPath = `/weighting/report/{poll_id}/{config_id}`
        .replace(`{${'poll_id'}}`, encodeURIComponent(String(pollId)))
        .replace(`{${'config_id'}}`, encodeURIComponent(String(configId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WeightingApi - functional programming interface
 * @export
 */
export const WeightingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WeightingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Config Create New
     * @param {string} name
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configCreateNewWeightingConfigPut(
      name: string,
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WeightingConfigResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.configCreateNewWeightingConfigPut(
          name,
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Config Delete
     * @param {string} uuid
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configDeleteWeightingConfigUuidDelete(
      uuid: string,
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: string }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.configDeleteWeightingConfigUuidDelete(
          uuid,
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Config Update
     * @param {string} uuid
     * @param {PollId} pollId
     * @param {string} [name]
     * @param {number} [minWeight]
     * @param {number} [maxWeight]
     * @param {string} [filterColumn]
     * @param {number} [designEffect]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configUpdateWeightingConfigUuidPut(
      uuid: string,
      pollId: PollId,
      name?: string,
      minWeight?: number,
      maxWeight?: number,
      filterColumn?: string,
      designEffect?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WeightingConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.configUpdateWeightingConfigUuidPut(
          uuid,
          pollId,
          name,
          minWeight,
          maxWeight,
          filterColumn,
          designEffect,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * List the weighting configs associated with a poll.
     * @summary Configs Map
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configsMapWeightingConfigsPut(
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: WeightingConfig }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.configsMapWeightingConfigsPut(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Filter Get
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterGetWeightingFilterGet(
      pollId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FilterConfigResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.filterGetWeightingFilterGet(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Filter Post
     * @param {string} pollId
     * @param {FilterConfig} filterConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterPostWeightingFilterPut(
      pollId: string,
      filterConfig: FilterConfig,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FilterConfigResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.filterPostWeightingFilterPut(
          pollId,
          filterConfig,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns a list of SM questions and their answers if possible
     * @summary Filter Questions Get
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterQuestionsGetWeightingFilterQuestionsGet(
      pollId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<QuestionAndAnswers>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.filterQuestionsGetWeightingFilterQuestionsGet(
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Given a list of column names as query parameters, create a target_group
     * @summary Target Group Add
     * @param {string} uuid
     * @param {string} name
     * @param {PollId} pollId
     * @param {string} [dncNames]
     * @param {string} [dncOutCols]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async targetGroupAddWeightingConfigUuidNewTargetGroupPut(
      uuid: string,
      name: string,
      pollId: PollId,
      dncNames?: string,
      dncOutCols?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WeightingConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.targetGroupAddWeightingConfigUuidNewTargetGroupPut(
          uuid,
          name,
          pollId,
          dncNames,
          dncOutCols,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Target Group Delete
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete(
      uuid: string,
      targetGroupUuid: string,
      pollId: PollId,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WeightingConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete(
          uuid,
          targetGroupUuid,
          pollId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Target Group Update
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {PollId} pollId
     * @param {string} [name]
     * @param {boolean} [enabled]
     * @param {number} [relativeImportance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut(
      uuid: string,
      targetGroupUuid: string,
      pollId: PollId,
      name?: string,
      enabled?: boolean,
      relativeImportance?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WeightingConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut(
          uuid,
          targetGroupUuid,
          pollId,
          name,
          enabled,
          relativeImportance,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Target Subgroup Update
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {string} targetSubgroupUuid
     * @param {PollId} pollId
     * @param {number} [targetValue]
     * @param {boolean} [include]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut(
      uuid: string,
      targetGroupUuid: string,
      targetSubgroupUuid: string,
      pollId: PollId,
      targetValue?: number,
      include?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WeightingConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut(
          uuid,
          targetGroupUuid,
          targetSubgroupUuid,
          pollId,
          targetValue,
          include,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Weighting Report
     * @param {string} pollId
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async weightingReportWeightingReportPollIdConfigIdGet(
      pollId: string,
      configId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WeightingReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.weightingReportWeightingReportPollIdConfigIdGet(
          pollId,
          configId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * WeightingApi - factory interface
 * @export
 */
export const WeightingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WeightingApiFp(configuration);
  return {
    /**
     *
     * @summary Config Create New
     * @param {string} name
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configCreateNewWeightingConfigPut(
      name: string,
      pollId: PollId,
      options?: any,
    ): AxiosPromise<WeightingConfigResponse> {
      return localVarFp
        .configCreateNewWeightingConfigPut(name, pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Config Delete
     * @param {string} uuid
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configDeleteWeightingConfigUuidDelete(
      uuid: string,
      pollId: PollId,
      options?: any,
    ): AxiosPromise<{ [key: string]: string }> {
      return localVarFp
        .configDeleteWeightingConfigUuidDelete(uuid, pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Config Update
     * @param {string} uuid
     * @param {PollId} pollId
     * @param {string} [name]
     * @param {number} [minWeight]
     * @param {number} [maxWeight]
     * @param {string} [filterColumn]
     * @param {number} [designEffect]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configUpdateWeightingConfigUuidPut(
      uuid: string,
      pollId: PollId,
      name?: string,
      minWeight?: number,
      maxWeight?: number,
      filterColumn?: string,
      designEffect?: number,
      options?: any,
    ): AxiosPromise<WeightingConfig> {
      return localVarFp
        .configUpdateWeightingConfigUuidPut(
          uuid,
          pollId,
          name,
          minWeight,
          maxWeight,
          filterColumn,
          designEffect,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * List the weighting configs associated with a poll.
     * @summary Configs Map
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configsMapWeightingConfigsPut(
      pollId: PollId,
      options?: any,
    ): AxiosPromise<{ [key: string]: WeightingConfig }> {
      return localVarFp
        .configsMapWeightingConfigsPut(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Filter Get
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterGetWeightingFilterGet(
      pollId: string,
      options?: any,
    ): AxiosPromise<FilterConfigResponse> {
      return localVarFp
        .filterGetWeightingFilterGet(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Filter Post
     * @param {string} pollId
     * @param {FilterConfig} filterConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterPostWeightingFilterPut(
      pollId: string,
      filterConfig: FilterConfig,
      options?: any,
    ): AxiosPromise<FilterConfigResponse> {
      return localVarFp
        .filterPostWeightingFilterPut(pollId, filterConfig, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of SM questions and their answers if possible
     * @summary Filter Questions Get
     * @param {string} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterQuestionsGetWeightingFilterQuestionsGet(
      pollId: string,
      options?: any,
    ): AxiosPromise<Array<QuestionAndAnswers>> {
      return localVarFp
        .filterQuestionsGetWeightingFilterQuestionsGet(pollId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Given a list of column names as query parameters, create a target_group
     * @summary Target Group Add
     * @param {string} uuid
     * @param {string} name
     * @param {PollId} pollId
     * @param {string} [dncNames]
     * @param {string} [dncOutCols]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    targetGroupAddWeightingConfigUuidNewTargetGroupPut(
      uuid: string,
      name: string,
      pollId: PollId,
      dncNames?: string,
      dncOutCols?: string,
      options?: any,
    ): AxiosPromise<WeightingConfig> {
      return localVarFp
        .targetGroupAddWeightingConfigUuidNewTargetGroupPut(
          uuid,
          name,
          pollId,
          dncNames,
          dncOutCols,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Target Group Delete
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {PollId} pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete(
      uuid: string,
      targetGroupUuid: string,
      pollId: PollId,
      options?: any,
    ): AxiosPromise<WeightingConfig> {
      return localVarFp
        .targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete(
          uuid,
          targetGroupUuid,
          pollId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Target Group Update
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {PollId} pollId
     * @param {string} [name]
     * @param {boolean} [enabled]
     * @param {number} [relativeImportance]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut(
      uuid: string,
      targetGroupUuid: string,
      pollId: PollId,
      name?: string,
      enabled?: boolean,
      relativeImportance?: number,
      options?: any,
    ): AxiosPromise<WeightingConfig> {
      return localVarFp
        .targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut(
          uuid,
          targetGroupUuid,
          pollId,
          name,
          enabled,
          relativeImportance,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Target Subgroup Update
     * @param {string} uuid
     * @param {string} targetGroupUuid
     * @param {string} targetSubgroupUuid
     * @param {PollId} pollId
     * @param {number} [targetValue]
     * @param {boolean} [include]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut(
      uuid: string,
      targetGroupUuid: string,
      targetSubgroupUuid: string,
      pollId: PollId,
      targetValue?: number,
      include?: boolean,
      options?: any,
    ): AxiosPromise<WeightingConfig> {
      return localVarFp
        .targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut(
          uuid,
          targetGroupUuid,
          targetSubgroupUuid,
          pollId,
          targetValue,
          include,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Weighting Report
     * @param {string} pollId
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    weightingReportWeightingReportPollIdConfigIdGet(
      pollId: string,
      configId: string,
      options?: any,
    ): AxiosPromise<WeightingReport> {
      return localVarFp
        .weightingReportWeightingReportPollIdConfigIdGet(
          pollId,
          configId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WeightingApi - object-oriented interface
 * @export
 * @class WeightingApi
 * @extends {BaseAPI}
 */
export class WeightingApi extends BaseAPI {
  /**
   *
   * @summary Config Create New
   * @param {string} name
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public configCreateNewWeightingConfigPut(
    name: string,
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .configCreateNewWeightingConfigPut(name, pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Config Delete
   * @param {string} uuid
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public configDeleteWeightingConfigUuidDelete(
    uuid: string,
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .configDeleteWeightingConfigUuidDelete(uuid, pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Config Update
   * @param {string} uuid
   * @param {PollId} pollId
   * @param {string} [name]
   * @param {number} [minWeight]
   * @param {number} [maxWeight]
   * @param {string} [filterColumn]
   * @param {number} [designEffect]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public configUpdateWeightingConfigUuidPut(
    uuid: string,
    pollId: PollId,
    name?: string,
    minWeight?: number,
    maxWeight?: number,
    filterColumn?: string,
    designEffect?: number,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .configUpdateWeightingConfigUuidPut(
        uuid,
        pollId,
        name,
        minWeight,
        maxWeight,
        filterColumn,
        designEffect,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List the weighting configs associated with a poll.
   * @summary Configs Map
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public configsMapWeightingConfigsPut(
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .configsMapWeightingConfigsPut(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filter Get
   * @param {string} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public filterGetWeightingFilterGet(
    pollId: string,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .filterGetWeightingFilterGet(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filter Post
   * @param {string} pollId
   * @param {FilterConfig} filterConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public filterPostWeightingFilterPut(
    pollId: string,
    filterConfig: FilterConfig,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .filterPostWeightingFilterPut(pollId, filterConfig, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of SM questions and their answers if possible
   * @summary Filter Questions Get
   * @param {string} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public filterQuestionsGetWeightingFilterQuestionsGet(
    pollId: string,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .filterQuestionsGetWeightingFilterQuestionsGet(pollId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Given a list of column names as query parameters, create a target_group
   * @summary Target Group Add
   * @param {string} uuid
   * @param {string} name
   * @param {PollId} pollId
   * @param {string} [dncNames]
   * @param {string} [dncOutCols]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public targetGroupAddWeightingConfigUuidNewTargetGroupPut(
    uuid: string,
    name: string,
    pollId: PollId,
    dncNames?: string,
    dncOutCols?: string,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .targetGroupAddWeightingConfigUuidNewTargetGroupPut(
        uuid,
        name,
        pollId,
        dncNames,
        dncOutCols,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Target Group Delete
   * @param {string} uuid
   * @param {string} targetGroupUuid
   * @param {PollId} pollId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete(
    uuid: string,
    targetGroupUuid: string,
    pollId: PollId,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .targetGroupDeleteWeightingConfigUuidTargetGroupTargetGroupUuidDelete(
        uuid,
        targetGroupUuid,
        pollId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Target Group Update
   * @param {string} uuid
   * @param {string} targetGroupUuid
   * @param {PollId} pollId
   * @param {string} [name]
   * @param {boolean} [enabled]
   * @param {number} [relativeImportance]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut(
    uuid: string,
    targetGroupUuid: string,
    pollId: PollId,
    name?: string,
    enabled?: boolean,
    relativeImportance?: number,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .targetGroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidPut(
        uuid,
        targetGroupUuid,
        pollId,
        name,
        enabled,
        relativeImportance,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Target Subgroup Update
   * @param {string} uuid
   * @param {string} targetGroupUuid
   * @param {string} targetSubgroupUuid
   * @param {PollId} pollId
   * @param {number} [targetValue]
   * @param {boolean} [include]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut(
    uuid: string,
    targetGroupUuid: string,
    targetSubgroupUuid: string,
    pollId: PollId,
    targetValue?: number,
    include?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .targetSubgroupUpdateWeightingConfigUuidTargetGroupTargetGroupUuidSubgroupTargetSubgroupUuidPut(
        uuid,
        targetGroupUuid,
        targetSubgroupUuid,
        pollId,
        targetValue,
        include,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Weighting Report
   * @param {string} pollId
   * @param {string} configId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WeightingApi
   */
  public weightingReportWeightingReportPollIdConfigIdGet(
    pollId: string,
    configId: string,
    options?: AxiosRequestConfig,
  ) {
    return WeightingApiFp(this.configuration)
      .weightingReportWeightingReportPollIdConfigIdGet(
        pollId,
        configId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
