import {
  PricingQuoteRequest,
  ClientType,
  BusinessUnit,
  UniverseType,
  OverSampleType,
  DiscountType,
  MessageTestingType,
  FirewallSide,
  District,
  PricingQuoteResponse,
  DistrictDemoData,
  QuoteGet,
} from './lib/pricing-tool-api';

export enum ReduxAction {
  UpdateClientType = 'UpdateClientType',
  UpdateBusinessUnit = 'UpdateBusinessUnit',
  UpdateUniverseCount = 'UpdateUniverseCount',
  UpdateUniverseTypes = 'UpdateUniverseTypes',
  UpdateOversampleType = 'UpdateOversampleType',
  UpdateSpecialDemoFilterPercentage = 'UpdateSpecialDemoFilterPercentage',
  UpdateDiscountType = 'UpdateDiscountType',
  UpdateIsAsap = 'UpdateIsAsap',
  UpdateManualMarkup = 'UpdateAdditionalMarkup',
  UpdateAdditionalFees = 'UpdateAdditionalFees',
  UpdateMessageTestingType = 'UpdateMessageTestingType',
  UpdateDealID = 'UpdateDealID',
  UpdateDealName = 'UpdateDealName',
  UpdateDistricts = 'UpdateDistricts',
  UpdateClientName = 'UpdateClientName',
  UpdateFirewallSide = 'UpdateFirewallSide',
  UpdateMemo = 'UpdateMemo',
  UpdateIsSaveable = 'UpdateIsSaveable',
  UpdateNumberOfQuestions = 'UpdateNumberOfQuestions',
  UpdateSampleSizes = 'UpdateSampleSizes',
  UpdateLastPriceResponse = 'UpdateLastPriceResponse',
  UpdateOtherUniverseDescription = 'UpdateOtherUniverseDescription',
  UpdateSelectedDemoDistricts = 'UpdateSelectedDemoDistricts',
  UpdateSelectedQuote = 'UpdateSelectedQuote',
}

export type Action =
  | { type: ReduxAction.UpdateClientType; clientType: ClientType }
  | { type: ReduxAction.UpdateBusinessUnit; businessUnit: BusinessUnit }
  | { type: ReduxAction.UpdateUniverseCount; universeCount: number }
  | { type: ReduxAction.UpdateUniverseTypes; universeTypes: UniverseType[] }
  | { type: ReduxAction.UpdateOversampleType; oversampleType: OverSampleType }
  | { type: ReduxAction.UpdateSpecialDemoFilterPercentage; percentage: number }
  | { type: ReduxAction.UpdateDiscountType; discountType: DiscountType }
  | { type: ReduxAction.UpdateIsAsap; isAsap: boolean }
  | { type: ReduxAction.UpdateManualMarkup; manualMarkup: number }
  | { type: ReduxAction.UpdateAdditionalFees; additionalFees: number }
  | {
      type: ReduxAction.UpdateMessageTestingType;
      messageTestingType: MessageTestingType;
    }
  | { type: ReduxAction.UpdateDealID; dealId: string }
  | { type: ReduxAction.UpdateDealName; dealName: string }
  | { type: ReduxAction.UpdateClientName; clientName: string }
  | { type: ReduxAction.UpdateFirewallSide; firewallSide: FirewallSide }
  | { type: ReduxAction.UpdateMemo; memo: string }
  | { type: ReduxAction.UpdateIsSaveable; isSaveable: boolean }
  | { type: ReduxAction.UpdateDistricts; districts: Array<District> }
  | { type: ReduxAction.UpdateNumberOfQuestions; numberOfQuestions: number[] }
  | { type: ReduxAction.UpdateSampleSizes; sampleSizes: number[] }
  | {
      type: ReduxAction.UpdateLastPriceResponse;
      lastPriceResponse: PricingQuoteResponse;
    }
  | {
      type: ReduxAction.UpdateOtherUniverseDescription;
      otherUniverseDescription: string;
    }
  | {
      type: ReduxAction.UpdateSelectedDemoDistricts;
      demoData: Array<DistrictDemoData>;
    }
  | { type: ReduxAction.UpdateSelectedQuote; selectedQuote: QuoteGet };

export interface DealInfo {
  zendesk_deal_id: string;
  client_name: string;
  deal_name: string;
  firewall_side: FirewallSide;
  memo: string;
}

export const initialState = {
  pricingQuote: {} as PricingQuoteRequest,
  dealInfo: {} as DealInfo,
  isSaveable: false,
  lastPriceResponse: {} as PricingQuoteResponse,
  selectedDemoDistricts: [] as Array<DistrictDemoData>,
  selectedQuote: {} as QuoteGet,
};

/* eslint-disable-next-line  @typescript-eslint/default-param-last */
export default function appReducer(state = initialState, action: Action) {
  switch (action.type) {
    case ReduxAction.UpdateClientType:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          client_type: action.clientType,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateBusinessUnit:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          business_unit: action.businessUnit,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateUniverseCount:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          num_of_universes: action.universeCount,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateUniverseTypes:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          universe_types: action.universeTypes,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateOversampleType:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          oversample_type: action.oversampleType,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateSpecialDemoFilterPercentage:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          special_demo_filter_pct: action.percentage,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateDiscountType:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          discount: action.discountType,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateIsAsap:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          asap: action.isAsap,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateMessageTestingType:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          message_testing: action.messageTestingType,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateManualMarkup:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          manual_markup: action.manualMarkup,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateAdditionalFees:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          additional_fees: action.additionalFees,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateDealID:
      return {
        ...state,
        dealInfo: {
          ...state.dealInfo,
          zendesk_deal_id: action.dealId,
        } as DealInfo,
      };
    case ReduxAction.UpdateDealName:
      return {
        ...state,
        dealInfo: {
          ...state.dealInfo,
          deal_name: action.dealName,
        } as DealInfo,
      };
    case ReduxAction.UpdateClientName:
      return {
        ...state,
        dealInfo: {
          ...state.dealInfo,
          client_name: action.clientName,
        } as DealInfo,
      };
    case ReduxAction.UpdateFirewallSide:
      return {
        ...state,
        dealInfo: {
          ...state.dealInfo,
          firewall_side: action.firewallSide,
        } as DealInfo,
      };
    case ReduxAction.UpdateMemo:
      return {
        ...state,
        dealInfo: {
          ...state.dealInfo,
          memo: action.memo,
        } as DealInfo,
      };
    case ReduxAction.UpdateIsSaveable:
      return {
        ...state,
        isSaveable: action.isSaveable,
      };
    case ReduxAction.UpdateDistricts:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          districts: action.districts,
        },
      };
    case ReduxAction.UpdateSampleSizes:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          sample_sizes: action.sampleSizes,
        },
      };
    case ReduxAction.UpdateNumberOfQuestions:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          number_of_questions: action.numberOfQuestions,
        },
      };
    case ReduxAction.UpdateLastPriceResponse:
      return {
        ...state,
        lastPriceResponse: action.lastPriceResponse,
      };
    case ReduxAction.UpdateOtherUniverseDescription:
      return {
        ...state,
        pricingQuote: {
          ...state.pricingQuote,
          other_universe_type_description: action.otherUniverseDescription,
        } as PricingQuoteRequest,
      };
    case ReduxAction.UpdateSelectedDemoDistricts:
      return {
        ...state,
        selectedDemoDistricts: action.demoData,
      };
    case ReduxAction.UpdateSelectedQuote:
      return {
        ...state,
        selectedQuote: action.selectedQuote,
      };
    default:
      return state;
  }
}
