import React from 'react';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  Tooltip,
  Legend,
  Title,
  LinearScale,
  ArcElement,
} from 'chart.js';

import { Radar } from 'react-chartjs-2';
import { ChangeColors } from '../../theme';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  Tooltip,
  Legend,
  Title,
  LinearScale,
  ArcElement,
);

const RADAR_DARK_COLOR = 'rgba(242, 108, 79, 0.2)';
const RADAR_LIGHT_COLOR = ChangeColors.Orange;
const TARGET_AXIS_COLOR = ChangeColors.BrightBlue;
const OTHER_AXIS_COLOR = ChangeColors.LightGray;

const TARGET_AXIS_WIDTH = 3;
const OTHER_AXIS_WIDTH = 1;

const DEFAULT_RADAR_CHART_OPTIONS = {
  scales: {
    r: {
      suggestedMax: 1.2,
      suggestedMin: 0.1,
      ticks: {
        // callback: (as_str: string) : string =>  {
        //   const asPercentage = parseFloat(as_str) * 100;
        //   return String(asPercentage);
        // },
      },
      grid: {
        color: (context: any) => {
          // This is goofy to get only a single blue line at 1.0
          if (
            context.tick.value >= 1.1 ||
            (context.tick.value >= 0.95 && context.tick.value < 1)
          ) {
            return OTHER_AXIS_COLOR;
          }
          const rounded = Math.round(context.tick.value * 10) / 10;
          if (rounded === 1.0) {
            return TARGET_AXIS_COLOR;
          }
          return OTHER_AXIS_COLOR;
        },
        lineWidth: (context: any) => {
          // This is goofy to get only a single blue line at 1.0
          if (
            context.tick.value >= 1.1 ||
            (context.tick.value >= 0.95 && context.tick.value < 1)
          ) {
            return OTHER_AXIS_WIDTH;
          }
          const rounded = Math.round(context.tick.value * 10) / 10;
          if (rounded === 1.0) {
            return TARGET_AXIS_WIDTH;
          }
          return OTHER_AXIS_WIDTH;
        },
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const asPercentage = context.raw * 100;
          const currentCount = 0;
          const targetTotal = 1;
          // with 2 decimal places of precision
          return `${asPercentage.toFixed(2)}% (${currentCount}/${targetTotal})`;
        },
      },
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
};

export interface SummaryChartDatum {
  subsetLabel: string;
  targetCount: number;
  actualCount: number;
}
interface SummaryChartProps {
  data: SummaryChartDatum[];
}
function SummaryChart({ data }: SummaryChartProps) {
  let checkedData: SummaryChartDatum[];
  if (data.length === 1) {
    checkedData = [...data, ...data, ...data];
  } else {
    checkedData = data.length === 2 ? [...data, ...data] : data;
  }

  const processedData = {
    labels: checkedData.map((scd: SummaryChartDatum) => scd.subsetLabel),
    datasets: [
      {
        // label: "CATEGORY % of target %",
        data: checkedData.map(
          (scd: SummaryChartDatum) => scd.actualCount / scd.targetCount,
        ),
        backgroundColor: RADAR_DARK_COLOR,
        borderColor: RADAR_LIGHT_COLOR,
        borderWidth: 1,
      },
    ],
  };

  const tooltips = new Map(
    checkedData.map((d: SummaryChartDatum) => {
      const percent = ((d.actualCount * 100) / d.targetCount).toFixed(2);
      return [
        d.subsetLabel,
        `${percent}% (${d.actualCount} / ${d.targetCount})`,
      ];
    }),
  );

  const options = {
    ...DEFAULT_RADAR_CHART_OPTIONS,
    ...{
      plugins: {
        ...DEFAULT_RADAR_CHART_OPTIONS.plugins,
        tooltip: {
          callbacks: {
            label: (context: any) => tooltips.get(context.label) || 'missing',
          },
        },
      },
    },
  };

  return <Radar data={processedData} options={options} />;
}

export default SummaryChart;
