import React, { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import ProvidedServices from './ProvidedServices';
import Contextualizer from './Contextualizer';
import {
  Configuration,
  District,
  DistrictDemoData,
  DistrictQuery,
  DistrictType,
  GeoservicesApi,
  State,
} from '../pricing-tool-api';

const GeoServiceContext = Contextualizer.createContext(
  ProvidedServices.GeoService,
);
export interface IGeoService {
  listDemoData(
    state: State,
    districtType?: DistrictType,
    districtName?: string,
  ): Promise<AxiosResponse<Array<DistrictDemoData>>>;
  listDistricts(
    state: State,
    districtType?: DistrictType,
    districtName?: string,
  ): Promise<AxiosResponse<Array<District>>>;
  listManyDistrict(
    query: DistrictQuery[],
  ): Promise<AxiosResponse<Array<DistrictDemoData>>>;
}

export const useGeoService = () =>
  Contextualizer.use<IGeoService>(ProvidedServices.GeoService);

type GeoServiceProps = { children: React.ReactNode };

function GeoService({ children }: GeoServiceProps) {
  const { getAccessTokenSilently } = useAuth0();
  const basePath = `${window.location.origin}/api`;

  const geoService = {
    async listDemoData(
      state: State,
      districtType?: DistrictType,
      districtName?: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<Array<DistrictDemoData>>> {
      const tokenValue = await token;
      return new GeoservicesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).listSingleStateDemoDataGeoDemoStateGet(
        state,
        districtType,
        districtName,
      );
    },

    async listDistricts(
      state: State,
      districtType?: DistrictType,
      districtName?: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<Array<District>>> {
      const tokenValue = await token;
      return new GeoservicesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).listDistrictsGeoDistrictsStateGet(state, districtType, districtName);
    },

    async listManyDistrict(
      query: DistrictQuery[],
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<Array<DistrictDemoData>>> {
      const tokenValue = await token;
      return new GeoservicesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).listDemoDataGeoDemoPost(query);
    },
  };

  return (
    <GeoServiceContext.Provider value={useMemo(() => geoService, [])}>
      {children}
    </GeoServiceContext.Provider>
  );
}

export default GeoService;
