import React, { useMemo } from 'react';

import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  Tooltip,
  Legend,
  Title,
  LinearScale,
  ArcElement,
  ChartData,
  Point,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  HistoricalSegmentSnapshotType,
  SegmentCategorySnapshotType,
} from '../../lib/polling-graphql/types';
import { ChangeCatColors } from '../../theme';
import { SummaryChartDatum } from './SummaryChart';

ChartJS.register(
  CategoryScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  LinearScale,
  Legend,
  Title,
  ArcElement,
);

interface FieldingTimeseriesProps {
  data: Array<HistoricalSegmentSnapshotType>;
  summary: SummaryChartDatum[];
}

function csum(data: Array<number>): Array<number> {
  // Calculate the cumumlative sum of an array
  // after https://stackoverflow.com/a/20477330
  return data.reduce((r: Array<number>, a: number) => {
    r.push(((r.length && r[r.length - 1]) || 0) + a);
    return r;
  }, []);
}

const DEFAULT_LINE_OPTIONS = {
  scales: {
    // x: {
    //     type: 'time',  // can have nice time with plugins https://www.chartjs.org/docs/latest/axes/cartesian/time.html
    //     time: {
    //         unit: 'day'
    //     }
    // },
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: (value) => `${value}%`,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom' as const,
    },
  },
} as ChartOptions<'line'>;

const COLOR_LIST: Array<string> = [
  ChangeCatColors.DarkGray,
  ChangeCatColors.BrightBlue,
  ChangeCatColors.BrightOrange,
  ChangeCatColors.BrightYellow,
  ChangeCatColors.BrightGreen,
  ChangeCatColors.BrightPurple,
  ChangeCatColors.BrightTeal,
];

function generateDataset(
  data: Array<HistoricalSegmentSnapshotType>,
  summary: SummaryChartDatum[],
): ChartData<'line', (number | Point | null)[], string> {
  const datasets: Array<object> = [];
  const categoryMap: Map<string, Array<number>> = new Map();

  for (let i = 0; i < data.length; i += 1) {
    data[i].categoryData.forEach((x: SegmentCategorySnapshotType) => {
      const list = categoryMap.get(x.categoryName);
      if (list === undefined) {
        categoryMap.set(x.categoryName, [x.count]);
      } else {
        list.push(x.count);
      }
    });
  }

  const countMap: Map<string, number> = new Map();
  summary.forEach((scd: SummaryChartDatum) => {
    countMap.set(scd.subsetLabel, scd.targetCount);
  });

  let colIdx = 0;
  categoryMap.forEach((value, key) => {
    datasets.push({
      data: csum(value).map((x) => (100 * x) / (countMap.get(key) || 0)),
      label: key,
      borderColor: COLOR_LIST[colIdx],
      backgroundColor: COLOR_LIST[colIdx],
    });
    colIdx += 1;
    colIdx %= COLOR_LIST.length;
  });

  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'US/Eastern',
    month: 'numeric',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
  };

  const labels = data.map((x) =>
    new Date(x.datetime).toLocaleDateString('en-US', options),
  );

  return {
    labels,
    datasets,
  } as ChartData<'line', (number | Point | null)[], string>;
}
function FieldingTimeseries({ data, summary }: FieldingTimeseriesProps) {
  const dataset = useMemo(
    () => generateDataset(data, summary),
    [data, summary],
  );

  return <Line data={dataset} options={DEFAULT_LINE_OPTIONS} />;
}

export default FieldingTimeseries;
