import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Radio,
  RadioGroup,
  Textarea,
  VStack,
  Modal,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';

import Choice from '../Choice';
import {
  FirewallSide,
  QuoteCreate,
  QuoteGet,
  QuoteUpdate,
} from '../../lib/pricing-tool-api';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ReduxAction } from '../../reducer';
import { useQuoteService } from '../../lib/services/QuoteService';

const NAMING_CONVENTIONS_LINK =
  'http://docs.crpolls.com/reference/poll_naming_conventions.html';

const QUOTE_SAVED_MODAL_TITLE = 'Quote Saved';

const FIREWALL_SIDES = [
  {
    choiceText: 'Candidate',
    value: FirewallSide.Candidate,
    isDefault: true,
  },
  {
    choiceText: 'Exempt',
    value: FirewallSide.Exempt,
    isDefault: false,
  },
  {
    choiceText: 'Independent Expenditure',
    value: FirewallSide.IndependentExpenditure,
    isDefault: false,
  },
];

export interface SaveQuoteSectionProps {
  quoteId: string | null;
}

function SaveQuoteSection(props: SaveQuoteSectionProps = { quoteId: null }) {
  const { quoteId } = props;
  const [isSaving, setIsSaving] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [savedQuote, setSavedQuote] = React.useState<QuoteGet | null>(null);
  const [modalHeader, setModalHeader] = React.useState(QUOTE_SAVED_MODAL_TITLE);
  const [dealId, setDealId] = React.useState('');
  const [dealName, setDealName] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [firewallSide, setFirewallSide] = React.useState(
    FirewallSide.Candidate as string,
  );
  const [memo, setMemo] = React.useState('');
  const [quoteErrorMessage, setQuoteErrorMessage] = React.useState('');
  const lastPriceResponse = useAppSelector((state) => state.lastPriceResponse);
  const dealInfo = useAppSelector((state) => state.dealInfo);
  const selectedDemoDistrictsFromRedux = useAppSelector(
    (state) => state.selectedDemoDistricts,
  );
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const quoteFromRedux = useAppSelector((state) => state.selectedQuote);

  // only on first render load the saved quote
  useEffect(() => {
    if (quoteId && quoteFromRedux && quoteFromRedux.id === quoteId) {
      setDealId(quoteFromRedux.zendesk_deal_id);
      setDealName(quoteFromRedux.deal_name);
      setClientName(quoteFromRedux.client_name);
      setFirewallSide(quoteFromRedux.firewall_side);
      if (quoteFromRedux.memo) {
        setMemo(quoteFromRedux.memo);
      }
    } else if (quoteId && (!quoteFromRedux || quoteFromRedux.id !== quoteId)) {
      throw new Error('Should have loaded the quote from api into redux');
    }
  }, []);

  useEffect(() => {
    dispatch({ type: ReduxAction.UpdateDealID, dealId });
  }, [dispatch, dealId]);

  useEffect(() => {
    dispatch({ type: ReduxAction.UpdateDealName, dealName });
  }, [dispatch, dealName]);

  useEffect(() => {
    dispatch({ type: ReduxAction.UpdateClientName, clientName });
  }, [dispatch, clientName]);

  useEffect(() => {
    dispatch({ type: ReduxAction.UpdateFirewallSide, firewallSide });
  }, [dispatch, firewallSide]);

  useEffect(() => {
    dispatch({ type: ReduxAction.UpdateMemo, memo });
  }, [dispatch, memo]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateSelectedQuote,
      selectedQuote: savedQuote,
    });
  }, [dispatch, savedQuote]);

  const quoteService = useQuoteService();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const saveQuote = async () => {
    setIsSaving(true);
    try {
      if (user == null) {
        throw new Error('You must be logged in.');
      }
      const request = {
        price_info: lastPriceResponse,
        user_email: user.email,
        ...dealInfo,
      };

      const response = quoteId
        ? await quoteService.updateQuote(quoteId, request as QuoteUpdate)
        : await quoteService.createQuote(request as QuoteCreate);

      const quoteData = response.data;
      // inject the demo data into the quote before saving to redux
      if (
        _.isEqual(
          quoteData.price_info.request.districts,
          _.map(selectedDemoDistrictsFromRedux, (demo) => demo.district),
        )
      ) {
        quoteData.district_demo_data = selectedDemoDistrictsFromRedux;
      }
      setSavedQuote(response.data);
      setModalHeader(QUOTE_SAVED_MODAL_TITLE);
      setQuoteErrorMessage('');
    } catch (e) {
      let msg = '';
      if (typeof e === 'string') {
        msg = e;
      } else if (e instanceof AxiosError) {
        msg = JSON.stringify(e.response?.data);
      } else if (e instanceof Error) {
        msg = e.message;
      }
      setModalHeader('Error Saving Quote');
      setQuoteErrorMessage(msg);
    }
    onOpen();
    setIsSaving(false);
  };

  const navigate = useNavigate();

  const onModalClose = () => {
    onClose();
    if (quoteErrorMessage === '' && savedQuote != null) {
      navigate(`/pricing/quote/${savedQuote.id}`, {
        replace: true,
      });
    }
  };

  return (
    <VStack flexDir="column" align="start" spacing="2%">
      <FormControl isRequired>
        <FormLabel>Deal ID</FormLabel>
        <Input value={dealId} onChange={(e) => setDealId(e.target.value)} />
      </FormControl>

      <FormControl textAlign="start" isRequired>
        <FormLabel>Deal Name</FormLabel>
        <Input value={dealName} onChange={(e) => setDealName(e.target.value)} />

        <FormHelperText>
          The short readable name for the deal following the{' '}
          <Link href={NAMING_CONVENTIONS_LINK} color="brand.linkColor">
            naming convention.
          </Link>
        </FormHelperText>

        <FormHelperText color="red.500">
          eg. _dd[_##]_client_2022_06[_##][_suffix]
        </FormHelperText>
      </FormControl>

      <FormControl textAlign="start" isRequired>
        <FormLabel>Client Name</FormLabel>
        <Input
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <FormHelperText>The client that the poll is for.</FormHelperText>
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Firewall Side</FormLabel>
        <RadioGroup value={firewallSide} onChange={setFirewallSide}>
          <VStack flexDir="column" align="start">
            {FIREWALL_SIDES.map(({ choiceText, value }: Choice) => (
              <Radio value={value} key={`firewall-side-${value}`}>
                {choiceText}
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Memo</FormLabel>
        <Textarea value={memo} onChange={(e) => setMemo(e.target.value)} />
      </FormControl>

      <ButtonGroup>
        <Tooltip
          hasArrow
          label="All required fields should be filled.
          All provided data should be valid.
          Please check the form for any errors."
          shouldWrapChildren
          mt="3"
          isDisabled={useAppSelector((state) => state.isSaveable)}
        >
          <Button
            isLoading={isSaving}
            isDisabled={!useAppSelector((state) => state.isSaveable)}
            colorScheme="blue"
            onClick={() => saveQuote()}
          >
            Save Quote
          </Button>
        </Tooltip>
        <Button
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
            if (!quoteId) {
              navigate('/pricing/quote');
            } else {
              navigate(`/pricing/quote/${quoteId}`);
            }
          }}
        >
          {!quoteId ? 'Reset Form' : 'Cancel'}
        </Button>
      </ButtonGroup>
      <Text fontSize="sm">
        The Save Quote button only saves the last successfully requested prices.
      </Text>

      <Modal isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{quoteErrorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="brand.miscUIColor" onClick={onModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}

export default SaveQuoteSection;
