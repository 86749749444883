import React from 'react';
import { Heading, Grid, GridItem, Card, CardHeader } from '@chakra-ui/react';

import SummaryChart, { SummaryChartDatum } from './SummaryChart';
import SegmentTable, { SummaryTableRow } from './FieldingTable';
import {
  SegmentCategoryDatumType,
  TargetSegmentSummaryType,
} from '../../lib/polling-graphql/types';
import FieldingTimeseries from './FieldingTimeseries';

interface FieldingCategoryRowProps {
  data: TargetSegmentSummaryType;
}

function FieldingCategoryRow({ data }: FieldingCategoryRowProps) {
  const radarData: SummaryChartDatum[] = data.categoryData.map(
    (value: SegmentCategoryDatumType) =>
      ({
        subsetLabel: value.categoryName,
        targetCount: value.targetCount,
        actualCount: value.approxCount,
      } as SummaryChartDatum),
  );

  const tableData: SummaryTableRow[] = data.categoryData.map(
    (value: SegmentCategoryDatumType) =>
      ({
        option: value.categoryName,
        targetPercentage: value.targetPercent,
        targetN: value.targetCount,
        currentPercentage: value.currentPercentOfTotal,
        currentN: value.approxCount,
        currentPercentageOfTarget: value.percentageOfFinalTarget,
      } as SummaryTableRow),
  );

  return (
    <Card
      border="1px"
      borderColor="gray.200"
      align="center"
      boxShadow="lg"
      padding={1}
    >
      <CardHeader>
        <Heading size="md">{data.segmentName}</Heading>
      </CardHeader>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        gap={3}
      >
        <GridItem w="100%">
          <SummaryChart data={radarData} />
        </GridItem>
        <GridItem w="100%">
          <SegmentTable rows={tableData} />
        </GridItem>
        <GridItem w="100%">
          <FieldingTimeseries data={data.historyLineData} summary={radarData} />
        </GridItem>
      </Grid>
    </Card>
  );
}

export default FieldingCategoryRow;
