export const workflowBaseUrl = (thisUiHostname: string) => {
  if (thisUiHostname === 'pricing.cr-polls.com') {
    return 'https://workflow.crpolls.com';
  }
  // *********
  // NOTE: comment out this if you want to test locally against staging Workflow
  if (thisUiHostname === 'localhost') {
    return 'http://localhost:8001';
  }
  // *********
  return 'https://staging-workflow.crpolls.com';
};

export const workflowPollHomeUrl = (
  thisUiHostname: string,
  pollName: string,
) => {
  const baseUrl = workflowBaseUrl(thisUiHostname);
  const url = `${baseUrl}/?mode=Existing+Poll&collection=My+Active+Polls&current_poll=${pollName}&crosstab_version=Apollo&app=Overview`;
  return url;
};
