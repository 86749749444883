import React from 'react';
import {
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/react';

import { WeightingConfigType } from '../../lib/polling-graphql/types';

interface WeightingConfigChooserProps {
  weightingConfigs: WeightingConfigType[];
  setConfig: (config: WeightingConfigType) => void;
}

function WeightingConfigChooser({
  weightingConfigs,
  setConfig,
}: WeightingConfigChooserProps) {
  const selected = weightingConfigs.find((wc) => wc.isRecruitmentConfig);
  const selectedId = selected ? selected.uuid : '';

  const isError = selectedId === undefined;
  const handleSelectConfig = (id: string) => {
    const config = weightingConfigs.find((wc) => wc.uuid === id);
    if (config) {
      setConfig(config);
    }
  };

  return (
    <FormControl isInvalid={isError}>
      <FormLabel>Recruitment configuration</FormLabel>
      <Select
        placeholder="Choose a config"
        onChange={(e) => {
          handleSelectConfig(e.target.value);
        }}
        value={selectedId}
      >
        {weightingConfigs.map((wc) => (
          <option key={wc.uuid} value={wc.uuid}>
            {wc.name}
          </option>
        ))}
      </Select>
      {isError && (
        <FormHelperText>
          Choose the weighting configuration which defines your recruitment
          targets.
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default WeightingConfigChooser;
