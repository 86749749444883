import React, { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Configuration,
  PricingApi,
  PricingQuoteRequest,
  PricingQuoteResponse,
} from '../pricing-tool-api';
import ProvidedServices from './ProvidedServices';
import Contextualizer from './Contextualizer';

export interface IPricingService {
  priceQuote(
    request: PricingQuoteRequest,
  ): Promise<AxiosResponse<PricingQuoteResponse>>;
}

const PricingServiceContext = Contextualizer.createContext(
  ProvidedServices.PricingService,
);
export const usePricingService = () =>
  Contextualizer.use<IPricingService>(ProvidedServices.PricingService);

type PricingServiceProps = { children: React.ReactNode };

function PricingService({ children }: PricingServiceProps) {
  const { getAccessTokenSilently } = useAuth0();
  const basePath = `${window.location.origin}/api`;

  const pricingService = {
    async priceQuote(
      request: PricingQuoteRequest,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<PricingQuoteResponse>> {
      const tokenValue = await token;
      return new PricingApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).priceQuotePricingPost(request);
    },
  };

  return (
    <PricingServiceContext.Provider value={useMemo(() => pricingService, [])}>
      {children}
    </PricingServiceContext.Provider>
  );
}

export default PricingService;
