import React from 'react';
import { Box, keyframes } from '@chakra-ui/react';

const BALL_POSITIONS = [
  'translate(0px, 39px)',
  'translate(21px, 22px)',
  'translate(32px, 34px)',
  'translate(20px, 58px)',
  'translate(32px, 67px)',
  'translate(65px, 13px)',
  'translate(61px, 33px)',
  'translate(57px, 46px)',
  'translate(60px, 63px)',
  'translate(84px, 36px)',
  'translate(77px, 63px)',
  'translate(101px, 34px)',
  'translate(97px, 70px)',
  'translate(109px, 59px)',
  'translate(126px, 51px)',
];
interface LoadingSpinnerProps {
  message: string | null;
}
function LoadingSpinner(props: LoadingSpinnerProps = { message: 'LOADING' }) {
  const moveToCenter = keyframes`
    0% {
      transform: translate(64px, 42px);
      opacity: 0.1;
    }
`;
  const moveAnimation = `${moveToCenter} 2s infinite`;
  const { message } = props;
  return (
    <Box transformOrigin="0px 0px" m="auto" w="150px" h="100px">
      {BALL_POSITIONS.map((pos: string) => (
        <Box
          key={pos}
          transform={pos}
          position="absolute"
          bg="#9ed6ed"
          opacity=".7"
          borderRadius="50%"
          w="25px"
          height="25px"
          animation={moveAnimation}
          sx={{ animationDirection: 'alternate' }}
        />
      ))}
      <Box
        position="absolute"
        bg="radial-gradient(ellipse 400% 200% at 12px 0px, rgba(242,108,79,1) 0%, rgba(242,108,79,0.80) 29%, rgba(242,108,79,0) 87%, rgba(242,108,79,0) 100%)"
        opacity="1"
        borderRadius="50%"
        w="25px"
        height="25px"
        transform="translate(64px,  42px)"
      />
      <Box
        position="absolute"
        color="#f26c4f"
        fontSize="20px"
        fontWeight="bold"
        bg="linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 59%, rgba(255,255,255,0) 100%)"
        opacity="1"
        w="150px"
        textAlign="center"
        transform="translate(0px,  72px)"
        textTransform="uppercase"
      >
        {message}
      </Box>
    </Box>
  );
}

export default LoadingSpinner;
