import React from 'react';
import { FiColumns, FiDollarSign } from 'react-icons/fi';

import { NavigationMenu, LinkItemProps } from '../NavigationMenu';

const PricingNavLinks: Array<LinkItemProps> = [
  { name: 'New Quote', icon: FiDollarSign, href: '/pricing/quote' },
  { name: 'List Quotes', icon: FiColumns, href: '/pricing' },
];

export default function PricingNavigationMenu({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <NavigationMenu linkItems={PricingNavLinks}>{children}</NavigationMenu>
  );
}
