/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const BusinessUnit = {
  Pna: 'pna',
  Embold: 'embold',
} as const;

export type BusinessUnit = typeof BusinessUnit[keyof typeof BusinessUnit];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ClientType = {
  Downballot: 'downballot',
  House: 'house',
  Statewide: 'statewide',
  Nationwide: 'nationwide',
  NonElectionNonGov: 'non_election_non_gov',
  Government: 'government',
  Corporate: 'corporate',
  Other: 'other',
} as const;

export type ClientType = typeof ClientType[keyof typeof ClientType];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DiscountType = {
  None: 'none',
  MultiPollDeal: 'multi_poll_deal',
  RepeatClient: 'repeat_client',
} as const;

export type DiscountType = typeof DiscountType[keyof typeof DiscountType];

/**
 *
 * @export
 * @interface District
 */
export interface District {
  /**
   *
   * @type {string}
   * @memberof District
   */
  id?: string;
  /**
   *
   * @type {DistrictType}
   * @memberof District
   */
  district_type: DistrictType;
  /**
   *
   * @type {string}
   * @memberof District
   */
  name: string;
  /**
   *
   * @type {State}
   * @memberof District
   */
  state: State;
}
/**
 *
 * @export
 * @interface DistrictDemoData
 */
export interface DistrictDemoData {
  /**
   *
   * @type {District}
   * @memberof DistrictDemoData
   */
  district: District;
  /**
   *
   * @type {number}
   * @memberof DistrictDemoData
   */
  registered_voters: number;
  /**
   *
   * @type {number}
   * @memberof DistrictDemoData
   */
  max_sample_size: number;
  /**
   *
   * @type {number}
   * @memberof DistrictDemoData
   */
  percent_white: number;
  /**
   *
   * @type {number}
   * @memberof DistrictDemoData
   */
  percent_black: number;
  /**
   *
   * @type {number}
   * @memberof DistrictDemoData
   */
  percent_hispanic: number;
  /**
   *
   * @type {number}
   * @memberof DistrictDemoData
   */
  percent_aapi: number;
}
/**
 *
 * @export
 * @interface DistrictQuery
 */
export interface DistrictQuery {
  /**
   *
   * @type {State}
   * @memberof DistrictQuery
   */
  state: State;
  /**
   *
   * @type {DistrictType}
   * @memberof DistrictQuery
   */
  district_type?: DistrictType;
  /**
   *
   * @type {string}
   * @memberof DistrictQuery
   */
  name?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DistrictType = {
  State: 'State',
  City: 'City',
  County: 'County',
  ZipCode: 'Zip Code',
  Congressional: 'Congressional',
  StateLegUpper: 'State Leg Upper',
  StateLegLower: 'State Leg Lower',
  TownDistrict: 'Town District',
  SchoolDistrict: 'School District',
  CountyCommissionerSupervisor: 'County Commissioner / Supervisor',
  CityCouncilCommissioner: 'City Council / Commissioner',
  BoardOfEdDistrict: 'Board of Ed District',
  TownWard: 'Town Ward',
  CitySchoolDistrict: 'City School District',
  SchoolSubdistrict: 'School Subdistrict',
  UnifiedSchoolDistrict: 'Unified School District',
  UnifiedSchoolSubdistrict: 'Unified School Subdistrict',
  CountyBoardOfEdSubdistrict: 'County Board of Ed Subdistrict',
  CountyBoardOfEdDistrict: 'County Board of Ed District',
  CityWard: 'City Ward',
  SchoolBoard: 'School Board',
  CountyLegislature: 'County Legislature',
  Nationwide: 'Nationwide',
  Other: 'Other',
} as const;

export type DistrictType = typeof DistrictType[keyof typeof DistrictType];

/**
 *
 * @export
 * @interface Factor
 */
export interface Factor {
  /**
   *
   * @type {FactorType}
   * @memberof Factor
   */
  factor_type: FactorType;
  /**
   *
   * @type {Array<Weight>}
   * @memberof Factor
   */
  weights: Array<Weight>;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const FactorType = {
  ClientType: 'client_type',
  MessageTestingType: 'message_testing_type',
  Universe: 'universe',
  Oversample: 'oversample',
  MultiPoll: 'multi_poll',
  RepeatClient: 'repeat_client',
  SampleSize: 'sample_size',
  NumberOfQuestions: 'number_of_questions',
  NumberOfUniverses: 'number_of_universes',
} as const;

export type FactorType = typeof FactorType[keyof typeof FactorType];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const FirewallSide = {
  Candidate: 'Candidate',
  Exempt: 'Exempt',
  IndependentExpenditure: 'Independent Expenditure',
  Above: 'Above',
  Unknown: 'Unknown',
} as const;

export type FirewallSide = typeof FirewallSide[keyof typeof FirewallSide];

/**
 * Base model for query parameter object-based filters.
 * @export
 * @interface GetQuoteFilter
 */
export interface GetQuoteFilter {
  /**
   *
   * @type {string}
   * @memberof GetQuoteFilter
   */
  deal_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetQuoteFilter
   */
  client_name?: string;
  /**
   *
   * @type {BusinessUnit}
   * @memberof GetQuoteFilter
   */
  business_unit?: BusinessUnit;
  /**
   *
   * @type {ClientType}
   * @memberof GetQuoteFilter
   */
  client_type?: ClientType;
}

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface Key
 */
export interface Key {}
/**
 *
 * @export
 * @interface ListQuotesResult
 */
export interface ListQuotesResult {
  /**
   *
   * @type {Array<any>}
   * @memberof ListQuotesResult
   */
  errors?: Array<any>;
  /**
   *
   * @type {Array<QuoteGet>}
   * @memberof ListQuotesResult
   */
  quotes?: Array<QuoteGet>;
  /**
   *
   * @type {Paging1}
   * @memberof ListQuotesResult
   */
  paging?: Paging1;
}
/**
 *
 * @export
 * @interface LocationInner
 */
export interface LocationInner {}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MessageTestingType = {
  None: 'none',
  Creative: 'creative',
  Written: 'written',
} as const;

export type MessageTestingType =
  typeof MessageTestingType[keyof typeof MessageTestingType];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const OverSampleType = {
  None: 'none',
  PrimaryVoters: 'primary_voters',
  Other: 'other',
} as const;

export type OverSampleType = typeof OverSampleType[keyof typeof OverSampleType];

/**
 *
 * @export
 * @interface Paging
 */
export interface Paging {
  /**
   *
   * @type {number}
   * @memberof Paging
   */
  next: number;
  /**
   *
   * @type {number}
   * @memberof Paging
   */
  prev: number;
  /**
   *
   * @type {number}
   * @memberof Paging
   */
  current: number;
  /**
   *
   * @type {number}
   * @memberof Paging
   */
  count: number;
}
/**
 *
 * @export
 * @interface Paging1
 */
export interface Paging1 {
  /**
   *
   * @type {number}
   * @memberof Paging1
   */
  next: number;
  /**
   *
   * @type {number}
   * @memberof Paging1
   */
  prev: number;
  /**
   *
   * @type {number}
   * @memberof Paging1
   */
  current: number;
  /**
   *
   * @type {number}
   * @memberof Paging1
   */
  count: number;
}
/**
 *
 * @export
 * @interface PricingQuotePrice
 */
export interface PricingQuotePrice {
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  number_of_questions: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  sample_size: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  recruitment_cost: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  labor_factor: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  wtp_factor: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  additional_fees: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  markup: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  baseline_labor_cost: number;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof PricingQuotePrice
   */
  factors: { [key: string]: { [key: string]: number } };
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  labor_cost: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuotePrice
   */
  asap_markup?: number;
}
/**
 *
 * @export
 * @interface PricingQuoteRequest
 */
export interface PricingQuoteRequest {
  /**
   *
   * @type {ClientType}
   * @memberof PricingQuoteRequest
   */
  client_type: ClientType;
  /**
   *
   * @type {BusinessUnit}
   * @memberof PricingQuoteRequest
   */
  business_unit: BusinessUnit;
  /**
   *
   * @type {Array<District>}
   * @memberof PricingQuoteRequest
   */
  districts: Array<District>;
  /**
   *
   * @type {number}
   * @memberof PricingQuoteRequest
   */
  num_of_universes: number;
  /**
   *
   * @type {Array<UniverseType>}
   * @memberof PricingQuoteRequest
   */
  universe_types: Array<UniverseType>;
  /**
   *
   * @type {number}
   * @memberof PricingQuoteRequest
   */
  special_demo_filter_pct?: number;
  /**
   *
   * @type {DiscountType}
   * @memberof PricingQuoteRequest
   */
  discount?: DiscountType;
  /**
   *
   * @type {boolean}
   * @memberof PricingQuoteRequest
   */
  asap?: boolean;
  /**
   *
   * @type {number}
   * @memberof PricingQuoteRequest
   */
  manual_markup?: number;
  /**
   *
   * @type {number}
   * @memberof PricingQuoteRequest
   */
  additional_fees?: number;
  /**
   *
   * @type {MessageTestingType}
   * @memberof PricingQuoteRequest
   */
  message_testing: MessageTestingType;
  /**
   *
   * @type {Array<number>}
   * @memberof PricingQuoteRequest
   */
  sample_sizes: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof PricingQuoteRequest
   */
  number_of_questions: Array<number>;
  /**
   *
   * @type {OverSampleType}
   * @memberof PricingQuoteRequest
   */
  oversample_type?: OverSampleType;
  /**
   *
   * @type {string}
   * @memberof PricingQuoteRequest
   */
  other_universe_type_description?: string;
}

/**
 *
 * @export
 * @interface PricingQuoteResponse
 */
export interface PricingQuoteResponse {
  /**
   *
   * @type {PricingQuoteRequest}
   * @memberof PricingQuoteResponse
   */
  request: PricingQuoteRequest;
  /**
   *
   * @type {Array<PricingQuotePrice>}
   * @memberof PricingQuoteResponse
   */
  prices: Array<PricingQuotePrice>;
  /**
   *
   * @type {string}
   * @memberof PricingQuoteResponse
   */
  pricing_model_version: string;
}
/**
 *
 * @export
 * @interface QuoteCreate
 */
export interface QuoteCreate {
  /**
   *
   * @type {PricingQuoteResponse}
   * @memberof QuoteCreate
   */
  price_info: PricingQuoteResponse;
  /**
   *
   * @type {string}
   * @memberof QuoteCreate
   */
  client_name: string;
  /**
   *
   * @type {string}
   * @memberof QuoteCreate
   */
  deal_name: string;
  /**
   *
   * @type {FirewallSide}
   * @memberof QuoteCreate
   */
  firewall_side: FirewallSide;
  /**
   *
   * @type {string}
   * @memberof QuoteCreate
   */
  zendesk_deal_id: string;
  /**
   *
   * @type {string}
   * @memberof QuoteCreate
   */
  user_email: string;
  /**
   *
   * @type {string}
   * @memberof QuoteCreate
   */
  memo?: string;
}

/**
 *
 * @export
 * @interface QuoteGet
 */
export interface QuoteGet {
  /**
   *
   * @type {PricingQuoteResponse}
   * @memberof QuoteGet
   */
  price_info: PricingQuoteResponse;
  /**
   *
   * @type {string}
   * @memberof QuoteGet
   */
  client_name: string;
  /**
   *
   * @type {string}
   * @memberof QuoteGet
   */
  deal_name: string;
  /**
   *
   * @type {FirewallSide}
   * @memberof QuoteGet
   */
  firewall_side: FirewallSide;
  /**
   *
   * @type {string}
   * @memberof QuoteGet
   */
  zendesk_deal_id: string;
  /**
   *
   * @type {string}
   * @memberof QuoteGet
   */
  user_email: string;
  /**
   *
   * @type {string}
   * @memberof QuoteGet
   */
  memo?: string;
  /**
   *
   * @type {string}
   * @memberof QuoteGet
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof QuoteGet
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof QuoteGet
   */
  updated_at: string;
  /**
   *
   * @type {Array<DistrictDemoData>}
   * @memberof QuoteGet
   */
  district_demo_data?: Array<DistrictDemoData>;
}

/**
 *
 * @export
 * @interface QuoteUpdate
 */
export interface QuoteUpdate {
  /**
   *
   * @type {PricingQuoteResponse}
   * @memberof QuoteUpdate
   */
  price_info?: PricingQuoteResponse;
  /**
   *
   * @type {string}
   * @memberof QuoteUpdate
   */
  client_name?: string;
  /**
   *
   * @type {string}
   * @memberof QuoteUpdate
   */
  deal_name?: string;
  /**
   *
   * @type {FirewallSide}
   * @memberof QuoteUpdate
   */
  firewall_side?: FirewallSide;
  /**
   *
   * @type {string}
   * @memberof QuoteUpdate
   */
  zendesk_deal_id?: string;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const State = {
  Alabama: 'Alabama',
  Alaska: 'Alaska',
  Arizona: 'Arizona',
  Arkansas: 'Arkansas',
  California: 'California',
  Colorado: 'Colorado',
  Connecticut: 'Connecticut',
  Delaware: 'Delaware',
  DistrictOfColumbia: 'District Of Columbia',
  Florida: 'Florida',
  Georgia: 'Georgia',
  Hawaii: 'Hawaii',
  Idaho: 'Idaho',
  Illinois: 'Illinois',
  Indiana: 'Indiana',
  Iowa: 'Iowa',
  Kansas: 'Kansas',
  Kentucky: 'Kentucky',
  Louisiana: 'Louisiana',
  Maine: 'Maine',
  Maryland: 'Maryland',
  Massachusetts: 'Massachusetts',
  Michigan: 'Michigan',
  Minnesota: 'Minnesota',
  Mississippi: 'Mississippi',
  Missouri: 'Missouri',
  Montana: 'Montana',
  Nebraska: 'Nebraska',
  Nevada: 'Nevada',
  NewHampshire: 'New Hampshire',
  NewJersey: 'New Jersey',
  NewMexico: 'New Mexico',
  NewYork: 'New York',
  NorthCarolina: 'North Carolina',
  NorthDakota: 'North Dakota',
  Ohio: 'Ohio',
  Oklahoma: 'Oklahoma',
  Oregon: 'Oregon',
  Pennsylvania: 'Pennsylvania',
  RhodeIsland: 'Rhode Island',
  SouthCarolina: 'South Carolina',
  SouthDakota: 'South Dakota',
  Tennessee: 'Tennessee',
  Texas: 'Texas',
  Utah: 'Utah',
  Vermont: 'Vermont',
  Virginia: 'Virginia',
  Washington: 'Washington',
  WestVirginia: 'West Virginia',
  Wisconsin: 'Wisconsin',
  Wyoming: 'Wyoming',
  Usa: 'USA',
} as const;

export type State = typeof State[keyof typeof State];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const UniverseType = {
  LikelyGeneralVoters: 'likely_general_voters',
  LikelyDemPrimary: 'likely_dem_primary',
  RegisteredVoters: 'registered_voters',
  OtherNonStandard: 'other_non_standard',
  AllAdults: 'all_adults',
} as const;

export type UniverseType = typeof UniverseType[keyof typeof UniverseType];

/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<LocationInner>}
   * @memberof ValidationError
   */
  loc: Array<LocationInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface Weight
 */
export interface Weight {
  /**
   *
   * @type {Key}
   * @memberof Weight
   */
  key: Key;
  /**
   *
   * @type {number}
   * @memberof Weight
   */
  labor: number;
  /**
   *
   * @type {number}
   * @memberof Weight
   */
  wtp: number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthHealthGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBasic required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Private Test
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateTestPrivateGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/private`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthHealthGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthHealthGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Private Test
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async privateTestPrivateGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.privateTestPrivateGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthHealthGet(options?: any): AxiosPromise<object> {
      return localVarFp
        .healthHealthGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Private Test
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateTestPrivateGet(options?: any): AxiosPromise<object> {
      return localVarFp
        .privateTestPrivateGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Health
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public healthHealthGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .healthHealthGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Private Test
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public privateTestPrivateGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .privateTestPrivateGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GeoservicesApi - axios parameter creator
 * @export
 */
export const GeoservicesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get the demo data for multiple geographic entities
     * @summary List Demo Data
     * @param {Array<DistrictQuery>} districtQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDemoDataGeoDemoPost: async (
      districtQuery: Array<DistrictQuery>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'districtQuery' is not null or undefined
      assertParamExists(
        'listDemoDataGeoDemoPost',
        'districtQuery',
        districtQuery,
      );
      const localVarPath = `/geo/demo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        districtQuery,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Districts
     * @param {State} state
     * @param {DistrictType} [districtType]
     * @param {string} [districtName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDistrictsGeoDistrictsStateGet: async (
      state: State,
      districtType?: DistrictType,
      districtName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'state' is not null or undefined
      assertParamExists('listDistrictsGeoDistrictsStateGet', 'state', state);
      const localVarPath = `/geo/districts/{state}`.replace(
        `{${'state'}}`,
        encodeURIComponent(String(state)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (districtType !== undefined) {
        localVarQueryParameter['district_type'] = districtType;
      }

      if (districtName !== undefined) {
        localVarQueryParameter['district_name'] = districtName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List demo data for a single state (and optionally district type and district name)
     * @summary List Single State Demo Data
     * @param {State} state
     * @param {DistrictType} [districtType]
     * @param {string} [districtName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSingleStateDemoDataGeoDemoStateGet: async (
      state: State,
      districtType?: DistrictType,
      districtName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'state' is not null or undefined
      assertParamExists(
        'listSingleStateDemoDataGeoDemoStateGet',
        'state',
        state,
      );
      const localVarPath = `/geo/demo/{state}`.replace(
        `{${'state'}}`,
        encodeURIComponent(String(state)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (districtType !== undefined) {
        localVarQueryParameter['district_type'] = districtType;
      }

      if (districtName !== undefined) {
        localVarQueryParameter['district_name'] = districtName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GeoservicesApi - functional programming interface
 * @export
 */
export const GeoservicesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GeoservicesApiAxiosParamCreator(configuration);
  return {
    /**
     * Get the demo data for multiple geographic entities
     * @summary List Demo Data
     * @param {Array<DistrictQuery>} districtQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDemoDataGeoDemoPost(
      districtQuery: Array<DistrictQuery>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DistrictDemoData>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDemoDataGeoDemoPost(
          districtQuery,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary List Districts
     * @param {State} state
     * @param {DistrictType} [districtType]
     * @param {string} [districtName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDistrictsGeoDistrictsStateGet(
      state: State,
      districtType?: DistrictType,
      districtName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<District>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDistrictsGeoDistrictsStateGet(
          state,
          districtType,
          districtName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * List demo data for a single state (and optionally district type and district name)
     * @summary List Single State Demo Data
     * @param {State} state
     * @param {DistrictType} [districtType]
     * @param {string} [districtName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSingleStateDemoDataGeoDemoStateGet(
      state: State,
      districtType?: DistrictType,
      districtName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DistrictDemoData>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listSingleStateDemoDataGeoDemoStateGet(
          state,
          districtType,
          districtName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * GeoservicesApi - factory interface
 * @export
 */
export const GeoservicesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GeoservicesApiFp(configuration);
  return {
    /**
     * Get the demo data for multiple geographic entities
     * @summary List Demo Data
     * @param {Array<DistrictQuery>} districtQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDemoDataGeoDemoPost(
      districtQuery: Array<DistrictQuery>,
      options?: any,
    ): AxiosPromise<Array<DistrictDemoData>> {
      return localVarFp
        .listDemoDataGeoDemoPost(districtQuery, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List Districts
     * @param {State} state
     * @param {DistrictType} [districtType]
     * @param {string} [districtName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDistrictsGeoDistrictsStateGet(
      state: State,
      districtType?: DistrictType,
      districtName?: string,
      options?: any,
    ): AxiosPromise<Array<District>> {
      return localVarFp
        .listDistrictsGeoDistrictsStateGet(
          state,
          districtType,
          districtName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * List demo data for a single state (and optionally district type and district name)
     * @summary List Single State Demo Data
     * @param {State} state
     * @param {DistrictType} [districtType]
     * @param {string} [districtName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSingleStateDemoDataGeoDemoStateGet(
      state: State,
      districtType?: DistrictType,
      districtName?: string,
      options?: any,
    ): AxiosPromise<Array<DistrictDemoData>> {
      return localVarFp
        .listSingleStateDemoDataGeoDemoStateGet(
          state,
          districtType,
          districtName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GeoservicesApi - object-oriented interface
 * @export
 * @class GeoservicesApi
 * @extends {BaseAPI}
 */
export class GeoservicesApi extends BaseAPI {
  /**
   * Get the demo data for multiple geographic entities
   * @summary List Demo Data
   * @param {Array<DistrictQuery>} districtQuery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeoservicesApi
   */
  public listDemoDataGeoDemoPost(
    districtQuery: Array<DistrictQuery>,
    options?: AxiosRequestConfig,
  ) {
    return GeoservicesApiFp(this.configuration)
      .listDemoDataGeoDemoPost(districtQuery, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Districts
   * @param {State} state
   * @param {DistrictType} [districtType]
   * @param {string} [districtName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeoservicesApi
   */
  public listDistrictsGeoDistrictsStateGet(
    state: State,
    districtType?: DistrictType,
    districtName?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeoservicesApiFp(this.configuration)
      .listDistrictsGeoDistrictsStateGet(
        state,
        districtType,
        districtName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List demo data for a single state (and optionally district type and district name)
   * @summary List Single State Demo Data
   * @param {State} state
   * @param {DistrictType} [districtType]
   * @param {string} [districtName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeoservicesApi
   */
  public listSingleStateDemoDataGeoDemoStateGet(
    state: State,
    districtType?: DistrictType,
    districtName?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeoservicesApiFp(this.configuration)
      .listSingleStateDemoDataGeoDemoStateGet(
        state,
        districtType,
        districtName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PricingApi - axios parameter creator
 * @export
 */
export const PricingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Current Factors
     * @param {boolean} [all] Include all the factors including the large continuous factors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentFactorsPricingFactorsGet: async (
      all?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/pricing/factors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (all !== undefined) {
        localVarQueryParameter['all'] = all;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Price Quote
     * @param {PricingQuoteRequest} pricingQuoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceQuotePricingPost: async (
      pricingQuoteRequest: PricingQuoteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pricingQuoteRequest' is not null or undefined
      assertParamExists(
        'priceQuotePricingPost',
        'pricingQuoteRequest',
        pricingQuoteRequest,
      );
      const localVarPath = `/pricing/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pricingQuoteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PricingApi - functional programming interface
 * @export
 */
export const PricingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PricingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Current Factors
     * @param {boolean} [all] Include all the factors including the large continuous factors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async currentFactorsPricingFactorsGet(
      all?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Factor>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.currentFactorsPricingFactorsGet(
          all,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Price Quote
     * @param {PricingQuoteRequest} pricingQuoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async priceQuotePricingPost(
      pricingQuoteRequest: PricingQuoteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PricingQuoteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.priceQuotePricingPost(
          pricingQuoteRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PricingApi - factory interface
 * @export
 */
export const PricingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PricingApiFp(configuration);
  return {
    /**
     *
     * @summary Current Factors
     * @param {boolean} [all] Include all the factors including the large continuous factors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentFactorsPricingFactorsGet(
      all?: boolean,
      options?: any,
    ): AxiosPromise<Array<Factor>> {
      return localVarFp
        .currentFactorsPricingFactorsGet(all, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Price Quote
     * @param {PricingQuoteRequest} pricingQuoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceQuotePricingPost(
      pricingQuoteRequest: PricingQuoteRequest,
      options?: any,
    ): AxiosPromise<PricingQuoteResponse> {
      return localVarFp
        .priceQuotePricingPost(pricingQuoteRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PricingApi - object-oriented interface
 * @export
 * @class PricingApi
 * @extends {BaseAPI}
 */
export class PricingApi extends BaseAPI {
  /**
   *
   * @summary Current Factors
   * @param {boolean} [all] Include all the factors including the large continuous factors
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingApi
   */
  public currentFactorsPricingFactorsGet(
    all?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return PricingApiFp(this.configuration)
      .currentFactorsPricingFactorsGet(all, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Price Quote
   * @param {PricingQuoteRequest} pricingQuoteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingApi
   */
  public priceQuotePricingPost(
    pricingQuoteRequest: PricingQuoteRequest,
    options?: AxiosRequestConfig,
  ) {
    return PricingApiFp(this.configuration)
      .priceQuotePricingPost(pricingQuoteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * QuotesApi - axios parameter creator
 * @export
 */
export const QuotesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Quote
     * @param {QuoteCreate} quoteCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createQuoteQuotesPost: async (
      quoteCreate: QuoteCreate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'quoteCreate' is not null or undefined
      assertParamExists('createQuoteQuotesPost', 'quoteCreate', quoteCreate);
      const localVarPath = `/quotes/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        quoteCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This is just to get the example filter into the OpenAPI docs, not actually used
     * @summary Example Filter
     * @param {GetQuoteFilter} getQuoteFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exampleFilterQuotesExampleFilterPost: async (
      getQuoteFilter: GetQuoteFilter,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getQuoteFilter' is not null or undefined
      assertParamExists(
        'exampleFilterQuotesExampleFilterPost',
        'getQuoteFilter',
        getQuoteFilter,
      );
      const localVarPath = `/quotes/exampleFilter/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getQuoteFilter,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Quote
     * @param {string} quoteId
     * @param {boolean} [includeDemoData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuoteQuotesQuoteIdGet: async (
      quoteId: string,
      includeDemoData?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'quoteId' is not null or undefined
      assertParamExists('getQuoteQuotesQuoteIdGet', 'quoteId', quoteId);
      const localVarPath = `/quotes/{quote_id}`.replace(
        `{${'quote_id'}}`,
        encodeURIComponent(String(quoteId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (includeDemoData !== undefined) {
        localVarQueryParameter['include_demo_data'] = includeDemoData;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Quotes
     * @param {number} [pageSize]
     * @param {number} [offset]
     * @param {any} [filter] Query filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listQuotesQuotesGet: async (
      pageSize?: number,
      offset?: number,
      filter?: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/quotes/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search Client Name
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchClientNameQuotesSearchClientGet: async (
      query: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists(
        'searchClientNameQuotesSearchClientGet',
        'query',
        query,
      );
      const localVarPath = `/quotes/search/client/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search Deal Name
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDealNameQuotesSearchDealGet: async (
      query: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('searchDealNameQuotesSearchDealGet', 'query', query);
      const localVarPath = `/quotes/search/deal/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Quote
     * @param {string} quoteId
     * @param {QuoteUpdate} quoteUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQuoteQuotesQuoteIdPut: async (
      quoteId: string,
      quoteUpdate: QuoteUpdate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'quoteId' is not null or undefined
      assertParamExists('updateQuoteQuotesQuoteIdPut', 'quoteId', quoteId);
      // verify required parameter 'quoteUpdate' is not null or undefined
      assertParamExists(
        'updateQuoteQuotesQuoteIdPut',
        'quoteUpdate',
        quoteUpdate,
      );
      const localVarPath = `/quotes/{quote_id}`.replace(
        `{${'quote_id'}}`,
        encodeURIComponent(String(quoteId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        quoteUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QuotesApi - functional programming interface
 * @export
 */
export const QuotesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QuotesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Quote
     * @param {QuoteCreate} quoteCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createQuoteQuotesPost(
      quoteCreate: QuoteCreate,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteGet>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createQuoteQuotesPost(
          quoteCreate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This is just to get the example filter into the OpenAPI docs, not actually used
     * @summary Example Filter
     * @param {GetQuoteFilter} getQuoteFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exampleFilterQuotesExampleFilterPost(
      getQuoteFilter: GetQuoteFilter,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetQuoteFilter>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exampleFilterQuotesExampleFilterPost(
          getQuoteFilter,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Quote
     * @param {string} quoteId
     * @param {boolean} [includeDemoData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuoteQuotesQuoteIdGet(
      quoteId: string,
      includeDemoData?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteGet>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getQuoteQuotesQuoteIdGet(
          quoteId,
          includeDemoData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary List Quotes
     * @param {number} [pageSize]
     * @param {number} [offset]
     * @param {any} [filter] Query filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listQuotesQuotesGet(
      pageSize?: number,
      offset?: number,
      filter?: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListQuotesResult>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listQuotesQuotesGet(
          pageSize,
          offset,
          filter,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search Client Name
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchClientNameQuotesSearchClientGet(
      query: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchClientNameQuotesSearchClientGet(
          query,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search Deal Name
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchDealNameQuotesSearchDealGet(
      query: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchDealNameQuotesSearchDealGet(
          query,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Quote
     * @param {string} quoteId
     * @param {QuoteUpdate} quoteUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateQuoteQuotesQuoteIdPut(
      quoteId: string,
      quoteUpdate: QuoteUpdate,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteGet>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateQuoteQuotesQuoteIdPut(
          quoteId,
          quoteUpdate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * QuotesApi - factory interface
 * @export
 */
export const QuotesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = QuotesApiFp(configuration);
  return {
    /**
     *
     * @summary Create Quote
     * @param {QuoteCreate} quoteCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createQuoteQuotesPost(
      quoteCreate: QuoteCreate,
      options?: any,
    ): AxiosPromise<QuoteGet> {
      return localVarFp
        .createQuoteQuotesPost(quoteCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This is just to get the example filter into the OpenAPI docs, not actually used
     * @summary Example Filter
     * @param {GetQuoteFilter} getQuoteFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exampleFilterQuotesExampleFilterPost(
      getQuoteFilter: GetQuoteFilter,
      options?: any,
    ): AxiosPromise<GetQuoteFilter> {
      return localVarFp
        .exampleFilterQuotesExampleFilterPost(getQuoteFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Quote
     * @param {string} quoteId
     * @param {boolean} [includeDemoData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuoteQuotesQuoteIdGet(
      quoteId: string,
      includeDemoData?: boolean,
      options?: any,
    ): AxiosPromise<QuoteGet> {
      return localVarFp
        .getQuoteQuotesQuoteIdGet(quoteId, includeDemoData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List Quotes
     * @param {number} [pageSize]
     * @param {number} [offset]
     * @param {any} [filter] Query filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listQuotesQuotesGet(
      pageSize?: number,
      offset?: number,
      filter?: any,
      options?: any,
    ): AxiosPromise<ListQuotesResult> {
      return localVarFp
        .listQuotesQuotesGet(pageSize, offset, filter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Search Client Name
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchClientNameQuotesSearchClientGet(
      query: string,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .searchClientNameQuotesSearchClientGet(query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Search Deal Name
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchDealNameQuotesSearchDealGet(
      query: string,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .searchDealNameQuotesSearchDealGet(query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Quote
     * @param {string} quoteId
     * @param {QuoteUpdate} quoteUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQuoteQuotesQuoteIdPut(
      quoteId: string,
      quoteUpdate: QuoteUpdate,
      options?: any,
    ): AxiosPromise<QuoteGet> {
      return localVarFp
        .updateQuoteQuotesQuoteIdPut(quoteId, quoteUpdate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * QuotesApi - object-oriented interface
 * @export
 * @class QuotesApi
 * @extends {BaseAPI}
 */
export class QuotesApi extends BaseAPI {
  /**
   *
   * @summary Create Quote
   * @param {QuoteCreate} quoteCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuotesApi
   */
  public createQuoteQuotesPost(
    quoteCreate: QuoteCreate,
    options?: AxiosRequestConfig,
  ) {
    return QuotesApiFp(this.configuration)
      .createQuoteQuotesPost(quoteCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This is just to get the example filter into the OpenAPI docs, not actually used
   * @summary Example Filter
   * @param {GetQuoteFilter} getQuoteFilter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuotesApi
   */
  public exampleFilterQuotesExampleFilterPost(
    getQuoteFilter: GetQuoteFilter,
    options?: AxiosRequestConfig,
  ) {
    return QuotesApiFp(this.configuration)
      .exampleFilterQuotesExampleFilterPost(getQuoteFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Quote
   * @param {string} quoteId
   * @param {boolean} [includeDemoData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuotesApi
   */
  public getQuoteQuotesQuoteIdGet(
    quoteId: string,
    includeDemoData?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return QuotesApiFp(this.configuration)
      .getQuoteQuotesQuoteIdGet(quoteId, includeDemoData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Quotes
   * @param {number} [pageSize]
   * @param {number} [offset]
   * @param {any} [filter] Query filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuotesApi
   */
  public listQuotesQuotesGet(
    pageSize?: number,
    offset?: number,
    filter?: any,
    options?: AxiosRequestConfig,
  ) {
    return QuotesApiFp(this.configuration)
      .listQuotesQuotesGet(pageSize, offset, filter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search Client Name
   * @param {string} query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuotesApi
   */
  public searchClientNameQuotesSearchClientGet(
    query: string,
    options?: AxiosRequestConfig,
  ) {
    return QuotesApiFp(this.configuration)
      .searchClientNameQuotesSearchClientGet(query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search Deal Name
   * @param {string} query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuotesApi
   */
  public searchDealNameQuotesSearchDealGet(
    query: string,
    options?: AxiosRequestConfig,
  ) {
    return QuotesApiFp(this.configuration)
      .searchDealNameQuotesSearchDealGet(query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Quote
   * @param {string} quoteId
   * @param {QuoteUpdate} quoteUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuotesApi
   */
  public updateQuoteQuotesQuoteIdPut(
    quoteId: string,
    quoteUpdate: QuoteUpdate,
    options?: AxiosRequestConfig,
  ) {
    return QuotesApiFp(this.configuration)
      .updateQuoteQuotesQuoteIdPut(quoteId, quoteUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
