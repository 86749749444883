import React from 'react';
import { Box, Center, Heading, Text } from '@chakra-ui/react';

export interface FieldingHeaderProps {
  name: string;
  lastSurveyResponseTime: Date | null;
  lastSurveyCheckTime: Date;
}

function FieldingHeader({
  name,
  lastSurveyResponseTime,
  lastSurveyCheckTime,
}: FieldingHeaderProps) {
  return (
    <Box>
      <Center>
        <Heading>Fielding Dashboard</Heading>
      </Center>
      <Center>
        <Heading size="md">{name}</Heading>
      </Center>
      <Center>
        <Text fontSize="xs">
          {`Time of Last Survey Response: ${
            lastSurveyResponseTime === null
              ? 'none'
              : `${lastSurveyResponseTime.toLocaleTimeString()} ${lastSurveyResponseTime.toLocaleDateString()}`
          }
            `}
        </Text>
      </Center>
      <Center>
        <Text fontSize="xs">
          {`Time of Last SurveyMonkey Check: ${lastSurveyCheckTime.toLocaleTimeString()} ${lastSurveyCheckTime.toLocaleDateString()}`}
        </Text>
      </Center>
    </Box>
  );
}

export default FieldingHeader;
