import React from 'react';

import { NavigationMenu, LinkItemProps } from '../NavigationMenu';

const PricingNavLinks: Array<LinkItemProps> = [];

export default function PollNavigationMenu({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <NavigationMenu linkItems={PricingNavLinks}>{children}</NavigationMenu>
  );
}
