import { extendTheme, ThemeConfig } from '@chakra-ui/react';

enum EmoldColors {
  // Primary
  BigStone = '#3A4F60',
  BrightBlue = '#139AD2',
  Pineapple = '#FBF5AA',
  Sunflower = '#FADB42',

  // Secondary
  Lime = '#D1E06E',
  Grass = '#87C040',
  Orange = '#F47D42',
  Mustard = '#CCA62C',
  Gray = '#E7E2D6',
  SkyBlue = '#BAE3F2',
}

enum EmboldFonts {
  heading = 'Poppins',
  subheading = 'Roboto Slab',
  body = 'Bitter',
}

export enum ChangeColors {
  // primary
  Orange = '#F26C4F',
  BrightBlue = '#139AD2',
  White = '#FFFFFF',
  // secondary
  DarkGray = '#58595B',
  LightGray = '#BCBEC0',
  LightBlue = '#BAE3F2',
}

// https://docs.google.com/document/d/1lRYvuW4mhFUQ2zfcN0kJpCKNN1CvPwA_4nJ1Sn_3QoY/edit
export enum ChangeCatColors {
  DarkGray = '#58595b',
  BrightBlue = '#139ad2',
  BrightOrange = '#f26c4f',
  BrightYellow = '#ffbf0d',
  BrightGreen = '#78c62f',
  BrightPurple = '#7777a5',
  BrightTeal = '#55cfd8',
}

const theme = extendTheme({
  fonts: {
    heading: `'Proxima Nova Bold', sans-serif`,
    body: `'Proxima Nova Regular', sans-serif`,
  },
  styles: {
    global: {
      'table, td, th': {
        border: '1px solid',
        borderCollapse: 'collapse',
      },
    },
  },
  colors: {
    brand: {
      // for the pricing toolbars
      primaryColor: ChangeColors.LightBlue,
      secondaryBackgroundColor: ChangeColors.White,
      // for most text
      textColor: ChangeColors.DarkGray,
      linkColor: ChangeColors.BrightBlue,
      // for propsals, quotes, cost tables
      tableTitleColor: ChangeColors.Orange,
      // for spinners and buttons
      miscUIColor: ChangeColors.BrightBlue,
      miscUIEmptyColor: ChangeColors.LightGray,
    },
    emphasisTextColor: '#FF0000',
  },
  initialColorMode: 'light',
  useSystemColorMode: false,
} as ThemeConfig);

export default theme;
