import Dexie, { Table } from 'dexie';
import { DistrictDemoData, State } from './pricing-tool-api';

const TTL_HOURS = 12;
const TTL_SECONDS = 60 * 60 * TTL_HOURS;

export interface StateDemographicData {
  id?: number;
  expiry: Date;
  state: string;
  data: Array<DistrictDemoData>;
}

export class MyDexie extends Dexie {
  demographicData!: Table<StateDemographicData>;

  constructor() {
    super('pricing-tool');
    this.version(2).stores({
      // represents the indexes only, you don't have to define all the data here. See interface for all fields
      demographicData: '++id,expiry,state',
    });
  }
}

export const db = new MyDexie();

export const saveDemographicDataToIndexed = async (
  state: State,
  data: Array<DistrictDemoData>,
  ttlSeconds = TTL_SECONDS,
) => {
  const expiryTime = new Date(Date.now() + ttlSeconds * 1000);
  const id = await db.demographicData.add({ expiry: expiryTime, state, data });
  return id;
};

export const getDemographicDataFromIndexed = async (
  state: State,
  allowStale = false,
  deleteOnStale = true,
) => {
  const value = await db.demographicData.where('state').equals(state).first();
  if (value) {
    const { expiry, data } = value;
    const isExpired = expiry < new Date(Date.now());
    if (isExpired && deleteOnStale && value.id) {
      await db.demographicData.delete(value.id);
    }
    if (allowStale || !isExpired) {
      return data;
    }
  }
  return null;
};
