import React, { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Configuration,
  QuotesApi,
  ListQuotesResult,
  QuoteUpdate,
  QuoteGet,
  QuoteCreate,
  GetQuoteFilter,
} from '../pricing-tool-api';
import ProvidedServices from './ProvidedServices';
import Contextualizer from './Contextualizer';

const DEFAULT_SIZE = 20;
const DEFAULT_OFFSET = 0;

export interface IQuoteService {
  createQuote(quoteCreate: QuoteCreate): Promise<AxiosResponse<QuoteGet>>;

  listQuotes(
    pageSize?: number,
    offset?: number,
    filter?: GetQuoteFilter,
  ): Promise<AxiosResponse<ListQuotesResult>>;

  updateQuote(
    quoteId: string,
    quoteUpdate: QuoteUpdate,
  ): Promise<AxiosResponse<QuoteGet>>;

  getQuote(
    quoteId: string,
    includeDemoData: boolean,
  ): Promise<AxiosResponse<QuoteGet>>;

  searchDealName(dealNameQuery: string): Promise<AxiosResponse<Array<string>>>;

  searchClientName(
    clientNameQuery: string,
  ): Promise<AxiosResponse<Array<string>>>;
}

const QuoteServiceContext = Contextualizer.createContext(
  ProvidedServices.QuoteService,
);
export const useQuoteService = () =>
  Contextualizer.use<IQuoteService>(ProvidedServices.QuoteService);

type QuoteServiceProps = { children: React.ReactNode };

function QuoteService({ children }: QuoteServiceProps) {
  const { getAccessTokenSilently } = useAuth0();
  const basePath = `${window.location.origin}/api`;

  const quoteService = {
    async createQuote(
      quoteCreate: QuoteCreate,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<QuoteGet>> {
      const tokenValue = await token;
      return new QuotesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).createQuoteQuotesPost(quoteCreate);
    },

    async listQuotes(
      pageSize = DEFAULT_SIZE,
      offset = DEFAULT_OFFSET,
      filter: GetQuoteFilter = {},
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<ListQuotesResult>> {
      const tokenValue = await token;
      return new QuotesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).listQuotesQuotesGet(pageSize, offset, JSON.stringify(filter));
    },

    async updateQuote(
      quoteId: string,
      quoteUpdate: QuoteUpdate,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<QuoteGet>> {
      const tokenValue = await token;
      return new QuotesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).updateQuoteQuotesQuoteIdPut(quoteId, quoteUpdate);
    },

    async getQuote(
      quoteId: string,
      includeDemoData = false,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<QuoteGet>> {
      const tokenValue = await token;
      return new QuotesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).getQuoteQuotesQuoteIdGet(quoteId, includeDemoData);
    },

    async searchDealName(
      dealNameQuery: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<Array<string>>> {
      const tokenValue = await token;
      return new QuotesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).searchDealNameQuotesSearchDealGet(dealNameQuery);
    },

    async searchClientName(
      clientNameQuery: string,
      token = getAccessTokenSilently(),
    ): Promise<AxiosResponse<Array<string>>> {
      const tokenValue = await token;
      return new QuotesApi(
        new Configuration({ basePath, accessToken: tokenValue }),
      ).searchClientNameQuotesSearchClientGet(clientNameQuery);
    },
  };

  return (
    <QuoteServiceContext.Provider value={useMemo(() => quoteService, [])}>
      {children}
    </QuoteServiceContext.Provider>
  );
}

export default QuoteService;
