import React from 'react';
import { Provider } from 'react-redux';
import * as ReactDOMClient from 'react-dom/client';
import {
  ChakraProvider,
  extendTheme,
  type ThemeConfig,
} from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import { store } from './store';
import reportWebVitals from './reportWebVitals';

import App from './App';

import Auth0ProviderWithHistory from './authProvider';
import Fonts from './components/Fonts';

const CHANGE_COLORS = {
  // primary
  Orange: '#F26C4F',
  BrightBlue: '#139AD2',
  White: '#FFFFFF',
  // secondary
  DarkGray: '#58595B',
  LightGray: '#BCBEC0',
  LightBlue: '#BAE3F2',
};

const THEME = extendTheme({
  fonts: {
    heading: `'Proxima Nova Bold', sans-serif`,
    body: `'Proxima Nova Regular', sans-serif`,
  },
  styles: {
    global: {
      'table, td, th': {
        border: '1px solid',
        borderCollapse: 'collapse',
      },
    },
  },
  colors: {
    brand: {
      // for the pricing tool bars
      primaryColor: CHANGE_COLORS.LightBlue,
      secondaryBackgroundColor: CHANGE_COLORS.White,
      // for most text
      textColor: CHANGE_COLORS.DarkGray,
      linkColor: CHANGE_COLORS.BrightBlue,
      // for propsals, quotes, cost tables
      tableTitleColor: CHANGE_COLORS.Orange,
      // for spinners and buttons
      miscUIColor: CHANGE_COLORS.BrightBlue,
      miscUIEmptyColor: CHANGE_COLORS.LightGray,
    },
    emphasisTextColor: '#FF0000',
  },
  initialColorMode: 'light',
  useSystemColorMode: false,
} as ThemeConfig);

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <ChakraProvider theme={THEME}>
            <Fonts />
            <App />
          </ChakraProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
