import { Global } from '@emotion/react';

function Fonts() {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Global
      styles={`
      /* latin */
      @font-face {
        font-family: 'Proxima Nova Bold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${window.location.origin}/fonts/FontsFree-Net-Proxima-Nova-Bold.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Proxima Nova Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${window.location.origin}/fonts/FontsFree-Net-proxima_nova_reg-webfont.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
    />
  );
}

export default Fonts;
