import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

type GqlServiceProps = { children: React.ReactNode };

function GqlService({ children }: GqlServiceProps) {
  const { getAccessTokenSilently } = useAuth0();
  const httpLink = createHttpLink({
    uri: `${window.location.origin}/poll-api/proxy/poll-server/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

export default GqlService;
