import React, { useEffect } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import ClientInformationSection from '../../components/pricing/ClientInformationSection';
import TargetUniverseSection from '../../components/pricing/TargetUniverseSection';
import FeeTypeSection from '../../components/pricing/FeeTypeSection';
import SaveQuoteSection from '../../components/pricing/SaveQuoteSection';
import GenerateQuoteSection from '../../components/pricing/GenerateQuoteSection';
import LocationInformationSection from '../../components/pricing/LocationInformationSection';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ReduxAction } from '../../reducer';
import { useQuoteService } from '../../lib/services/QuoteService';

const PRICING_SECTIONS = [
  {
    menuName: 'Location Information',
    component: LocationInformationSection,
  },
  {
    menuName: 'Client Information',
    component: ClientInformationSection,
  },
  {
    menuName: 'Target Universe',
    component: TargetUniverseSection,
  },
  {
    menuName: 'Fee Type',
    component: FeeTypeSection,
  },
  {
    menuName: 'Generate Quote',
    component: GenerateQuoteSection,
  },
  {
    menuName: 'Save Quote',
    component: SaveQuoteSection,
  },
];

interface PricingFormProps {
  quoteId: string | null;
}

function PricingForm(props: PricingFormProps = { quoteId: null }) {
  const { quoteId } = props;
  return (
    <VStack>
      {PRICING_SECTIONS.map(({ menuName, component }) => (
        <Box key={`main-section-${menuName}`} w="100%">
          <Text bg="brand.primaryColor" mb={4}>
            {menuName}
          </Text>
          <Box mb={8}>{component({ quoteId })}</Box>
        </Box>
      ))}
    </VStack>
  );
}

function PricingTool() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [errMsg, setErrMsg] = React.useState('Loading...');
  const [searchParams] = useSearchParams();
  const quoteId =
    searchParams && searchParams.get('id') ? searchParams.get('id') : null;
  const dispatch = useAppDispatch();
  const quoteFromRedux = useAppSelector((state) => state.selectedQuote);
  const quoteService = useQuoteService();

  useEffect(() => {
    if (quoteId && quoteFromRedux && quoteId !== quoteFromRedux.id) {
      quoteService
        .getQuote(quoteId, true) // force demo data fetch
        .then((response) => {
          setIsLoading(false);
          dispatch({
            type: ReduxAction.UpdateSelectedQuote,
            selectedQuote: response.data,
          });
        })
        .catch(() => {
          setErrMsg(
            `Could not fetch quote with ID ${quoteId}. Please contact the tech team with any questions.`,
          );
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [quoteFromRedux]);

  return (
    <Box>
      {isLoading ? <Box>{errMsg}</Box> : <PricingForm quoteId={quoteId} />}
    </Box>
  );
}

export default PricingTool;
