import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  Heading,
  Grid,
  GridItem,
  Link,
} from '@chakra-ui/react';

import { usePollService, PollDetail } from '../../lib/services/PollService';
import { workflowPollHomeUrl } from '../../lib/workflowUtils';
import { GetPollResponse } from '../../lib/polling-api/api';

function PollDetailView() {
  const { pollId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [errMsg, setErrMsg] = useState<string>();
  const pollService = usePollService();
  const pollFetched = useRef(false);

  const [poll, setPoll] = useState<GetPollResponse>();

  useEffect(() => {
    if (pollFetched.current) return;
    pollFetched.current = true;
    const fetchPoll = async (id: string) => {
      try {
        const pollFromService = await pollService.getPoll(id);
        setPoll(pollFromService.data);
      } catch (err: any) {
        setErrMsg(err.message);
      }
    };
    setIsLoading(true);
    if (pollId) {
      fetchPoll(pollId);
    } else {
      setErrMsg('No poll id provided');
    }
    setIsLoading(false);
  }, []);

  const backToStreamlitUrl = (pollName: string) =>
    workflowPollHomeUrl(window.location.hostname, pollName);

  return (
    <Box>
      {isLoading && <Spinner />}
      {errMsg && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{errMsg}</AlertDescription>
        </Alert>
      )}
      {poll && poll.poll.name && (
        <Grid templateColumns="repeat(1, 5fr)">
          <GridItem colSpan={1}>
            <Box>
              <Heading>{poll.poll.name}</Heading>
            </Box>
          </GridItem>
          <GridItem colSpan={1}>
            <Box>
              <Text>ID: {poll.poll.id.id}</Text>
            </Box>
          </GridItem>
          <GridItem colSpan={1}>
            <Link href={backToStreamlitUrl(poll.poll.name)}>
              <Button>Back to Workflow</Button>
            </Link>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
}

export default PollDetailView;
