import React from 'react';

import {
  TableContainer,
  Table,
  Tr,
  Text,
  Th,
  Tbody,
  Td,
  Thead,
  HStack,
  IconButton,
  NumberInputField,
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
  Select,
  Spacer,
  Spinner,
  Box,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Radio,
  RadioGroup,
  Button,
} from '@chakra-ui/react';

import { AsyncSelect } from 'chakra-react-select';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons';

import {
  PaginationState,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  ColumnFiltersState,
  Table as ReactTable,
  useReactTable,
  Header,
} from '@tanstack/react-table';

import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  BusinessUnit,
  GetQuoteFilter,
  QuoteGet,
} from '../../lib/pricing-tool-api';
import { useQuoteService } from '../../lib/services/QuoteService';

function QuoteList() {
  const navigate = useNavigate();

  const ROW_COUNTS = [20, 50, 100];

  type OptionType = {
    label: string;
  };

  const quoteService = useQuoteService();
  async function callQuotesAPI(
    pageSize?: number,
    offset?: number,
    filter?: GetQuoteFilter,
  ) {
    const response = await quoteService.listQuotes(pageSize, offset, filter);
    return response.data;
  }

  const [loading, setLoading] = React.useState(true);
  const [loadingError, setLoadingError] = React.useState('');
  const [data, setData] = React.useState<QuoteGet[]>([]);

  const [dealNameFilter, setDealNameFilter] =
    React.useState<OptionType | null>();
  const [clientNameFilter, setClientNameFilter] =
    React.useState<OptionType | null>();
  const [businessUnitFilter, setBusinessUnitFilter] = React.useState<
    BusinessUnit | string | undefined
  >('any');

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: ROW_COUNTS[0],
    });
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  // setting defaults for when there's no table data to display
  const defaultData = React.useMemo(() => [], []);
  const [totalPages, setTotalPages] = React.useState(1);

  const table: ReactTable<QuoteGet> = useReactTable({
    data: data ?? defaultData,
    columns: [
      {
        accessorKey: 'deal_name',
        header: 'Deal',
      },
      {
        accessorKey: 'client_name',
        header: 'Client',
      },
      {
        accessorKey: 'user_email',
        header: 'Created By',
      },
      {
        accessorKey: 'updated_at',
        header: 'Last Updated',
      },
      {
        accessorKey: 'price_info.request.business_unit',
        header: 'Business Unit',
      },
    ],
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    pageCount: totalPages,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualPagination: true,
  });

  const populateQuotesList = React.useCallback(() => {
    setLoading(true);
    const filter = {
      deal_name: dealNameFilter?.label,
      client_name: clientNameFilter?.label,
      business_unit:
        businessUnitFilter === 'any'
          ? undefined
          : (businessUnitFilter as BusinessUnit),
    };
    callQuotesAPI(pageSize, pageSize * pageIndex, filter)
      .then((response) => {
        const { quotes, paging } = response;
        if (quotes) {
          setData(quotes);
          if (paging) {
            table.setPageSize(pageSize);
            // instance.setPageCount doesn't seem to work properly
            // this is a workaround
            setTotalPages(Math.ceil(paging.count / pageSize));
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoadingError(error.message);
      });
  }, [
    pageSize,
    pageIndex,
    businessUnitFilter,
    clientNameFilter,
    dealNameFilter,
  ]);

  React.useEffect(() => {
    populateQuotesList();
  }, [populateQuotesList, pageIndex, pageSize]);

  const paginationToolbar = (
    <HStack>
      <IconButton
        aria-label="Go to first page"
        icon={<ArrowLeftIcon />}
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      />

      <IconButton
        aria-label="Go back one page"
        icon={<ChevronLeftIcon />}
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      />

      <Text>
        Page {table.getState().pagination.pageIndex + 1} of{' '}
        {table.getPageCount()}
      </Text>

      <NumberInput
        defaultValue={pageIndex + 1}
        min={1}
        max={table.getPageCount()}
        onChange={(e) => {
          const destPage = e ? Number(e) - 1 : 0;
          table.setPageIndex(destPage);
        }}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      <Select
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {ROW_COUNTS.map((rowCount) => (
          <option key={rowCount} value={rowCount}>
            {rowCount}
          </option>
        ))}
      </Select>

      <IconButton
        aria-label="Go forward one page"
        icon={<ChevronRightIcon />}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      />

      <IconButton
        aria-label="Go to last page"
        icon={<ArrowRightIcon />}
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      />
    </HStack>
  );

  const onRowClick = (row: QuoteGet | undefined) => {
    if (!row) {
      return;
    }
    navigate(`/pricing/quote/${row.id}`, {
      replace: false,
    });
  };

  function headerSortingUI(header: Header<QuoteGet, unknown>) {
    const headerText: string = header?.column?.columnDef?.header as string;
    if (!header.column.getIsSorted()) {
      return headerText;
    }
    if (header.column.getIsSorted() === 'desc') {
      return [`${headerText} Descending`, <ChevronDownIcon />];
    }
    if (header.column.getIsSorted() === 'asc') {
      return [`${headerText} Ascending`, <ChevronUpIcon />];
    }
    return null;
  }

  const onResetClick = (): void => {
    setDealNameFilter(null);
    setClientNameFilter(null);
    setBusinessUnitFilter('any');
  };

  function createFilters(dataTable: ReactTable<QuoteGet>, columnName: string) {
    const col = dataTable.getColumn(columnName);
    if (col === undefined) return <Text>Column not found.</Text>;

    return (
      <Stack spacing="2%" direction="column">
        <Flex alignItems="center">
          <FormControl maxWidth={300} mr="4">
            <FormLabel>Deal Name</FormLabel>
            <AsyncSelect
              isMulti={false}
              isClearable
              value={dealNameFilter}
              onChange={setDealNameFilter}
              placeholder="Select a Deal Name"
              loadOptions={debounce((inputValue, callback) => {
                quoteService.searchDealName(inputValue).then((result) => {
                  callback(
                    result.data.map((name) => ({ label: name.valueOf() })),
                  );
                });
              }, 250)}
            />
          </FormControl>
          <FormControl maxWidth={300} mr="4">
            <FormLabel>Client Name</FormLabel>
            <AsyncSelect
              isMulti={false}
              isClearable
              value={clientNameFilter}
              onChange={setClientNameFilter}
              placeholder="Select a Client Name"
              loadOptions={debounce((inputValue, callback) => {
                quoteService.searchClientName(inputValue).then((result) => {
                  callback(
                    result.data.map((name) => ({ label: name.valueOf() })),
                  );
                });
              }, 250)}
            />
          </FormControl>
          <FormControl mr="4">
            <FormLabel>Business Unit</FormLabel>
            <RadioGroup
              onChange={setBusinessUnitFilter}
              value={businessUnitFilter}
            >
              <Stack direction="row" align="center">
                <Radio value="any">Any</Radio>
                <Radio value={BusinessUnit.Pna}>P&A</Radio>
                <Radio value={BusinessUnit.Embold}>Embold</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Spacer flex="1" />
          <Button pl={6} pr={6} onClick={onResetClick}>
            Reset
          </Button>
        </Flex>
      </Stack>
    );
  }

  return (
    <TableContainer>
      {loading && !loadingError && (
        <Box>
          <Spinner
            emptyColor="brand.miscUIEmptyColor"
            key="1"
            size="xl"
            thickness="3px"
            color="brand.miscUIColor"
          />
          <Text key="2">Loading results...</Text>
        </Box>
      )}

      {loadingError && (
        <Center>
          <Text color="red.500">
            Error: {loadingError} Please report this to the tech team.
          </Text>
        </Center>
      )}

      {!loading && !loadingError && (
        <Flex flexDirection="column" rowGap="1ch">
          {createFilters(table, 'deal_name')}
          {paginationToolbar}
        </Flex>
      )}

      {!loading && !loadingError && (
        <Table>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    className={
                      header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : ''
                    }
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {headerSortingUI(header)}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row?.original?.id}
                onClick={() => {
                  onRowClick(row?.original);
                }}
                cursor="pointer"
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>{String(cell.renderValue())}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </TableContainer>
  );
}

export default QuoteList;
