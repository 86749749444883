import React, { useEffect } from 'react';
import {
  Checkbox,
  RadioGroup,
  FormControl,
  FormLabel,
  VStack,
  Radio,
  InputGroup,
  Input,
  InputLeftAddon,
  FormHelperText,
  Link,
  InputRightAddon,
} from '@chakra-ui/react';
import * as _ from 'lodash';
import { DiscountType, MessageTestingType } from '../../lib/pricing-tool-api';
import DollarSignIcon from '../DollarSignIcon';
import Choice from '../Choice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ReduxAction } from '../../reducer';
import PercentIcon from '../PercentIcon';

export const DISCOUNT_OPTIONS = [
  {
    choiceText: 'None',
    value: DiscountType.None,
  },
  {
    choiceText: 'Multi-poll deal',
    value: DiscountType.MultiPollDeal,
  },
  {
    choiceText: 'Repeat client',
    value: DiscountType.RepeatClient,
  },
];

export const MESSAGE_TESTING_OPTIONS = [
  {
    choiceText: 'None',
    value: MessageTestingType.None,
  },
  {
    choiceText: 'Creative (ex: video / image)',
    value: MessageTestingType.Creative,
  },
  {
    choiceText: 'Written message',
    value: MessageTestingType.Written,
  },
];

const ADDITIONAL_DELIVERABLES_LINK =
  'https://docs.google.com/spreadsheets/d/1cm_u5w9ASITI-jh7zIPr-XiM9u445qOL1yd1027LIb8/edit#gid=448947046';

const MARKUP_PRECISION = 1;

export const formatManualMarkupForDisplay = (manualMarkup: number): number => {
  let formatted = manualMarkup;
  if (formatted === 1) {
    formatted = 0;
  } else {
    formatted = (formatted - 1) * 100;
  }
  formatted = Number.parseFloat(formatted.toFixed(MARKUP_PRECISION));
  return formatted;
};

export const formatManualMarkupForSaving = (
  manualMarkup: string | number,
): number => {
  let markup =
    typeof manualMarkup === 'string' ? _.toNumber(manualMarkup) : manualMarkup;
  markup = markup === 0 ? 1 : 1 + markup / 100;
  // precision preserved with + 2 since we divide markup by 100
  // 12.34% -> 1.1234 -> 1.123
  markup = Number.parseFloat(markup.toFixed(MARKUP_PRECISION + 2));
  return markup;
};

export interface FeeTypeSectionProps {
  quoteId: string | null;
}

function FeeTypeSection(props: FeeTypeSectionProps = { quoteId: null }) {
  const { quoteId } = props;
  const [discountType, setDiscountType] = React.useState(
    DiscountType.None as string,
  );
  const [isAsap, setIsAsap] = React.useState(false);
  const [manualMarkup, setManualMarkup] = React.useState<number | string>(0);
  const [additionalFees, setAdditionalFees] = React.useState<number | string>(
    0,
  );
  const [messageTestingType, setMessageTestingType] = React.useState(
    MessageTestingType.None as string,
  );

  const dispatch = useAppDispatch();
  const quoteFromRedux = useAppSelector((state) => state.selectedQuote);

  // only on first render load the saved quote
  useEffect(() => {
    if (quoteId && quoteFromRedux && quoteFromRedux.id === quoteId) {
      if (quoteFromRedux.price_info.request.discount) {
        setDiscountType(quoteFromRedux.price_info.request.discount);
      }
      if (quoteFromRedux.price_info.request.asap) {
        setIsAsap(quoteFromRedux.price_info.request.asap);
      }
      if (quoteFromRedux.price_info.request.manual_markup) {
        setManualMarkup(
          formatManualMarkupForDisplay(
            quoteFromRedux.price_info.request.manual_markup,
          ),
        );
      }
      if (quoteFromRedux.price_info.request.additional_fees) {
        setAdditionalFees(quoteFromRedux.price_info.request.additional_fees);
      }
      setMessageTestingType(quoteFromRedux.price_info.request.message_testing);
    } else if (quoteId && (!quoteFromRedux || quoteFromRedux.id !== quoteId)) {
      throw new Error('Should have loaded the quote from api into redux');
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateDiscountType,
      discountType,
    });
  }, [dispatch, discountType]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateIsAsap,
      isAsap,
    });
  }, [dispatch, isAsap]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateManualMarkup,
      manualMarkup: formatManualMarkupForSaving(manualMarkup),
    });
  }, [dispatch, manualMarkup]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateAdditionalFees,
      additionalFees: _.toNumber(additionalFees),
    });
  }, [dispatch, additionalFees]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateMessageTestingType,
      messageTestingType,
    });
  }, [dispatch, messageTestingType]);

  return (
    <VStack spacing="2%">
      <FormControl isRequired>
        <FormLabel>Discounts</FormLabel>
        <RadioGroup
          value={discountType}
          onChange={setDiscountType}
          defaultValue={DiscountType.None}
        >
          <VStack flexDir="column" align="start">
            {DISCOUNT_OPTIONS.map(({ value, choiceText }: Choice) => (
              <Radio key={value} value={value}>
                {choiceText}
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
      </FormControl>

      <FormControl textAlign="start">
        <FormLabel>ASAP</FormLabel>
        <Checkbox isChecked={isAsap} onChange={() => setIsAsap(!isAsap)} />
      </FormControl>

      <FormControl textAlign="start">
        <FormLabel>Mark-up (+) / Discount (-) Percentage</FormLabel>
        <InputGroup>
          <Input
            type="number"
            value={manualMarkup}
            onChange={(e) =>
              setManualMarkup(e.target.value ? _.toNumber(e.target.value) : '')
            }
            onBlur={(e) => {
              setManualMarkup(_.toNumber(e.target.value));
            }}
            isInvalid={!_.inRange(_.toNumber(manualMarkup), -99, 100)}
          />
          <InputRightAddon pointerEvents="none">
            <PercentIcon />
          </InputRightAddon>
        </InputGroup>
        <FormHelperText>
          % (eg. 20 or 30), NOT counting markups/discounts from choices already
          on this page (ex: ASAP or creative testing). Use a negative (eg. -10)
          for a discount.
        </FormHelperText>
      </FormControl>

      <FormControl textAlign="start">
        <FormLabel>
          Additional fee/discount(s) to add to the total price
        </FormLabel>
        <InputGroup>
          <InputLeftAddon>
            <DollarSignIcon />
          </InputLeftAddon>
          <Input
            type="number"
            value={additionalFees}
            onChange={(e) =>
              setAdditionalFees(
                e.target.value ? _.toNumber(e.target.value) : '',
              )
            }
            onBlur={(e) => {
              setAdditionalFees(_.round(_.toNumber(e.target.value), 2));
            }}
          />
        </InputGroup>
        <FormHelperText>
          <Link
            href={ADDITIONAL_DELIVERABLES_LINK}
            color="brand.linkColor"
            isExternal
          >
            Additional Deliverable Prices
          </Link>
        </FormHelperText>
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Message Testing</FormLabel>
        <RadioGroup value={messageTestingType} onChange={setMessageTestingType}>
          <VStack flexDir="column" align="start">
            {MESSAGE_TESTING_OPTIONS.map(({ value, choiceText }: Choice) => (
              <Radio key={value} value={value}>
                {choiceText}
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
      </FormControl>
    </VStack>
  );
}

export default FeeTypeSection;
