import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  TableContainer,
  Table,
  Tr,
  Grid,
  GridItem,
  Tbody,
  Td,
  TableCaption,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import * as _ from 'lodash';

import {
  District,
  DistrictDemoData,
  PricingQuotePrice,
  QuoteGet,
} from '../../lib/pricing-tool-api';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  IQuoteService,
  useQuoteService,
} from '../../lib/services/QuoteService';
import { ReduxAction } from '../../reducer';
import { LocationInformationTable } from '../../components/pricing/LocationInformationSection';
import {
  ProposalListSection,
  QuoteListSection,
  RecruitmentCostSection,
} from '../../components/pricing/GenerateQuoteSection';
import { calculateMaxRecommendedSampleSize } from '../../store';
import {
  OVERSAMPLE_OPTIONS,
  UNIVERSE_TYPE_OPTIONS,
} from '../../components/pricing/TargetUniverseSection';
import {
  DISCOUNT_OPTIONS,
  MESSAGE_TESTING_OPTIONS,
  formatManualMarkupForDisplay,
} from '../../components/pricing/FeeTypeSection';
import {
  BUSINESS_UNIT_OPTIONS,
  CLIENT_TYPE_OPTIONS,
} from '../../components/pricing/ClientInformationSection';

interface SimpleDetailTableProps {
  data: Map<string, string | undefined>;
  title: string;
}

function SimpleDetailTable(props: SimpleDetailTableProps) {
  const { data, title } = props;
  return (
    <TableContainer>
      <Table size="sm">
        <TableCaption placement="top" bg="brand.primaryColor">
          {title}
        </TableCaption>
        <Tbody>
          {[...data.keys()].map((key) => (
            <Tr key={key}>
              <Td>{key}</Td>
              <Td whiteSpace="normal" overflow="auto">
                {data.get(key)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

interface QuoteDescriptionProps {
  quote: QuoteGet | undefined;
}

function QuoteDescription(props: QuoteDescriptionProps) {
  const { quote } = props;
  const navigate = useNavigate();

  const [districtDemoData] = useState<DistrictDemoData[]>(
    quote?.district_demo_data && quote?.district_demo_data.length > 0
      ? quote?.district_demo_data
      : [],
  );

  const clientInformation = () => {
    if (!quote) {
      throw new Error('Quote was null, contact the tech team');
    }
    let clientType = quote.price_info.request.client_type.toString();
    const mappedClientType = _.flatMap(
      CLIENT_TYPE_OPTIONS,
      (group) => group.groupTypes,
    ).find((x) => x.value === clientType);
    if (mappedClientType) {
      clientType = mappedClientType.choiceText;
    }

    let businessUnit = quote.price_info.request.business_unit.toString();
    const mappedBusinessUnit = BUSINESS_UNIT_OPTIONS.find(
      (x) => x.value === businessUnit,
    );
    if (mappedBusinessUnit) {
      businessUnit = mappedBusinessUnit.choiceText;
    }
    return new Map<string, string | undefined>([
      ['ID', quote?.id],
      ['Client Type', clientType],
      ['Business Unit', businessUnit],
      ['Deal ID', quote?.zendesk_deal_id],
      ['Deal Name', quote?.deal_name],
      ['Client Name', quote?.client_name],
      ['Firewall Side', quote?.firewall_side as string],
      ['Memo', quote?.memo],
    ]);
  };

  const targetUniverseInfo = () => {
    let oversampleType = quote?.price_info.request.oversample_type?.toString();
    if (oversampleType) {
      const mappedOversampleType = OVERSAMPLE_OPTIONS.find(
        (option) => option.value === oversampleType,
      )?.choiceText;
      if (mappedOversampleType) {
        oversampleType = mappedOversampleType;
      }
    }
    return new Map<string, string | undefined>([
      [
        'Num of Universes',
        quote?.price_info.request.num_of_universes.toString(),
      ],
      [
        'Universe Types',
        quote?.price_info.request.universe_types
          .map((u) => {
            const universeType = UNIVERSE_TYPE_OPTIONS.find(
              (ut) => ut.value === u,
            );
            return universeType ? universeType.choiceText : u.toString();
          })
          .join(', '),
      ],
      ['Oversample Type', oversampleType],
      [
        'Special Demographic Filtering',
        `${(quote?.price_info.request.special_demo_filter_pct || 0) * 100}%`,
      ],
    ]);
  };

  const feeTypeInfo = () => {
    let discountType = quote?.price_info.request.discount?.toString();
    if (discountType) {
      const mappedDiscountType = DISCOUNT_OPTIONS.find(
        (option) => option.value === discountType,
      )?.choiceText;
      if (mappedDiscountType) {
        discountType = mappedDiscountType;
      }
    }
    const markup = formatManualMarkupForDisplay(
      quote?.price_info.request.manual_markup as number,
    );
    let messageTesting = quote?.price_info.request.message_testing?.toString();
    if (messageTesting) {
      const mappedMessageTesting = MESSAGE_TESTING_OPTIONS.find(
        (option) => option.value === messageTesting,
      )?.choiceText;
      if (mappedMessageTesting) {
        messageTesting = mappedMessageTesting;
      }
    }
    return new Map<string, string | undefined>([
      ['Discount', discountType],
      ['ASAP', quote?.price_info.request.asap?.toString()],
      ['Mark-up (+) / Discount (-) Percentage', `${markup}%`],
      [
        'Additional fee(s)',
        `$${quote?.price_info.request.additional_fees?.toString()}`,
      ],
      ['Message Testing', messageTesting],
    ]);
  };

  const maxRecommendedSampleSize = () =>
    districtDemoData.length === 0
      ? Number.MAX_SAFE_INTEGER // this is a hack so that the table doesn't show a warning if demo data is missing
      : calculateMaxRecommendedSampleSize(districtDemoData);

  const demoDataAsMap = () => {
    const result = new Map<string, DistrictDemoData>();
    districtDemoData.forEach((demo) => {
      const id =
        demo.district.id ||
        `${demo.district.state}|${demo.district.district_type}|${demo.district.name}`;
      result.set(id, demo);
    });

    return result;
  };

  const onEditButtonClick = () => {
    navigate(`/pricing/quote?id=${quote?.id}`, { replace: true });
  };

  return (
    <Box>
      <Grid templateColumns="repeat(2, 5fr)">
        <GridItem colSpan={2}>
          <Button
            float="right"
            leftIcon={<EditIcon />}
            variant="solid"
            colorScheme="blue"
            onClick={onEditButtonClick}
          >
            Edit
          </Button>
        </GridItem>
        <GridItem fontSize="md">
          <SimpleDetailTable
            data={clientInformation()}
            title="Client Information"
          />
        </GridItem>
        <GridItem fontSize="md">
          <SimpleDetailTable
            data={targetUniverseInfo()}
            title="Target Universe"
          />
        </GridItem>

        <GridItem fontSize="md">
          <SimpleDetailTable data={feeTypeInfo()} title="Fee Type" />
        </GridItem>
        <GridItem colSpan={2}>
          <Text bg="brand.primaryColor" fontSize="md">
            Location Information
          </Text>
          <LocationInformationTable
            selectedDistricts={
              quote?.price_info.request.districts as District[]
            }
            selectedDistrictsDemoData={demoDataAsMap()}
            onRemoveDistrict={() => {}}
            showRemoveButton={false}
            allowNullDemoData
            size="sm"
          />
        </GridItem>
      </Grid>

      <Text bg="brand.primaryColor" mb={4}>
        Prices
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" gap="20px">
        <GridItem>
          <RecruitmentCostSection
            calculatedMaxRecommendedSampleSize={maxRecommendedSampleSize()}
            quotes={quote?.price_info.prices as PricingQuotePrice[]}
            numberOfQuestions={
              quote?.price_info.request.number_of_questions as number[]
            }
            sampleSizes={quote?.price_info.request.sample_sizes as number[]}
          />
        </GridItem>

        <GridItem>
          <QuoteListSection
            calculatedMaxRecommendedSampleSize={maxRecommendedSampleSize()}
            quotes={quote?.price_info.prices as PricingQuotePrice[]}
            numberOfQuestions={
              quote?.price_info.request.number_of_questions as number[]
            }
            sampleSizes={quote?.price_info.request.sample_sizes as number[]}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <ProposalListSection
            calculatedMaxRecommendedSampleSize={maxRecommendedSampleSize()}
            quotes={quote?.price_info.prices as PricingQuotePrice[]}
          />
        </GridItem>
      </Grid>
    </Box>
  );
}

function QuoteDetail() {
  const { quoteId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [quote, setQuote] = useState<QuoteGet>();
  const [errMsg, setErrMsg] = useState<string>();
  const dispatch = useAppDispatch();
  const quoteFromRedux = useAppSelector((state) => state.selectedQuote);
  const quoteService = useQuoteService();

  const fetchSavedQuote = async (api: IQuoteService, id: string) => {
    const response = await api.getQuote(id, true);
    return response;
  };

  useEffect(() => {
    if (!quoteId) {
      return;
    }
    if (quoteFromRedux && quoteFromRedux.id === quoteId) {
      setQuote(quoteFromRedux);
      setIsLoading(false);
    } else {
      fetchSavedQuote(quoteService, quoteId)
        .then((response) => {
          setQuote(response.data);
          setIsLoading(false);
          dispatch({
            type: ReduxAction.UpdateSelectedQuote,
            selectedQuote: response.data,
          });
        })
        .catch(() => {
          setErrMsg(
            `Could not fetch quote with ID ${quoteId}. Please contact the tech team with any questions.`,
          );
          setIsLoading(false);
        });
    }
  }, [quoteId]);

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box>
          {!errMsg ? (
            <QuoteDescription quote={quote} />
          ) : (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>Fetch Error</AlertTitle>
              <AlertDescription>{errMsg}</AlertDescription>
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
}

export default QuoteDetail;
