import React from 'react';

import {
  Table,
  Tbody,
  Th,
  Tr,
  Td,
  Thead,
  Stat,
  StatNumber,
  StatHelpText,
  Tooltip,
} from '@chakra-ui/react';

import { ChangeCatColors } from '../../theme';

const COLOR_ALERT = ChangeCatColors.BrightOrange;
const COLOR_GOOD = ChangeCatColors.BrightGreen;
const COLOR_WARN = ChangeCatColors.BrightYellow;

export interface SummaryTableRow {
  option: string;
  targetPercentage: number | string | null;
  targetN: number | string | null;
  currentPercentage: number | string;
  currentN: number | string;
  currentPercentageOfTarget: number | string | null;
}

interface SummaryTableProps {
  rows: SummaryTableRow[];
}

function SegmentTable({ rows }: SummaryTableProps) {
  const conditionalFormatPercentage = (value: number | string | null) => {
    const defaultColor = 'black';
    if (value === null) {
      return defaultColor;
    }
    const asNumber = Number(value);
    if (asNumber > 1) {
      return COLOR_ALERT;
    }
    if (asNumber === 1) {
      return COLOR_GOOD;
    }
    if (asNumber > 0.9) {
      return COLOR_WARN;
    }
    return defaultColor;
  };
  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th />
          <Th>Sample</Th>
          <Th>%</Th>
          <Th>
            Current % <br />
            of Target
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((row: any) => (
          <Tr key={row.option}>
            <Td>{row.option}</Td>
            <Td>
              <Stat>
                <Tooltip label="The total number of respondents in this subgroup">
                  <StatNumber>{row.currentN}</StatNumber>
                </Tooltip>
                <Tooltip label="The target number of respondents for this subgroup">
                  <StatHelpText>{row.targetN}</StatHelpText>
                </Tooltip>
              </Stat>
            </Td>
            <Td>
              <Stat>
                <Tooltip label="The percent of the collected population represented by this subgroup">
                  <StatNumber>
                    {(row.currentPercentage * 100).toFixed(1)}
                  </StatNumber>
                </Tooltip>
                <Tooltip label="The target percent for this subgroup.">
                  <StatHelpText>
                    {(row.targetPercentage * 100).toFixed(1)}
                  </StatHelpText>
                </Tooltip>
              </Stat>
            </Td>
            <Td>
              <Stat>
                <Tooltip label="The number of respondents in this subgroup divided by the target number of respondents for this subgroup expressed as a percentage">
                  <StatNumber
                    color={conditionalFormatPercentage(
                      row.currentPercentageOfTarget,
                    )}
                  >
                    {row.currentPercentageOfTarget === null
                      ? 'n/a'
                      : (row.currentPercentageOfTarget * 100).toFixed(1)}
                  </StatNumber>
                </Tooltip>
              </Stat>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default SegmentTable;
