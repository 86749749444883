import React, { useEffect, useState } from 'react';
import {
  VStack,
  Link,
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  HStack,
  Text,
} from '@chakra-ui/react';

import { ClientType, BusinessUnit } from '../../lib/pricing-tool-api';
import Choice from '../Choice';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ReduxAction } from '../../reducer';

const EXAMPLE_CLIENTS_LINK =
  'https://docs.google.com/spreadsheets/d/16lQVD2mf1E3btRb0Bkbl5IPEJBkzjH5ZhBnmXamwrU8/edit#gid=0';

export interface ClientTypeOption {
  choiceText: string;
  flavorText: string;
  value: ClientType;
}
export interface ClientTypeGroup {
  clientTypeGroup: string;
  groupTypes: ClientTypeOption[];
}

export const CLIENT_TYPE_OPTIONS: ClientTypeGroup[] = [
  {
    clientTypeGroup: 'Electoral',
    groupTypes: [
      {
        choiceText: 'Downballot',
        flavorText:
          'Any client focused on downballot races (ex: State Legislative races)',
        value: ClientType.Downballot,
      },
      {
        choiceText: 'House',
        flavorText: 'Any client focused on US House races',
        value: ClientType.House,
      },
      {
        choiceText: 'Statewide',
        flavorText: 'Any client focused on Statewide races',
        value: ClientType.Statewide,
      },
      {
        choiceText: 'Nationwide',
        flavorText: 'Any client focused on nationwide races',
        value: ClientType.Nationwide,
      },
    ],
  },
  {
    clientTypeGroup: 'Non-Electoral',
    groupTypes: [
      {
        choiceText: 'Non-election / Non-gov',
        flavorText: 'Large NPNC issue and non-electoral polling',
        value: ClientType.NonElectionNonGov,
      },
      {
        choiceText: 'Government',
        flavorText: 'Government polling on non-electoral topics',
        value: ClientType.Government,
      },
      {
        choiceText: 'Corporate',
        flavorText:
          'Corporate client, such as a tech company or a trade association, polling on non-electoral topics',
        value: ClientType.Corporate,
      },
      {
        choiceText: 'Other',
        flavorText:
          'Small NPNC and other organizations (ex: media) polling on issue and non-electoral topics',
        value: ClientType.Other,
      },
    ],
  },
];

export const BUSINESS_UNIT_OPTIONS = [
  {
    choiceText: 'P&A',
    value: BusinessUnit.Pna,
  },
  {
    choiceText: 'Embold',
    value: BusinessUnit.Embold,
  },
];

interface ClientInformationSectionProps {
  quoteId: string | null;
}

function ClientInformationSection(
  props: ClientInformationSectionProps = { quoteId: null },
) {
  const { quoteId } = props;
  const [businessUnit, setBusinessUnit] = useState<BusinessUnit>(
    BUSINESS_UNIT_OPTIONS[0].value,
  );
  const [clientType, setClientType] = useState<ClientType>(
    CLIENT_TYPE_OPTIONS[0].groupTypes[0].value,
  );
  const dispatch = useAppDispatch();
  const quoteFromRedux = useAppSelector((state) => state.selectedQuote);

  // only on first render load the saved quote
  useEffect(() => {
    if (quoteId && quoteFromRedux && quoteFromRedux.id === quoteId) {
      setBusinessUnit(quoteFromRedux.price_info.request.business_unit);
      setClientType(quoteFromRedux.price_info.request.client_type);
    } else if (quoteId && (!quoteFromRedux || quoteFromRedux.id !== quoteId)) {
      throw new Error('Should have loaded the quote from api into redux');
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateBusinessUnit,
      businessUnit,
    });
  }, [dispatch, businessUnit]);

  useEffect(() => {
    dispatch({ type: ReduxAction.UpdateClientType, clientType });
  }, [dispatch, clientType]);

  return (
    <VStack spacing="2%">
      <FormControl isRequired>
        <RadioGroup
          value={clientType}
          onChange={(v) => {
            setClientType(v as ClientType);
          }}
        >
          <FormLabel as="label">
            {"What is the focus of this client's polling? "}
            <Link
              href={EXAMPLE_CLIENTS_LINK}
              color="brand.linkColor"
              isExternal
            >
              (examples)
            </Link>
          </FormLabel>
          <Stack direction="column" align="stretch">
            {CLIENT_TYPE_OPTIONS.map(({ clientTypeGroup, groupTypes }) => [
              <Text alignSelf="start">{clientTypeGroup}</Text>,
              groupTypes.map(
                ({ choiceText, flavorText, value }: Choice, index) => {
                  const id = `clientType${index}`;
                  const radio = (
                    <HStack key={id}>
                      <Radio id={id} value={value as ClientType}>
                        {` ${choiceText} - `}
                      </Radio>
                      <FormHelperText>{flavorText}</FormHelperText>
                    </HStack>
                  );
                  return radio;
                },
              ),
            ])}
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl isRequired>
        <RadioGroup
          onChange={(v) => setBusinessUnit(v as BusinessUnit)}
          value={businessUnit}
        >
          <FormLabel as="label">Business Unit</FormLabel>
          <HStack>
            {BUSINESS_UNIT_OPTIONS.map(({ choiceText, value }: Choice) => (
              <Radio key={value} value={value}>
                {' '}
                {choiceText}{' '}
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
      </FormControl>
    </VStack>
  );
}

export default ClientInformationSection;
