import React, { useState, useEffect } from 'react';

import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  TableContainer,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  useToast,
  TableCaption,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import { AxiosError } from 'axios';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ReduxAction } from '../../reducer';
import { selectMaxRecommendedSampleSize } from '../../store';

import {
  PricingQuotePrice,
  PricingQuoteRequest,
} from '../../lib/pricing-tool-api';
import { usePricingService } from '../../lib/services/PricingService';

const MIN_SAMPLE_SIZE = 50;
const MAX_SAMPLE_SIZE = 3000;
const MIN_QUESTIONS = 12;
const MAX_QUESTIONS = 75;
const MAX_PRICING_QUOTE_PRICES = 5;

function pricingQuotePriceToString(
  numberOfQuestions: number,
  sampleSize: number,
) {
  return `${sampleSize} respondent, ${numberOfQuestions} question poll`;
}

export async function copyRichContent(
  tableReference: React.RefObject<HTMLTableElement>,
) {
  const docRange = document.createRange();
  if (tableReference.current) {
    docRange.selectNode(tableReference.current);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(docRange);
    document.execCommand('copy');
    window.getSelection()?.removeAllRanges();
  }
}

interface QuoteMatrixProps {
  numberOfQuestions: number[];
  sampleSizes: number[];
  quotes: PricingQuotePrice[];
  calculatedMaxRecommendedSampleSize: number;
}

interface ProposalListSectionProps {
  quotes: PricingQuotePrice[];
  calculatedMaxRecommendedSampleSize: number;
}

export function ProposalListSection(props: ProposalListSectionProps) {
  const tableRef = React.useRef<HTMLTableElement>(null);
  const { quotes, calculatedMaxRecommendedSampleSize } = props;
  const toast = useToast();

  return (
    <TableContainer ref={tableRef}>
      <Table size="sm" variant="striped">
        <TableCaption
          placement="top"
          backgroundColor="brand.tableTitleColor"
          textColor="brand.bodyText"
          fontSize="lg"
        >
          Proposal List
          <IconButton
            aria-label="Copy proposals to clipboard"
            variant="ghost"
            color="brand.linkColor"
            marginLeft={10}
            onClick={() => {
              toast({
                title: 'Proposal List',
                description: 'Copied proposal list to clipboard!',
                status: 'info',
                duration: 5000,
                isClosable: true,
              });
              copyRichContent(tableRef);
            }}
          >
            <CopyIcon />
          </IconButton>
        </TableCaption>
        <Thead>
          <Tr>
            <Th textAlign="center" fontWeight="bold">
              Description
            </Th>
            <Th textAlign="center" fontWeight="bold">
              Price
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {quotes.map(({ number_of_questions, sample_size, price }) => {
            const descriptor = pricingQuotePriceToString(
              number_of_questions,
              sample_size,
            );
            return (
              <Tr
                key={descriptor}
                color={
                  sample_size > calculatedMaxRecommendedSampleSize
                    ? 'emphasisTextColor'
                    : 'current'
                }
              >
                <Td textAlign="center">
                  <strong>{`${descriptor}`}</strong>
                </Td>
                <Td textAlign="center">{`$${price.toLocaleString(
                  'en-US',
                )}`}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export function RecruitmentCostSection(props: QuoteMatrixProps) {
  const {
    numberOfQuestions,
    sampleSizes,
    quotes,
    calculatedMaxRecommendedSampleSize,
  } = props;
  const tableRef = React.useRef<HTMLTableElement>(null);

  const toast = useToast();
  return (
    <TableContainer ref={tableRef}>
      <Table variant="striped" size="sm">
        <TableCaption
          placement="top"
          backgroundColor="brand.tableTitleColor"
          textColor="brand.bodyText"
          fontSize="lg"
        >
          Estimated Recruitment Cost
          <IconButton
            aria-label="Copy estimated recruitment costs to clipboard"
            variant="ghost"
            color="brand.linkColor"
            marginLeft={10}
            onClick={() => {
              toast({
                title: 'Estimated Recruitment Costs',
                description: 'Copy estimated recruitment costs to clipboard!',
                status: 'info',
                duration: 5000,
                isClosable: true,
              });
              copyRichContent(tableRef);
            }}
          >
            <CopyIcon />
          </IconButton>
        </TableCaption>
        <Thead>
          <Tr>
            <Td textAlign="center" fontWeight="bold">
              Respondents
            </Td>
            {sampleSizes.map((value) => (
              <Td textAlign="center" fontWeight="bold" key={value}>
                n = {value}
              </Td>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {numberOfQuestions.map((questionCount) => (
            <Tr key={questionCount}>
              <Td textAlign="center">
                <strong>{questionCount} Question Poll</strong>
              </Td>
              {sampleSizes.map((sampleValue, index) => (
                <Td
                  textAlign="center"
                  key={`${questionCount}-${sampleValue}`}
                  color={
                    sampleValue > calculatedMaxRecommendedSampleSize
                      ? 'emphasisTextColor'
                      : 'current'
                  }
                >{`$${
                  quotes.length && index < quotes.length
                    ? _.find(
                        quotes,
                        (q) =>
                          q.number_of_questions === questionCount &&
                          q.sample_size === sampleValue,
                      )?.recruitment_cost.toLocaleString('en-US')
                    : '-'
                }`}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export function QuoteListSection(props: QuoteMatrixProps) {
  const {
    numberOfQuestions,
    sampleSizes,
    quotes,
    calculatedMaxRecommendedSampleSize,
  } = props;
  const tableRef = React.useRef<HTMLTableElement>(null);
  const toast = useToast();
  return (
    <TableContainer ref={tableRef}>
      <Table size="sm" variant="striped">
        <TableCaption
          placement="top"
          backgroundColor="brand.tableTitleColor"
          textColor="brand.bodyText"
          fontSize="lg"
        >
          Quote
          <IconButton
            aria-label="Copy quote to clipboard"
            variant="ghost"
            color="brand.linkColor"
            marginLeft={10}
            onClick={() => {
              toast({
                title: 'Quote',
                description: 'Copied quote to clipboard!',
                status: 'info',
                duration: 5000,
                isClosable: true,
              });
              copyRichContent(tableRef);
            }}
          >
            <CopyIcon />
          </IconButton>
        </TableCaption>
        <Thead>
          <Tr>
            <Td textAlign="center" fontWeight="bold">
              Respondents
            </Td>
            {sampleSizes.map((value, index) => {
              const key = `${value}-${index}`;
              return (
                <Td textAlign="center" fontWeight="bold" key={key}>
                  n = {value}
                </Td>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {numberOfQuestions.map((questionCount) => (
            <Tr key={questionCount}>
              <Td textAlign="center">
                <strong>{`${questionCount} Question Poll`}</strong>
              </Td>
              {sampleSizes.map((sampleValue, index) => (
                <Td
                  textAlign="center"
                  key={`${questionCount}-${sampleValue}`}
                  color={
                    sampleValue > calculatedMaxRecommendedSampleSize
                      ? 'emphasisTextColor'
                      : 'current'
                  }
                >{`$${
                  quotes.length && index < quotes.length
                    ? _.find(
                        quotes,
                        (q) =>
                          q.number_of_questions === questionCount &&
                          q.sample_size === sampleValue,
                      )?.price.toLocaleString('en-US')
                    : '-'
                }`}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export interface GenerateQuoteSectionProps {
  quoteId: string | null;
}

function GenerateQuoteSection(
  props: GenerateQuoteSectionProps = { quoteId: null },
) {
  const AT_LEAST_ONE_LOCATION_TOOLTIP = 'You must have at least one location';
  const MAX_QUESTION_COUNT_TOOLTIP =
    "You've reached the max number of question counts you can add";
  const QUESTION_COUNT_ALREADY_TOOLTIP = 'Question count already added';
  const MAX_SAMPLE_SIZES_TOOLTIP =
    "You've reached the max number of sample sizes you can add";
  const SAMPLE_SIZE_ALREADY_TOOLTIP = 'Sample size already added';

  const { quoteId } = props;
  const [numberOfQuestions, setNumberOfQuestions] = useState<number[]>([]);
  const [sampleSizes, setSampleSizes] = useState<number[]>([]);
  const [quotes, setQuotes] = useState<Array<PricingQuotePrice>>([]);
  const [numberOfQuestionsToAdd, setNumberOfQuestionsToAdd] =
    useState(MIN_QUESTIONS);
  const [sampleSizeToAdd, setSampleSizeToAdd] = useState(MIN_SAMPLE_SIZE);
  const [pricingErrorMessage, setPricingErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [canAddMoreQuestions, setCanAddMoreQuestions] = useState(false);
  const [canAddMoreSampleSizes, setCanAddMoreSampleSizes] = useState(false);

  const [addSampleSizeTooltip, setAddSampleSizeTooltip] = useState(
    AT_LEAST_ONE_LOCATION_TOOLTIP,
  );
  const [addQuestionCountTooltip, setAddQuestionCountTooltip] = useState(
    AT_LEAST_ONE_LOCATION_TOOLTIP,
  );

  const pricingQuote = useAppSelector((state) => state.pricingQuote);
  const lastPriceResponse = useAppSelector((state) => state.lastPriceResponse);
  const pricingService = usePricingService();
  const calculatedMaxRecommendedSampleSize = useAppSelector(
    selectMaxRecommendedSampleSize,
  );

  const dispatch = useAppDispatch();
  const quoteFromRedux = useAppSelector((state) => state.selectedQuote);

  const getPrices = async (request: PricingQuoteRequest) => {
    let msg = '';
    try {
      setIsLoading(true);
      const response = await pricingService.priceQuote(request);
      dispatch({
        type: ReduxAction.UpdateLastPriceResponse,
        lastPriceResponse: response.data,
      });
      setQuotes(response.data.prices);
    } catch (e) {
      if (typeof e === 'string') {
        msg = e;
      } else if (e instanceof AxiosError) {
        msg = JSON.stringify(e.response?.data);
      } else if (e instanceof Error) {
        msg = e.message;
      }
    }
    setPricingErrorMessage(msg);
    setIsLoading(false);
  };

  // only on first render load the saved quote
  useEffect(() => {
    if (quoteId && quoteFromRedux && quoteFromRedux.id === quoteId) {
      setNumberOfQuestions(
        quoteFromRedux.price_info.request.number_of_questions,
      );
      setSampleSizes(quoteFromRedux.price_info.request.sample_sizes);
    } else if (quoteId && (!quoteFromRedux || quoteFromRedux.id !== quoteId)) {
      throw new Error('Should have loaded the quote from api into redux');
    }
  }, []);

  useEffect(() => {
    const request = { ...pricingQuote } as PricingQuoteRequest;
    if (
      sampleSizes.length &&
      numberOfQuestions.length &&
      !_.isEqual(lastPriceResponse.request, request) &&
      request.districts.length > 0
    ) {
      getPrices(request).catch((e) => {
        // we want to print to console here
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
  }, [dispatch, pricingQuote]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateSampleSizes,
      sampleSizes,
    });
  }, [dispatch, sampleSizes]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateNumberOfQuestions,
      numberOfQuestions,
    });
  }, [dispatch, numberOfQuestions]);

  useEffect(() => {
    setCanAddMoreQuestions(
      numberOfQuestions.length !== MAX_PRICING_QUOTE_PRICES &&
        !isLoading &&
        !numberOfQuestions.includes(numberOfQuestionsToAdd) &&
        pricingQuote.districts?.length !== 0 &&
        _.inRange(numberOfQuestionsToAdd, MIN_QUESTIONS, MAX_QUESTIONS + 1),
    );
    if (numberOfQuestions.length === MAX_PRICING_QUOTE_PRICES) {
      setAddQuestionCountTooltip(MAX_QUESTION_COUNT_TOOLTIP);
    } else if (numberOfQuestions.includes(numberOfQuestionsToAdd)) {
      setAddQuestionCountTooltip(QUESTION_COUNT_ALREADY_TOOLTIP);
    } else {
      setAddQuestionCountTooltip(AT_LEAST_ONE_LOCATION_TOOLTIP);
    }
  }, [isLoading, numberOfQuestions, numberOfQuestionsToAdd, pricingQuote]);

  useEffect(() => {
    setCanAddMoreSampleSizes(
      sampleSizes.length !== MAX_PRICING_QUOTE_PRICES &&
        !isLoading &&
        !sampleSizes.includes(sampleSizeToAdd) &&
        pricingQuote.districts?.length !== 0 &&
        _.inRange(sampleSizeToAdd, MIN_SAMPLE_SIZE, MAX_SAMPLE_SIZE + 1),
    );

    if (sampleSizes.length === MAX_PRICING_QUOTE_PRICES) {
      setAddSampleSizeTooltip(MAX_SAMPLE_SIZES_TOOLTIP);
    } else if (sampleSizes.includes(sampleSizeToAdd)) {
      setAddSampleSizeTooltip(SAMPLE_SIZE_ALREADY_TOOLTIP);
    } else {
      setAddSampleSizeTooltip(AT_LEAST_ONE_LOCATION_TOOLTIP);
    }
  }, [isLoading, sampleSizes, sampleSizeToAdd, pricingQuote]);

  const addQuestionCount = () => {
    setNumberOfQuestions(
      [...numberOfQuestions, numberOfQuestionsToAdd].sort((a, b) => a - b),
    );
  };

  const addSampleSize = () => {
    setSampleSizes([...sampleSizes, sampleSizeToAdd].sort((a, b) => a - b));
  };

  const pressEnterToAddQuestionCount: React.KeyboardEventHandler<
    HTMLDivElement
  > = (event) => {
    if (event.key === 'Enter') {
      if (canAddMoreQuestions) {
        addQuestionCount();
      }
    }
  };

  const pressEnterToAddSampleSize: React.KeyboardEventHandler<
    HTMLDivElement
  > = (event) => {
    if (event.key === 'Enter') {
      if (canAddMoreSampleSizes) {
        addSampleSize();
      }
    }
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap="20px">
      <GridItem colSpan={1}>
        Maximum Recommended Sample Size:
        <Text
          display="inline"
          color={
            calculatedMaxRecommendedSampleSize > 0
              ? 'emphasisTextColor'
              : 'current'
          }
        >
          {' '}
          {calculatedMaxRecommendedSampleSize > 0
            ? calculatedMaxRecommendedSampleSize
            : '-'}
        </Text>
      </GridItem>
      <GridItem colSpan={1} />
      <GridItem colSpan={1}>
        <FormControl isRequired>
          <FormLabel>
            Choose up to {MAX_PRICING_QUOTE_PRICES} sample sizes (
            {MIN_SAMPLE_SIZE} - {MAX_SAMPLE_SIZE})
          </FormLabel>
          <InputGroup>
            <HStack>
              <NumberInput
                defaultValue={MIN_SAMPLE_SIZE}
                min={MIN_SAMPLE_SIZE}
                max={MAX_SAMPLE_SIZE}
                onChange={(valueAsString, valueAsNumber) =>
                  setSampleSizeToAdd(valueAsNumber)
                }
                onKeyDown={pressEnterToAddSampleSize}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <Tooltip
                hasArrow
                label={addSampleSizeTooltip}
                isDisabled={canAddMoreSampleSizes}
                shouldWrapChildren
                mt="1"
              >
                <Button
                  colorScheme="green"
                  onClick={addSampleSize}
                  isDisabled={!canAddMoreSampleSizes}
                >
                  Add Sample Size
                </Button>
              </Tooltip>

              <Spinner
                m={4}
                thickness="4px"
                speed="1s"
                emptyColor="brand.miscUIEmptyColor"
                color="brand.miscUIColor"
                size="lg"
                hidden={!isLoading}
              />
              {sampleSizes.map((size) => (
                <Tag
                  key={`sample-size-tag-${size}`}
                  borderRadius="full"
                  variant="solid"
                  colorScheme={
                    size > calculatedMaxRecommendedSampleSize ? 'red' : 'green'
                  }
                >
                  <TagLabel>{size}</TagLabel>
                  <TagCloseButton
                    onClick={() => setSampleSizes(_.without(sampleSizes, size))}
                  />
                </Tag>
              ))}
            </HStack>
          </InputGroup>
        </FormControl>
      </GridItem>

      <GridItem colSpan={1}>
        <FormControl isRequired>
          <FormLabel>
            Choose up to {MAX_PRICING_QUOTE_PRICES} question lengths (
            {MIN_QUESTIONS} - {MAX_QUESTIONS})
          </FormLabel>
          <InputGroup>
            <HStack>
              <NumberInput
                defaultValue={MIN_QUESTIONS}
                min={MIN_QUESTIONS}
                max={MAX_QUESTIONS}
                onChange={(valueAsString, valueAsNumber) =>
                  setNumberOfQuestionsToAdd(valueAsNumber)
                }
                onKeyDown={pressEnterToAddQuestionCount}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <Tooltip
                hasArrow
                label={addQuestionCountTooltip}
                isDisabled={canAddMoreQuestions}
                shouldWrapChildren
                mt="1"
              >
                <Button
                  colorScheme="green"
                  onClick={() => addQuestionCount()}
                  isDisabled={!canAddMoreQuestions}
                >
                  Add Question Count
                </Button>
              </Tooltip>
              <Spinner
                m={4}
                thickness="4px"
                speed="1s"
                emptyColor="brand.miscUIEmptyColor"
                color="brand.miscUIColor"
                size="lg"
                hidden={!isLoading}
              />
              {numberOfQuestions.map((questionCount) => (
                <Tag
                  borderRadius="full"
                  variant="solid"
                  colorScheme="green"
                  key={`num-question-tag-${questionCount}`}
                >
                  <TagLabel>{questionCount}</TagLabel>
                  <TagCloseButton
                    onClick={() =>
                      setNumberOfQuestions(
                        _.without(numberOfQuestions, questionCount),
                      )
                    }
                  />
                </Tag>
              ))}
            </HStack>
          </InputGroup>
        </FormControl>
      </GridItem>

      <GridItem>
        <RecruitmentCostSection
          calculatedMaxRecommendedSampleSize={
            calculatedMaxRecommendedSampleSize
          }
          quotes={quotes}
          numberOfQuestions={numberOfQuestions}
          sampleSizes={sampleSizes}
        />
      </GridItem>

      <GridItem>
        <QuoteListSection
          calculatedMaxRecommendedSampleSize={
            calculatedMaxRecommendedSampleSize
          }
          quotes={quotes}
          numberOfQuestions={numberOfQuestions}
          sampleSizes={sampleSizes}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <ProposalListSection
          calculatedMaxRecommendedSampleSize={
            calculatedMaxRecommendedSampleSize
          }
          quotes={quotes}
        />
      </GridItem>
      <GridItem visibility={pricingErrorMessage ? 'visible' : 'hidden'}>
        <Text size="sm" color="emphasisTextColor">
          {pricingErrorMessage}
        </Text>
      </GridItem>
    </Grid>
  );
}

export default GenerateQuoteSection;
