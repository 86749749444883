import React, { MouseEventHandler, useReducer, useEffect, useRef } from 'react';
import {
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Select,
  Table,
  TableContainer,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  GridItem,
  Grid,
  HStack,
  Spinner,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Tooltip,
  useToast,
  TableCaption,
  Flex,
} from '@chakra-ui/react';
import * as _ from 'lodash';
import { AxiosError } from 'axios';

import { CopyIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ReduxAction } from '../../reducer';
import {
  State,
  DistrictType,
  District,
  DistrictDemoData,
} from '../../lib/pricing-tool-api';
import { IGeoService, useGeoService } from '../../lib/services/GeoService';
import TrashIcon from '../TrashIcon';
import {
  saveDemographicDataToIndexed,
  getDemographicDataFromIndexed,
} from '../../lib/indexedDbStore';
import { copyRichContent } from './GenerateQuoteSection';

const MAX_LOCATIONS = 25;

type LocationInformationSectionState = {
  isLoading: boolean;
  allowAddLocation: boolean;
  selectedDistricts: Array<District>;
  selectedDistrictsDemoData: Map<string, DistrictDemoData>;
  districtTypes: Array<DistrictType>;
  districtsList: Array<District>;
  districtMap: Map<string, District>;
  currentState?: State;
  currentStateDemoData: Map<string, DistrictDemoData>;
  currentDistrictType?: DistrictType;
  currentDistrict?: District;
  error?: string;
};

enum ActionType {
  SetCurrentState = 'set-current-state',
  SetCurrentDistrictType = 'set-current-district-type',
  SetCurrentDistrict = 'set-current-district',
  SetSelectedDistrict = 'set-selected-district',
  SetDistrictsList = 'set-districts-list',
  SetCurrentStateDemoData = 'set-demo-data',
  Error = 'error',
}

type Action =
  | { type: ActionType.SetCurrentState; currentState: State }
  | {
      type: ActionType.SetCurrentDistrictType;
      currentDistrictType: DistrictType;
    }
  | { type: ActionType.SetCurrentDistrict; currentDistrict: District }
  | { type: ActionType.SetDistrictsList; districtsList: Array<District> }
  | {
      type: ActionType.SetSelectedDistrict;
      selectedDistricts: Array<District>;
      demoData: Array<DistrictDemoData> | undefined;
    }
  | { type: ActionType.Error; msg: string }
  | {
      type: ActionType.SetCurrentStateDemoData;
      demoData: Array<DistrictDemoData>;
    };

const initialState: LocationInformationSectionState = {
  isLoading: false,
  allowAddLocation: false,
  selectedDistricts: [],
  selectedDistrictsDemoData: new Map<string, DistrictDemoData>(),
  districtsList: [],
  districtMap: new Map<string, District>(),
  districtTypes: [],
  currentStateDemoData: new Map<string, DistrictDemoData>(),
};

const formatPercentage = (percentage: number): string =>
  Number.isNaN(percentage) ? '-' : `${(percentage * 100).toFixed(1)}%`;

const formatDemographicData = (
  demoData: DistrictDemoData | undefined,
): string => {
  if (!demoData) {
    return '-';
  }
  return `${formatPercentage(demoData.percent_white)} White;
    ${formatPercentage(demoData.percent_hispanic)} Hispanic;
    ${formatPercentage(demoData.percent_black)} Black;
    ${formatPercentage(demoData.percent_aapi)} AAPI;`;
};

function reducer(
  state: LocationInformationSectionState,
  action: Action,
): LocationInformationSectionState {
  const belowMaxLocations = state.selectedDistricts.length < MAX_LOCATIONS;

  switch (action.type) {
    case ActionType.SetCurrentState:
      return {
        ...state,
        isLoading: true,
        allowAddLocation: false,
        districtTypes: [],
        districtsList: [],
        currentStateDemoData: new Map<string, DistrictDemoData>(),
        currentDistrictType: '' as DistrictType,
        currentDistrict: undefined,
        currentState: action.currentState,
      };
    case ActionType.SetCurrentDistrictType: {
      const firstDistrictInDropdown = _.find(state.districtsList, {
        district_type: action.currentDistrictType,
      });
      if (
        firstDistrictInDropdown !== undefined &&
        firstDistrictInDropdown.id !== undefined
      ) {
        const addedAlready =
          _.find(state.selectedDistricts, {
            id: firstDistrictInDropdown?.id,
          }) !== undefined;
        return {
          ...state,
          allowAddLocation: !addedAlready,
          currentDistrictType: action.currentDistrictType,
          currentDistrict: _.cloneDeep(firstDistrictInDropdown),
        };
      }
      const addedAlready =
        _.find(state.selectedDistricts, { id: state.currentDistrict?.id }) !==
        undefined;
      return {
        ...state,
        allowAddLocation: !addedAlready,
        currentDistrictType: action.currentDistrictType,
      };
    }
    case ActionType.SetCurrentDistrict: {
      const addedAlready =
        _.find(state.selectedDistricts, { id: action.currentDistrict?.id }) !==
        undefined;
      return {
        ...state,
        isLoading: false,
        allowAddLocation:
          belowMaxLocations &&
          action.currentDistrict !== undefined &&
          !addedAlready,
        currentDistrict: action.currentDistrict,
      };
    }
    case ActionType.SetSelectedDistrict: {
      if (!action.selectedDistricts.every((d) => d.id !== undefined)) {
        // this shouldn't happen
        throw new Error('All districts must have an id');
      }

      // get the existing entries except for ones no longer selected
      // this handles the "delete" case
      const selectedDemoMap: Map<string, DistrictDemoData> = new Map<
        string,
        DistrictDemoData
      >(
        _.map(
          [...state.selectedDistrictsDemoData.entries()].filter(([id]) =>
            action.selectedDistricts
              .map((d) => d.id)
              .filter((d) => d !== undefined)
              .includes(id),
          ),
          ([key, value]) => [key, value],
        ),
      );

      action.selectedDistricts
        .filter(
          (d) =>
            // only load for data that isn't already loaded into the map
            d.id !== undefined && ![...selectedDemoMap.keys()].includes(d.id),
        )
        .forEach((d) => {
          let foundDemoData: DistrictDemoData | undefined;
          if (d.id === undefined) {
            // this should never happen because we throw an error early in the function
            return;
          }
          if (action.demoData) {
            const fromExplicitlyPassedDemoData = action.demoData.filter(
              (demo) => demo.district.id === d.id,
            );
            if (fromExplicitlyPassedDemoData.length > 1) {
              throw new Error(`Found multiple demo data for district ${d.id}`);
            } else if (fromExplicitlyPassedDemoData.length === 1) {
              [foundDemoData] = fromExplicitlyPassedDemoData;
            }
          }
          if (!foundDemoData) {
            // if we didn't find it in the explicitly passed data, try to find it in the state
            let dataFromCurrentState = state.currentStateDemoData.get(d.id);
            if (dataFromCurrentState === undefined) {
              // if it's still not found then we'll allow it to be null if we passed in demo data explicitly
              // this would happen if we're editing an existing quote
              // we'd want to make sure the quote cannot be saved though
              // TODO: stop saving quotes if there's an empty demo data
              if (action.demoData) {
                dataFromCurrentState = { district: d } as DistrictDemoData;
              } else {
                // I don't think we can hit this case
                throw Error('No demo data found for district');
              }
            }
            foundDemoData = dataFromCurrentState;
          }
          if (foundDemoData !== undefined) {
            selectedDemoMap.set(d.id, foundDemoData);
          } else {
            // we shouldn't hit this in most cases, would only happen if the district list was out of date
            throw Error('something went wrong finding the demo data');
          }
        });

      return {
        ...state,
        isLoading: false,
        // this makes it so you're unable to add the same district twice
        allowAddLocation: false,
        selectedDistricts: action.selectedDistricts,
        selectedDistrictsDemoData: selectedDemoMap,
      };
    }
    case ActionType.SetDistrictsList:
      return {
        ...state,
        isLoading: false,
        allowAddLocation: false,
        districtsList: action.districtsList,
        districtMap: new Map<string, District>(
          // api can legitimately return null
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          action.districtsList.map((district) => [district.id!, district]),
        ),
        districtTypes: [
          ...new Set<DistrictType>(
            action.districtsList.map(
              ({ district_type }) => district_type as DistrictType,
            ),
          ),
        ],
      };
    case ActionType.SetCurrentStateDemoData:
      return {
        ...state,
        currentStateDemoData: new Map<string, DistrictDemoData>(
          action.demoData.map((districtDemoData) => [
            // api can legitimately return null
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            districtDemoData.district.id!,
            districtDemoData,
          ]),
        ),
      };
    case ActionType.Error:
      return {
        ...state,
        isLoading: false,
        allowAddLocation: false,
        error: action.msg,
      };
    default:
      throw new Error('unrecognized action type');
  }
}

const saveDemoDataToLocalCache = async (
  state: State,
  demoData: Array<DistrictDemoData>,
): Promise<void> => {
  await saveDemographicDataToIndexed(state, demoData);
};

const loadDemoDataFromLocalCache = async (
  state: State,
): Promise<Array<DistrictDemoData> | null> => {
  const data = await getDemographicDataFromIndexed(state);
  return data;
};

interface LocationInformationSectionProps {
  selectedDistricts: Array<District>;
  selectedDistrictsDemoData: Map<string, DistrictDemoData>;
  onRemoveDistrict: (id: string) => void;
  showRemoveButton: boolean;
  allowNullDemoData: boolean;
  size: 'sm' | 'md';
}

export function LocationInformationTable(
  props: LocationInformationSectionProps,
) {
  const toast = useToast();
  const tableRef = useRef<HTMLTableElement>(null);
  const {
    selectedDistricts,
    selectedDistrictsDemoData,
    onRemoveDistrict,
    showRemoveButton,
    allowNullDemoData,
    size,
  } = props;

  const locationName = (
    state: State,
    districtType: DistrictType,
    name: string,
  ) => {
    if (districtType === DistrictType.State) {
      return `${state} (Statewide)`;
    }
    return `${name}, ${state} (${districtType})`;
  };

  return (
    <TableContainer ref={tableRef}>
      <Table size={size}>
        <TableCaption
          placement="top"
          backgroundColor="brand.tableTitleColor"
          textColor="brand.bodyText"
          fontSize="lg"
        >
          Locations List
          <IconButton
            aria-label="Copy proposals to clipboard"
            variant="ghost"
            color="brand.linkColor"
            marginLeft="8"
            onClick={() => {
              toast({
                title: 'Proposal List',
                description: 'Copied proposal list to clipboard!',
                status: 'info',
                duration: 5000,
                isClosable: true,
              });
              copyRichContent(tableRef);
            }}
          >
            <CopyIcon />
          </IconButton>
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Location</Th>
            <Th>Registered Voters</Th>
            <Th>Percentage of Registered Voters</Th>
          </Tr>
        </Thead>
        <Tbody>
          {selectedDistricts.map(
            ({ id, state, district_type, name }, index) => {
              if (id == null) {
                throw new Error(`ID for district with ${id} is null`);
              }
              const demoData = selectedDistrictsDemoData.get(id);
              const locationId = `location${index}`;
              if (demoData == null && !allowNullDemoData) {
                throw new Error(`Demo data for district with ${id} is null`);
              }
              const registeredVoters =
                demoData?.registered_voters?.toLocaleString('en-US') || '-';
              const demographics = formatDemographicData(demoData);
              return (
                <Tr key={id}>
                  <Td>{locationName(state, district_type, name)}</Td>
                  <Td>{registeredVoters}</Td>
                  <Td>
                    <Flex display="flex" justifyContent="space-between">
                      <Text alignSelf="center">{demographics}</Text>
                      {showRemoveButton && (
                        <IconButton
                          key={locationId}
                          id={locationId}
                          colorScheme="red"
                          onClick={() => onRemoveDistrict(id)}
                          icon={<TrashIcon />}
                          isDisabled={selectedDistricts.length === 0}
                          aria-label={`remove location ${index + 1}`}
                        />
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            },
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

const fetchDemoData = async (
  api: IGeoService,
  state: State,
): Promise<Array<DistrictDemoData>> => {
  const localStorageCopy = await loadDemoDataFromLocalCache(state);
  if (localStorageCopy) {
    return localStorageCopy;
  }
  const response = await api.listDemoData(state);
  const demoData = response.data;
  await saveDemoDataToLocalCache(state, demoData);
  return demoData;
};

interface LocationInformationProps {
  quoteId: string | null;
}

function LocationInformationSection(
  props: LocationInformationProps = { quoteId: null },
) {
  const { quoteId } = props;
  const [pageState, componentDispatch] = useReducer(reducer, initialState);
  const geoServicesApi = useGeoService();
  const dispatch = useAppDispatch();
  const quoteFromRedux = useAppSelector((state) => state.selectedQuote);

  // only on first render load the saved quote
  useEffect(() => {
    if (quoteId && quoteFromRedux && quoteFromRedux.id === quoteId) {
      if (quoteFromRedux.district_demo_data) {
        componentDispatch({
          type: ActionType.SetCurrentStateDemoData,
          demoData: quoteFromRedux.district_demo_data,
        });
      }
      componentDispatch({
        type: ActionType.SetSelectedDistrict,
        selectedDistricts: quoteFromRedux.price_info.request.districts,
        demoData: quoteFromRedux.district_demo_data,
      });
    } else if (quoteId && (!quoteFromRedux || quoteFromRedux.id !== quoteId)) {
      throw new Error('Should have loaded the quote from api into redux');
    }
  }, []);

  useEffect(() => {
    if (pageState.currentState) {
      fetchDemoData(geoServicesApi, pageState.currentState)
        .then((data) => {
          componentDispatch({
            type: ActionType.SetDistrictsList,
            districtsList: _.map(data, (d) => d.district),
          });
          componentDispatch({
            type: ActionType.SetCurrentStateDemoData,
            demoData: data,
          });
        })
        .catch((e) => {
          let msg = '';
          if (typeof e === 'string') {
            msg = e;
          } else if (e instanceof AxiosError) {
            msg = JSON.stringify(e.response?.data);
          } else if (e instanceof Error) {
            msg = e.message;
          }
          componentDispatch({ type: ActionType.Error, msg });
        });
    }
  }, [pageState.currentState]);

  useEffect(() => {
    dispatch({
      type: ReduxAction.UpdateDistricts,
      districts: pageState.selectedDistricts,
    });
  }, [dispatch, pageState.selectedDistricts]);

  useEffect(() => {
    if (pageState.selectedDistrictsDemoData.size > 0) {
      dispatch({
        type: ReduxAction.UpdateSelectedDemoDistricts,
        demoData: Array.from(pageState.selectedDistrictsDemoData.values()),
      });
    }
  }, [dispatch, pageState.selectedDistrictsDemoData]);

  const addLocation: MouseEventHandler<HTMLButtonElement> = () => {
    if (pageState.currentDistrict == null) {
      throw new Error(
        'District cannot be undefined, please contact the tech team',
      );
    }
    componentDispatch({
      type: ActionType.SetSelectedDistrict,
      selectedDistricts: [
        ...pageState.selectedDistricts,
        pageState.currentDistrict,
      ],
      demoData: undefined,
    });
  };

  const deleteLocationById: (id: string) => void = (id) => {
    componentDispatch({
      type: ActionType.SetSelectedDistrict,
      selectedDistricts: _.filter(
        pageState.selectedDistricts,
        (d) => d.id !== id,
      ),
      demoData: undefined,
    });
  };

  const demoDataForAllSelectedDistricts = () => {
    const districtIds = _.without(
      pageState.selectedDistricts.map((d) => d.id),
      undefined,
    ) as string[];
    const demoDataKeys = [...pageState.selectedDistrictsDemoData.keys()];
    return _.every(districtIds, (id) => demoDataKeys.includes(id));
  };

  return (
    <Grid templateColumns="repeat(4, 1fr)" rowGap="1em" columnGap="0.5em">
      <GridItem>
        <FormControl>
          <FormLabel>In which state are we polling?</FormLabel>
          <Select
            onChange={(event) => {
              componentDispatch({
                type: ActionType.SetCurrentState,
                currentState: event.target.value as State,
              });
            }}
          >
            {['', ...Object.values(State)].map((state) => (
              <option value={state} key={`current-state-option-${state}`}>
                {state}
              </option>
            ))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem>
        <FormControl
          isDisabled={pageState.currentState == null || pageState.isLoading}
        >
          <FormLabel>What is the district type?</FormLabel>
          <Select
            onChange={(event) => {
              componentDispatch({
                type: ActionType.SetCurrentDistrictType,
                currentDistrictType: event.target.value as DistrictType,
              });
            }}
          >
            {['', ...pageState.districtTypes].map((districtType) => (
              <option
                value={districtType}
                key={`current-district-type-option-${districtType}`}
              >
                {districtType}
              </option>
            ))}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl
          isDisabled={
            !pageState.currentDistrictType ||
            pageState.districtsList.length === 0
          }
        >
          <FormLabel>What is the district?</FormLabel>
          <Select
            defaultValue=""
            onChange={(event) => {
              componentDispatch({
                type: ActionType.SetCurrentDistrict,
                // api can legitimately return null
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                currentDistrict: pageState.districtMap.get(event.target.value)!,
              });
            }}
          >
            {pageState.districtsList
              .filter(
                (district) =>
                  district.district_type === pageState.currentDistrictType,
              )
              .map((district) => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem alignSelf="end">
        <HStack align="center">
          <Tooltip
            hasArrow
            label="Another dropdown must be filled."
            shouldWrapChildren
            mt="1"
            isDisabled={pageState.allowAddLocation}
          >
            <Button
              colorScheme="green"
              onClick={addLocation}
              isDisabled={!pageState.allowAddLocation}
              alignSelf="end"
            >
              Add Location
            </Button>
          </Tooltip>
          <Spinner
            m={4}
            thickness="4px"
            speed="1s"
            emptyColor="brand.miscUIEmptyColor"
            color="brand.miscUIColor"
            size="lg"
            hidden={!pageState.isLoading}
          />
        </HStack>
      </GridItem>

      {pageState.districtsList.length === 0 &&
        pageState.currentState &&
        !pageState.isLoading && (
          <GridItem colSpan={4}>
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>Empty Districts List:</AlertTitle>
              <AlertDescription>
                Districts list should not be empty. Please contact the tech
                team.
              </AlertDescription>
            </Alert>
          </GridItem>
        )}

      {pageState.selectedDistricts.length > 0 && [
        <GridItem colSpan={4} key="location-table">
          {demoDataForAllSelectedDistricts() ? (
            <LocationInformationTable
              onRemoveDistrict={deleteLocationById}
              selectedDistricts={pageState.selectedDistricts}
              selectedDistrictsDemoData={pageState.selectedDistrictsDemoData}
              showRemoveButton
              allowNullDemoData={false}
              size="md"
            />
          ) : (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>No Demo Data Found</AlertTitle>
              <AlertDescription>
                Something went wrong. Please contact the tech team with the
                details of the quote you are trying to create.
              </AlertDescription>
            </Alert>
          )}
        </GridItem>,
        <GridItem bg="brand.tableTitleColor" colSpan={4} key="location-count">
          {pageState.selectedDistricts.length} / {MAX_LOCATIONS} locations
        </GridItem>,
      ]}
    </Grid>
  );
}

export default LocationInformationSection;
